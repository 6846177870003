/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useState } from "react";
import CardSection from "../../../components/ui/card/CardSection";
import SystemTaskOrderList from "./SystemTaskOrderList";
import { FirstSecondLetter } from "../../../utils/FirstSecLetter/FirstSecondLetter";
import formatDate from "../../../lib/formatDate";
import NoRecordFound from "../../../components/ui/noRecordFound/NoRecordFound";
import { useLazyGetAllSystemBadgeBySystemTaskIdQuery } from "../../../app/services/systemTaskAPI";
import DataLoader from "../../../components/FinalMolGrid/ui/dataLoader/DataLoader";
import { getStatusColor } from "../../../utils/CommonUtils/CommonUtilsMethods";
import ToastService from "../../../services/toastService/ToastService";


const SystemOpenTask = ({ orderList, badgeRef, isLoading, selectedId, setSelectedId, setSelectedBadgeId, selectedBadgeId, setCustomerId, setOrderId, isGetSystemTaskListFetching }) => {

  const [orderBadgesList, setOrderBadgesList] = useState([]);
  const [getAllSystemBadgeBySystemTaskId, { isFetching: isGetAllSystemBadgeBySystemTaskIdFetching, isSuccess: isGetAllSystemBadgeBySystemTaskIdSucess, data: allGetAllSystemBadgeBySystemTaskIdData }] = useLazyGetAllSystemBadgeBySystemTaskIdQuery();
  const [isChecked,setIsChecked] =useState(false);


  useEffect(() => {
    if (selectedId) {
      const request={
        systemTaskId : selectedId,
        isShowPassBadges : isChecked
      }
      getAllSystemBadgeBySystemTaskId(request)
    } else if (selectedId === null) {
      setSelectedBadgeId(null)
    }
  }, [selectedId])


  useEffect(() => {
    if (!isGetAllSystemBadgeBySystemTaskIdFetching && isGetAllSystemBadgeBySystemTaskIdSucess && allGetAllSystemBadgeBySystemTaskIdData) {

      // if(selectedBadgeId === null){
      setSelectedBadgeId(allGetAllSystemBadgeBySystemTaskIdData[0]?.systemTaskBadgeId);
      // }
      setOrderBadgesList(allGetAllSystemBadgeBySystemTaskIdData);

    }
  }, [isGetAllSystemBadgeBySystemTaskIdFetching, isGetAllSystemBadgeBySystemTaskIdSucess, allGetAllSystemBadgeBySystemTaskIdData]);

  const handleTabClick = (selectId) => {
    setSelectedBadgeId(selectId)
  }

  const onGetData = () => {
    const request={
      systemTaskId : selectedId,
      isShowPassBadges : isChecked
    }
    selectedId && getAllSystemBadgeBySystemTaskId(request)
  }
  useImperativeHandle(badgeRef, () => ({
    callChildFunction: onGetData,
  }));


  const handleToggle = () => {
    if(selectedId){
      setIsChecked( !isChecked)
      const request={
        systemTaskId : selectedId,
        isShowPassBadges : !isChecked
      }
      getAllSystemBadgeBySystemTaskId(request)
    }
    else{
      ToastService.warning("No Order Badges Found")
    }
  };
  
  return (
    <div className="row">
      <div className="col-5 pr-0 left-modual-sec">
        <SystemTaskOrderList isLoading={isLoading} orderList={orderList} selectedId={selectedId} setSelectedId={setSelectedId} setCustomerId={setCustomerId} setOrderId={setOrderId} isGetSystemTaskListFetching={isGetSystemTaskListFetching}

        />
      </div>
      <div className="col-7 pl-1 pr-1 centertext-count">
        <CardSection
          cardTitle="Order Badges" 
           rightButton={true}  isShowToggle={true} isChecked={isChecked} handleToggle={handleToggle} togglelabel={"Show Pass"}
        >
          <div className="customer-info">
            {isGetAllSystemBadgeBySystemTaskIdFetching ? (
              <DataLoader />
            ) : (
              <div className="tabs">
                {orderBadgesList && orderBadgesList.length > 0 && orderList.length > 0 ? (
                  orderBadgesList.map((tab) => (
                    <button
                      key={tab.systemTaskBadgeId} // Use a unique key
                      className={`tab-button ${selectedBadgeId === tab.systemTaskBadgeId ? "active" : ""}`}
                      onClick={() => handleTabClick(tab.systemTaskBadgeId)}
                    >
                      <div className="d-flex align-items-start w-100">
                        <span className="profile-icon">
                          {" "}
                          {FirstSecondLetter(tab.subStatus)}
                        </span>
                        <div className="right-name-desc">
                          <div className="title">
                            {tab.subStatus}
                            <div className="date">
                              {tab.createdAt
                                ? formatDate(
                                  tab.createdAt,
                                  "MM/DD/YYYY hh:mm A"
                                )
                                : "No Date"}
                            </div>
                          </div>
                          <div className="bage-fix">
                            <span className="sub-title">
                              Created By : {tab.createdBy}
                            </span>
                            <div className={`status-badge ${getStatusColor(tab.badgeStatus)}`}>
                              {tab.badgeStatus}
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  ))
                ) : (
                  <NoRecordFound />
                )}
              </div>
            )}
          </div>
        </CardSection>
      </div>
    </div>
  )

}
export default SystemOpenTask;