import { validationTypes } from "../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";

export const EmailTemplateFormData = {
  initialState: {
    emailTemplateName: "", subject: "", emailBody: "", isActive: false,
  },
  section: [
    {
      title: "Email Template Information Section",
      row: {},
      style: {
        sectionStyle: "row mb-3",
      },
      fields: [
        {
          id: "emailTemplateName",
          label: "Email Template Name",
          Field_Name: "Email template name",
          fieldType: FormFieldTypes.INPUT,
          dataField: "emailTemplateName",
          fieldSetting: {
            placeholder: "Enter Email Template Name",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-input mt-2",
          },
        },
        {
          id: "subject",
          label: "Subject",
          Field_Name: "Subject",
          fieldType: FormFieldTypes.INPUT,
          dataField: "subject",
          fieldSetting: {
            placeholder: "Enter Subject",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-input mt-2",
          },
        },
        {
          id: "emailBody",
          label: "Email Body ",
          Field_Name: "Email body",
          fieldType: FormFieldTypes.TEXTEDITOR,
          dataField: "emailBody",
          fieldSetting: {
            placeholder: "Enter Email Body",
            allowSpace: true,
            maxLength: 1000,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
          },
        },
        {
          id: "isActive",
          label: "Is Active",
          Field_Name: "Is Active",
          fieldType: FormFieldTypes.CHECKBOX,
          dataField: "isActive",
          fieldSetting: {
            placeholder: "",
            allowSpace: true,
          },
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-input mt-3 margin-top-checkbox",
          },
        },
      ]
    }
  ]
}
export const EmailTemplateGridConfig = {
  columns: [
    {
      name: "Email Template Name",
      fieldName: "emailTemplateName",
      colStyle: {
        width: "35%",
      },
      allowShort: true,
    },
    {
      name: "Subject",
      fieldName: "subject",
      colStyle: {
        width: "35%",
      },
      allowShort: true,
    },
    {
      name: "IsActive",
      fieldName: "isActive",
      colStyle: {
        width: "20%",
      },
      colType: GridColumnType.CHECKBOX,
      colSettings: {
        isDisabled: true,
      },
    },
    {
      name: "Action",
      colStyle: {
        width: "10%",
      },
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,

      },
    },
  ],

};