import { validationTypes } from "../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../components/FinalForms/libs/data/formFieldType";



export const AddEditSystemTaskCommentsFormData = {
    name: "Comemnt From",
    initialState: { customerComments:"",internalComments :"" },
    section: [
      {
        title: "Comemnt Section",
        row: {},
        style: {
          sectionStyle: "row mb-3",
        },
        fields: [
          {
            id: "internalComments",
            label: "Comments",
            Field_Name: "Internal comments",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "internalComments",
            fieldSetting: {
              placeholder: "Enter Internal Comments",
              allowSpace: true,
              maxLength: 500,
              isDisable: false
            },
            validation: [{ type: validationTypes.REQUIRE }],
            style: {
              containerCss: "col-xxl-12 col-xl-12 col-md-12 mb-1",
            },
          },
          {
            id: "customerComments",
            label: "Customer Communication Comments",
            Field_Name: "Customer comments",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "customerComments",
            fieldSetting: {
              placeholder: "Enter Customer Communication Comments",
              allowSpace: true,
              maxLength: 500,
              isDisable: false
            },
             validation: [{ type: validationTypes.REQUIRE }],
            style: {
              containerCss: "col-xxl-12 col-xl-12 col-md-12 mb-1",
            },
          }
        ]
      }
    ],
    formSetting: {
      isViewOnly: false
    }
  }