/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import FormCreator from "../../../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../../../components/ui/button/Buttons";
import { useAddEditWorkflowStepMutation, useLazyGetWorkflowStepByStepIdQuery } from "../../../../../../app/services/workFlowAPI";
import { addEditWorkflowStepsFormData } from "./config/WorkflowSteps.data";
import { onResetForm } from "../../../../../../utils/FormFields/ResetForm/handleResetForm";
import ToastService from "../../../../../../services/toastService/ToastService";

const AddEditWorkflowSteps=(props)=>{
    const ref=useRef();
    const [formData,setFormData]=useState(addEditWorkflowStepsFormData);
  const [addEditWorkflowStep, { isLoading: isAddEditWorkflowStepLoading, isSuccess: isAddEditWorkflowStepSuccess, data: allAddEditWorkflowStepData, },] = useAddEditWorkflowStepMutation();

  const [getWorkflowStepByStepId,{isFetching:   isGetWorkflowStepByStepIdFetching,isSuccess: isGetWorkflowStepByStepIdSuccess,data: isGetWorkflowStepByStepIdData, },] = useLazyGetWorkflowStepByStepIdQuery();
  
  useEffect(()=>{
    if(props.isEdit){
       
        getWorkflowStepByStepId(props.stepId)
    }
  },[props.isEdit,props.stepId])

  useEffect(() => {
    if (!isGetWorkflowStepByStepIdFetching && isGetWorkflowStepByStepIdSuccess && isGetWorkflowStepByStepIdData) {
      if(isGetWorkflowStepByStepIdData){
        let newForm={...formData};
        newForm.initialState={
            stepId:isGetWorkflowStepByStepIdData.stepId,
            stepName:isGetWorkflowStepByStepIdData.stepName,
            isInitial:isGetWorkflowStepByStepIdData.isInitial,
            isFinal:isGetWorkflowStepByStepIdData.isFinal,
            createdAt:isGetWorkflowStepByStepIdData.createdAt,
        }
        setFormData(newForm)
      }
    }
  }, [isGetWorkflowStepByStepIdFetching,isGetWorkflowStepByStepIdSuccess, isGetWorkflowStepByStepIdData]);

  useEffect(() => {
    if (isAddEditWorkflowStepSuccess && allAddEditWorkflowStepData) {
  
        ToastService.success(allAddEditWorkflowStepData.errorMessage);
        onResetForm(addEditWorkflowStepsFormData, setFormData, null);
             props.onClose();
             props.onSuccess();
     
    }
  }, [isAddEditWorkflowStepSuccess, allAddEditWorkflowStepData]);

  const handleSaveClick=()=>{
    const formData = ref.current.getFormData();
    if (formData ) {
      let request = {
        workflowId:props?.workFlowId ,
        stepName:formData?.stepName,
        isInitial:formData?.isInitial,
        stepId:props?.stepId,
        isFinal:formData?.isFinal,
      };
      addEditWorkflowStep(request);
    }
  }
  const handleResetAndClose = () => {
    onResetForm(addEditWorkflowStepsFormData, setFormData, null);
    props.onClose();
  };
    return(
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="vertical-form">
                <FormCreator
                 ref={ref} config={formData} {...formData}
                  />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="d-flex align-item-center justify-content-end">
                <Buttons
                  buttonTypeClassName="theme-button"
                 buttonText={props.isEdit ? "Update" : "Save"}
                 onClick={handleSaveClick}
                  isLoading={isAddEditWorkflowStepLoading}
                />
                <Buttons
                  buttonTypeClassName="dark-btn ml-5"
                  buttonText="Cancel"
            onClick={handleResetAndClose}
                />
              </div>
            </div>
          </div>
        </div>)
}
export default AddEditWorkflowSteps;