export const GridColumnType = {
    Text: 1,
    LINK:2,
    CHECKBOX:3,
    TOGGLE:4,
    label:5,
    DATE:6,
    MONEY:7,
    ACTION:14,
    CUSTOM:15,
    MULTIACTION:16,
    PROGRESS:17
    // add other types

}
