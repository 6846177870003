import PropTypes from 'prop-types';
import './Incoterms.scss';

const IncotermMismatch = ({ customerDetails, orderDetails }) => {
    const isMismatch = customerDetails?.incotermName !== orderDetails?.incotermName;

    return (
        <div className="incoterm-container">
            <div className="comparison-header">
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:account-outline"></i>
                    <span>Customer</span>
                </div>
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:file-document-outline"></i>
                    <span>Order</span>
                </div>
            </div>
            <div className="comparison-content">
                <div className={`incoterm-box ${isMismatch ? 'mismatch' : ''}`}>
                    <div className="incoterm-details">
                        <div className="incoterm-value">
                            {customerDetails?.incotermName || 'N/A'}
                        </div>
                    </div>
                </div>
                <div className={`incoterm-box ${isMismatch ? 'mismatch' : ''}`}>
                    <div className="incoterm-details">
                        <div className="incoterm-value">
                            {orderDetails?.incotermName || 'N/A'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

IncotermMismatch.propTypes = {
    customerDetails: PropTypes.shape({
        incotermName: PropTypes.string
    }),
    orderDetails: PropTypes.shape({
        incotermName: PropTypes.string
    })
};

export default IncotermMismatch;