import React from "react";
import "./AdvancedEmailSettings.scss";
import FormCreator from "../../../../../components/FinalForms/FormCreator";
import { AdvancedEmailSettingsFormData } from "./config/AdvancedEmailSettings.Data";
import { useRef } from "react";
import Buttons from "../../../../../components/ui/button/Buttons";

const AdvancedEmailSettings = ({ closeDropdown }) => {
  const advSettingRef = useRef();
  return (
    <div>
      <FormCreator ref={advSettingRef} config={AdvancedEmailSettingsFormData} />
      <div className="d-flex align-item-end justify-content-end" style={{gap: "10px"}}>
        <Buttons buttonTypeClassName="dark-btn" buttonText="Clear All" />
        <Buttons buttonTypeClassName="theme-button" buttonText="Search" onClick={closeDropdown}/>
      </div>
    </div>
  );
};

export default AdvancedEmailSettings;
