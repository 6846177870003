/* Component  */
import {
  compare, email, number, required, uniqueIdentifier, isvalidPassword, maxLength, minLength, maxProspects, minEndDate, maxSum, distinct, isValidEIN,
  isValidPhone, isValidFax, isUnique, isInvalidBoundarySpaces, isWebsite, isTaxId, isOnlyText, isValidZipCode, validateCharacters, addressValidation,

  validateFile,
  validateImageFile,
  validateNagativeNumber,
  validateJson,
  maxCharacterValidator,
  validFileName
} from './ValidateField'

// Validation functions 

export function ValidateAll(state, rules) {
  let result = {
    isValid: true,
    error: {}
  }
  const keys = Object.keys(rules);
  let error = {};
  keys.forEach(key => {
    const fieldRules = rules[key]
    const validateResult = ValidateField(state[key], fieldRules, state);
    if (!validateResult.isvalid) {
      result.isValid = false;
      error[key] = validateResult.message;
    }
  });
  result.error = error;
  return result;
}

export function Validate(state, rules, key) {
  let result = {
    isValid: true,
    error: {}
  }
  let error = {};
  let fieldRules = rules[key]
  let validateResult = ValidateField(state[key], fieldRules, state);
  if (!validateResult.isvalid) {
    result.isValid = false;
    error[key] = validateResult.message;
  }
  result.error = error;
  return result;
}

export function ValidateField(value, fieldRules, state) {
  const result = { isvalid: true, message: '' };

  for (const rule of fieldRules) {
    if (!result.isvalid) break; // Exit early if already invalid

    const validator = getValidator(rule.type);

    const validationResponse = validator && validator(value, rule, state);

    if (validator && !validationResponse) {
      result.isvalid = false;
      result.message = rule.message;
    } else if (validationResponse?.customMessage) {
      result.isvalid = validationResponse?.isValid;
      result.message = validationResponse?.customMessage;
    }
  }

  return result;
}

export const ValidateFileField = (value, fieldRules, state, acceptedFileFormats, minFileSize, maxFileSize, dataField, maxCharacterValidator) => {
  const result = { isValid: true, messages: [] };

  for (const rule of fieldRules) {
    if (!result.isValid) break; // Exit early if already invalid

    const validator = getValidator(rule.type);
    if (rule.type === "file" && validator) {

      const validatorResults = validator(value, acceptedFileFormats, minFileSize, maxFileSize)

      for (const validatorResult of validatorResults) {
        if (!validatorResult.isValid) {
          result.isValid = false;
          result.messages.push({
            message: rule.message || validatorResult.errorMessage,
            // fileName: validatorResult.fileName
          });
        }
      }
    } else if (rule.type === "maxCharacterValidator" && validator) {
      const validatorResults = validator(value.name, maxCharacterValidator)
      for (const validatorResult of validatorResults) {
        if (!validatorResult.isValid) {
          result.isValid = false;
          result.messages.push({
            message: rule.message || validatorResult.errorMessage,
            // fileName: validatorResult.fileName
          });
        }
      }
    }
    else if (rule.type === "validFileName") { 
 
      if (!validFileName(value.name)) {
        result.isValid = false;
        result.messages.push({
          message: rule.message || "Invalid file name. ",
        });
      }
    }
  }

  return result;
}

export const ValidateImageField = (value, fieldRules, state, fieldSetting, imageData) => {
  const result = { isValid: true, messages: [] };

  for (const rule of fieldRules) {
    if (!result.isValid) break; // Exit early if already invalid

    const validator = getValidator(rule.type);
    if (rule.type === "image" && validator) {

      const validatorResults = validator(value, fieldSetting, imageData)

      for (const validatorResult of validatorResults) {
        if (!validatorResult.isValid) {
          result.isValid = false;
          result.messages.push({
            message: rule.message || validatorResult.errorMessage,
            // fileName: validatorResult.fileName
          });
        }
      }
    }
  }

  return result;
}

const validators = {
  require: (value) => required(value),
  email: (value) => email(value),
  password: (value) => isvalidPassword(value),
  compare: (value, rule, state) => compare(value, state[rule.compareEle]),
  distinct: (value, rule, state) => distinct(value, state[rule.distinctEle]),
  number: (value) => number(value),
  uniqueIdentifier: (value) => uniqueIdentifier(value),
  maxLength: (value, rule) => maxLength(value, rule.maxLength),
  minLength: (value, rule) => minLength(value, rule.minLength),
  maxProspects: (value, rule) => maxProspects(value, rule.maxProspects),
  minEndDate: (value, rule) => minEndDate(value, rule.minEndDate),
  maxSum: (value, rule) => maxSum(value, rule.maxSum),
  addressvalidation: (value, rule) => addressValidation(value, rule),
  validEIN: (value) => isValidEIN(value),
  validPhone: (value) => isValidPhone(value),
  validFax: (value) => isValidFax(value),
  uniqueName: (value) => isUnique(value),
  invalidBoundarySpaces: (value) => isInvalidBoundarySpaces(value),
  website: (value) => isWebsite(value),
  taxId: (value) => isTaxId(value),
  onlyText: (value) => isOnlyText(value),
  validZipCode: (value, rule, state) => isValidZipCode(rule, state),
  validateCharacters: (value) => validateCharacters(value),
  file: (value, acceptedFormats, minSizeMB, maxSizeMB) => {
    const validationResult = validateFile(value, acceptedFormats, minSizeMB, maxSizeMB)
    return [validationResult];
  },
  image: (value, fieldSetting, imageData) => {
    const validationResult = validateImageFile(value, fieldSetting, imageData)
    return [validationResult];
  },
  nagativeNumber: (value) => validateNagativeNumber(value),
  validateJson: (value) => validateJson(value),
  maxCharacterValidator: (value, maxCharacterLength) => {
    const validationResult = maxCharacterValidator(value, maxCharacterLength)
    return [validationResult];
  },
  //  validFileName: (value) => validFileName(value),
};

const getValidator = (type) => {
  return validators[type] || null;
};
