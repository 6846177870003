import { validationTypes } from "../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";

export const rulesFormData = {
    initialState: {
        approvalConfigurationId: 0, ruleName: "", moduleId: "", functionalityId: "", functionalityEventId: "",
        functionalitiesFieldId: "", roleId: "", isFunctional: false
    },
    section: [
        {
            title: "Rules Information Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "ruleName",
                    label: "Rule Name ",
                    Field_Name: "Rule name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "ruleName",
                    fieldSetting: {
                        placeholder: "Enter Rule Name",
                        allowSpace: true,
                        maxLength: 65,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "moduleId",
                    label: "Module ",
                    Field_Name: "Module",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "moduleId",
                    fieldSetting: {
                        placeholder: "Select Module",
                        isEnableOnChange: true
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "functionalityId",
                    label: "Functionality ",
                    Field_Name: "Name",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "functionalityId",
                    fieldSetting: {
                        isDisabled: true,
                        placeholder: "Select Functionality",
                        isEnableOnChange: true
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "functionalityEventId",
                    label: "Functionality Event",
                    Field_Name: "Functionality event",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "functionalityEventId",
                    fieldSetting: {
                        isDisabled: true,
                        placeholder: "Select Functionality Event",
                        isEnableOnChange: true
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "functionalitiesFieldId",
                    label: "Field ",
                    Field_Name: "fieldName",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "functionalitiesFieldId",
                    fieldSetting: {
                        isDisabled: true,
                        placeholder: "Select Field",
                        isEnableOnChange: true
                    },
                    style: {
                        containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "roleId",
                    label: "Approver Role ",
                    Field_Name: "Rolename",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "roleId",
                    fieldSetting: {
                        placeholder: "Select Approver Role",
                        isEnableOnChange: true
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "template",
                    label: "Template",
                    Field_Name: "Template",
                    fieldType: FormFieldTypes.TEXTEDITOR,
                    dataField: "template",
                    fieldSetting: {
                        placeholder: "",
                        allowSpace: true,
                        isDisable: false
                    },
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input ",
                    },
                },
                {
                    id: "isFunctional",
                    label: "Is Functional",
                    Field_Name: "Is Functional",
                    fieldType: FormFieldTypes.CHECKBOX,
                    dataField: "isFunctional",
                    fieldSetting: {
                        placeholder: "",
                        allowSpace: true,
                        isDisable: true
                    },
                    style: {
                        containerCss: "col-xxl-5 col-xl-5 col-md-12 col-12 col-12 mb-input",
                    },
                },
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }
};

export const rulesListData = {
    columns: [
        {
            name: "Module",
            fieldName: "moduleName",
            colStyle: {
                width: "15%",
            },
            allowShort: true,
        },
        {
            name: "Functionality",
            fieldName: "functionalityName",
            colStyle: {
                width: "20%",
            },
            allowShort: true,
        },
        {
            name: "Rule",
            fieldName: "ruleName",
            colStyle: {
                width: "30%",
            },
            allowShort: true,
        },
        {
            name: "Role",
            fieldName: "roleName",
            colStyle: {
                width: "10%",
            },
            allowShort: true,
        },
        {
            name: "Is Functional",
            fieldName: "isFunctional",
            colStyle: {
                width: "15%",
            },
            colType: GridColumnType.CHECKBOX,
            colSettings: {
                //allowCheckbox: true,
                isDisabled: true
            },
        },
        {
            name: "Action",
            colStyle: {
                width: "10%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
            },
        },
    ],

};

