/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//*** Style File's */
import "./EmailManagement.scss";
import DataLoader from "../../../components/FinalMolGrid/ui/dataLoader/DataLoader";

//** Service's */
import ToastService from "../../../services/toastService/ToastService";
import {
  useGetEmailInboxDetailsByIdMutation, useGetEmailInboxListMutation, useLazyGetEmailInboxDetailsMongoDbEmailIdQuery,
  useUpdateEmailInboxFlaggedByEmailIdMutation
} from "../../../app/services/IntentEmailsAPI";
import { useRef } from "react";

//** Component's */
const EmailListManagement = React.lazy(() => import("./feature/emailListManagement/EmailListManagement"));
const EmailBodyManagement = React.lazy(() => import("./feature/emailBodyManagement/EmailBodyManagement"));

const EmailManagement = ({ intentName, emailIntentId }) => {

  const childRef = useRef();
  const [emailObj, setEmailObj] = useState(null);
  const [mongoDbEmailId, setMongoDbEmailId] = useState(0);
  const [intentEmailList, setIntentEmailList] = useState([]);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [emailInboxDetails, setEmailInboxDetails] = useState({});

  const [getEmailInboxDetailsById, { isLoading, isFetching: isGetEmailInboxDetailsByIdFetching, isSuccess: isGetEmailInboxDetailsByIdSuccess,
    data: isGetEmailInboxDetailsByIdData }] = useGetEmailInboxDetailsByIdMutation();
  const [
    updateEmailInboxFlaggedByEmailId,
    {
      isSuccess: isUpdateEmailInboxFlaggedByEmailIdSuccess,
      data: isUpdateEmailInboxFlaggedByEmailIdData,
    },
  ] = useUpdateEmailInboxFlaggedByEmailIdMutation();

  const [
    getEmailInboxList,
    {
      isLoading: isGetEmailInboxListLoading,
      isFetching: isGetEmailInboxListFetching,
      isSuccess: isGetEmailInboxListSuccess,
      data: isGetEmailInboxListData,
    },
  ] = useGetEmailInboxListMutation();

  const [getEmailInboxDetails, { isLoading: isGetByIdLoading, isFetching: isGetEmailInboxDetailsFetching, isSuccess: isGetEmailInboxDetailsSuccess, data: isGetEmailInboxDetailsData }
  ] = useLazyGetEmailInboxDetailsMongoDbEmailIdQuery();

  useEffect(() => {
    handleGetList(emailIntentId, null, null, true,null);
  }, [emailIntentId]);

  useEffect(() => {
    if (!isGetEmailInboxListFetching && isGetEmailInboxListSuccess && isGetEmailInboxListData) {
      if (isGetEmailInboxListData) {
        setIntentEmailList(isGetEmailInboxListData);
        if (childRef.current) {
          childRef.current.handleEmailClick(isGetEmailInboxListData[0]?.mongoDbEmailId);
        }
      }
    }
  }, [isGetEmailInboxListFetching, isGetEmailInboxListSuccess, isGetEmailInboxListData]);

  useEffect(() => {
    if (isUpdateEmailInboxFlaggedByEmailIdSuccess && isUpdateEmailInboxFlaggedByEmailIdData) {
      ToastService.success(isUpdateEmailInboxFlaggedByEmailIdData.errorMessage);
    }
  }, [isUpdateEmailInboxFlaggedByEmailIdSuccess, isUpdateEmailInboxFlaggedByEmailIdData]);

  useEffect(() => {
    if (!isGetEmailInboxDetailsByIdFetching && isGetEmailInboxDetailsByIdSuccess && isGetEmailInboxDetailsByIdData) {
      if (isGetEmailInboxDetailsByIdData) {
        if (isGetEmailInboxDetailsByIdData.statusCode === 200) {
          setEmailObj(isGetEmailInboxDetailsByIdData);
        }
        else if (isGetEmailInboxDetailsByIdData.statusCode === 404) {
          ToastService.warning("Email Details Not Found");
        }
      }
    }
  }, [isGetEmailInboxDetailsByIdFetching, isGetEmailInboxDetailsByIdSuccess, isGetEmailInboxDetailsByIdData]);

  useEffect(() => {
    if (!isGetEmailInboxDetailsFetching && isGetEmailInboxDetailsSuccess && isGetEmailInboxDetailsData) {
      isGetEmailInboxDetailsData && setEmailInboxDetails(isGetEmailInboxDetailsData);
    }
  }, [isGetEmailInboxDetailsFetching, isGetEmailInboxDetailsSuccess, isGetEmailInboxDetailsData]);

  const handleEmailChange = (id) => {
    setEmailObj(null);
    setMongoDbEmailId(Number(id));
    setIntentEmailList((prevEmails) => prevEmails.map((email) => email.mongoDbEmailId === id ? { ...email, isEmailRead: true } : email));
    getEmailInboxDetailsById(id);
  };
  const handleGetList = (emailIntentId, isEmailRead = null, isEmailFlagged = null, isShortingFlag = null, isEmailDateTimeShortingFlag=null) => {
    const request = {
      emailIntentId: String(emailIntentId),
      isEmailRead: isEmailRead,
      isEmailFlagged: isEmailFlagged,
      isShortingFlag: isShortingFlag,
      isEmailDateTimeShortingFlag : isEmailDateTimeShortingFlag,
    };
    getEmailInboxList(request);
  };
  const handleFlagChange = (emailId, updatedEmails) => {
    const request = {
      mongoDbEmailId: emailId,
      isEmailFlagged: updatedEmails.find((email) => email.mongoDbEmailId === emailId).isEmailFlagged,
    };
    updateEmailInboxFlaggedByEmailId(request);
    setIntentEmailList((prevEmails) =>
      prevEmails.map((email) => email.mongoDbEmailId === emailId ? { ...email, isEmailFlagged: request.isEmailFlagged } : email)
    );
  };

  const handleRefreshEmailList = () => {
    handleGetList(emailIntentId);
  }

  useEffect(() => {
    handleGetByIdRefresh();
  }, [mongoDbEmailId]);

  const handleGetByIdRefresh = () => {
    mongoDbEmailId && getEmailInboxDetails(mongoDbEmailId);
  }

  const selectedEmail = intentEmailList.find((email) => email.mongoDbEmailId === selectedEmailId);

  return (
    <React.Fragment>
      <div className="email-management">
        <div className={`email-management-list collapsed`}>
          <EmailListManagement intentName={intentName} emailIntentId={emailIntentId} selectedEmail={selectedEmail} selectedEmailId={selectedEmailId}
            intentEmailList={intentEmailList} setSelectedEmailId={setSelectedEmailId} handleGetList={handleGetList} isGetEmailInboxListLoading={isGetEmailInboxListLoading}
            handleFlagChange={handleFlagChange} handleEmailChange={handleEmailChange} childRef={childRef} />
        </div>

        <div className={`email-management-detail collapsed`} >
          {!isGetByIdLoading ?
            <EmailBodyManagement emailIntentId={emailIntentId} emailInboxDetails={emailInboxDetails} isLoading={isLoading} mongoDbEmailId={mongoDbEmailId}
              handleRefreshEmailList={handleRefreshEmailList} childRef={childRef}
              selectedEmail={selectedEmail} emailObj={emailObj} handleGetByIdRefresh={handleGetByIdRefresh} intentEmailList={intentEmailList} />
            : <DataLoader />
          }
        </div>
      </div>
    </React.Fragment>
  );
};

EmailManagement.propTypes = {
  intentName: PropTypes.string.isRequired,
  emailIntentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
export default EmailManagement;
