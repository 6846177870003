/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  useAddApiEventResponseFieldsMappingMutation,
  useLazyGetAllResponseFieldsByEventIdQuery,
} from "../../../../../../../../app/services/thirdPartyAPI";
import { AddEditRequiredMappingData } from "../config/AddEditRequiredMapping.data";
import ToastService from "../../../../../../../../services/toastService/ToastService";
import { setDropDownOptionField } from "../../../../../../../../utils/FormFields/FieldsSetting/SetFieldSetting";
import { onResetForm } from "../../../../../../../../utils/FormFields/ResetForm/handleResetForm";
import FormCreator from "../../../../../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../../../../../components/ui/button/Buttons";
import { useLazyGetAllAPIEndpointsQuery } from "../../../../../../../../app/services/apiParametersAPI";
import PropTypes from "prop-types";

const AddEditRequiredMapping = (props) => {
  const addEditMappingRef = useRef();
  const [addEditMappingData, setAddEditMappingData] = useState(
    AddEditRequiredMappingData
  );
  const [
    getAllAPIEndpoints,
    { isSuccess: isGetAllAPIEndpointsSucess, data: allGetAllAPIEndpointsData },
  ] = useLazyGetAllAPIEndpointsQuery();
  const [
    getAllResponseFields,
    {
      isSuccess: isGetAllResponseFieldsSucess,
      data: isGetAllResponseFieldsData,
    },
  ] = useLazyGetAllResponseFieldsByEventIdQuery();
  const [
    addApiEventResponseFieldsMapping,
    {
      isLoading: isAddApiEventResponseFieldsMappingLoading,
      isSuccess: isAddApiEventResponseFieldsMappingSuccess,
      data: allAddApiEventResponseFieldsMappingData,
    },
  ] = useAddApiEventResponseFieldsMappingMutation();

  useEffect(() => {
    getAllAPIEndpoints();
    getAllResponseFields(props.keyId);
  }, []);

  useEffect(() => {
    if (
      isAddApiEventResponseFieldsMappingSuccess &&
      allAddApiEventResponseFieldsMappingData
    ) {
      if (
        allAddApiEventResponseFieldsMappingData.errorMessage.includes("exists")
      ) {
        ToastService.warning(
          allAddApiEventResponseFieldsMappingData.errorMessage
        );

        return;
      }
      ToastService.success(
        allAddApiEventResponseFieldsMappingData.errorMessage
      );
      handleResetAndClose();
      props.onGetData();
    }
  }, [
    isAddApiEventResponseFieldsMappingSuccess,
    allAddApiEventResponseFieldsMappingData,
  ]);

  useEffect(() => {
    if (isGetAllAPIEndpointsSucess && allGetAllAPIEndpointsData) {
      setDropDownOptionField(
        allGetAllAPIEndpointsData,
        "endpointId",
        "name",
        AddEditRequiredMappingData,
        "endpointId"
      );
    }
    if (isGetAllResponseFieldsSucess && isGetAllResponseFieldsData) {
      setDropDownOptionField(
        isGetAllResponseFieldsData,
        "apiEventResponseFieldId",
        "fieldName",
        AddEditRequiredMappingData,
        "apiEventResponseFieldId"
      );
    }
  }, [
    isGetAllAPIEndpointsSucess,
    allGetAllAPIEndpointsData,
    isGetAllResponseFieldsSucess,
    isGetAllResponseFieldsData,
  ]);

  const handleAddEditAPIPRovider = () => {
    const formData = addEditMappingRef.current.getFormData();
    if (formData) {
      let request = {
        ...formData,
        apiEventId: props.keyId ? props.keyId : 0,
        apiEventResponseFieldId: formData.apiEventResponseFieldId.value,
        apiResponseFieldName: formData.apiResponseFieldName,
      };
      addApiEventResponseFieldsMapping(request);
    }
  };

  useEffect(() => {
    onResetForm(addEditMappingData, setAddEditMappingData, null);
  }, [props.isOpen]);

  const handleResetAndClose = () => {
    onResetForm(addEditMappingData, setAddEditMappingData, null);
    props.onClose();
  };

  return (
    <div className="row mt-2 add-address-form">
      <div className="col-12">
        <FormCreator config={addEditMappingData} ref={addEditMappingRef} />
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex align-item-end justify-content-end">
          <Buttons
            buttonTypeClassName="theme-button"
            buttonText="Save"
            onClick={handleAddEditAPIPRovider}
            isLoading={isAddApiEventResponseFieldsMappingLoading}
          />
          <Buttons
            buttonTypeClassName="dark-btn ml-5"
            buttonText="Cancel"
            onClick={handleResetAndClose}
          />
        </div>
      </div>
    </div>
  );
};

AddEditRequiredMapping.propTypes = {
  keyId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onGetData: PropTypes.func,
  getData: PropTypes.shape({
    apiEventRequiredFieldId: PropTypes.shape({
      value: PropTypes.any,
    }),
  }),
};

export default AddEditRequiredMapping;
