import React, { useState } from "react";
import RenderTabs from "../../../../../../../components/ui/tabs/RenderTabs";
import EventMapping from "../eventMapping/EventMapping";
import EventRequiredFields from "../eventRequiredFields/EventRequiredFields";
import RequiredFieldsMapping from "../requiredFieldsMapping/RequiredFieldsMapping";
import EventParamter from "../eventParamter/EventParamter";
import EventHistory from "../eventHistory/EventHistory";
import PropTypes from "prop-types";

const ThirdPartyApiConfigurationViewTab = ({ keyId, viewCardDetails }) => {
  const [endpointId, setEndpointId] = useState(0);

  const tabs = [
    {
      sMenuItemCaption: "Subscriber",
      component: (
        <div className="mt-2 subscriber-event-list">
          <EventMapping
            keyId={keyId}
            setEndpointId={setEndpointId}
            endpointId={endpointId}
            viewCardDetails={viewCardDetails}
          />
        </div>
      ),
      // isVisible: hasAddressPermission.hasAccess,
    },
    {
      sMenuItemCaption: "Event Fields",
      component: (
        <div className="mt-2 event-fields-list">
          <EventParamter keyId={keyId} />
        </div>
      ),
      // isVisible: hasContactPermission.hasAccess,
    },
    // {
    //     sMenuItemCaption: "Parameter Mapping",
    //     component: (
    //         <div className="mt-2">
    //             <ParameterMapping
    //                 keyId={keyId}
    //                 endpointId={endpointId}
    //             />
    //         </div>
    //     ),
    //     // isVisible: hasContactPermission.hasAccess,/
    // },
    {
      sMenuItemCaption: "Event Response Fields",
      component: (
        <div className="mt-2 event-response-fields">
          <EventRequiredFields keyId={keyId} />
        </div>
      ),
      // isVisible: hasDocumentPermission.hasAccess,
    },
    {
      sMenuItemCaption: "Required Fields Mapping",
      component: (
        <div className="mt-2 required-fields-map">
          <RequiredFieldsMapping keyId={keyId} />
        </div>
      ),
      // isVisible: hasNotePermission.hasAccess,
    },
    {
      sMenuItemCaption: "History Logs",
      component: (
        <div className="mt-2 history-logs-list">
          <EventHistory keyId={keyId} />
        </div>
      ),
      // isVisible: hasNotePermission.hasAccess,
    },
  ];
  return (
    // <CardSection
    //     cardTitle="Address"
    //     buttonClassName="theme-button"

    // >
    <div className="vertical-tab-inner configuration-tabs">
      <RenderTabs tabs={tabs} isCollapse={true} />
    </div>
    // </CardSection>
  );
};

ThirdPartyApiConfigurationViewTab.propTypes = {
  keyId: PropTypes.number,
};

export default ThirdPartyApiConfigurationViewTab;
