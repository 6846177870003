import React from "react";
import "./index.scss";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "bs-stepper/dist/css/bs-stepper.min.css";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer autoClose={3000} />
    <App />
  </Provider>
);

reportWebVitals();