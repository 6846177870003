
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const systemTaskAPI = createApi({
    reducerPath: "systemTaskAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        getSystemTaskDetailBySystemTaskId: builder.query({
            query: (systemTaskId) => ({
                url: encryptQueryString(`/SystemTask/GetSystemTaskDetailBySystemTaskId/?systemTaskId=${systemTaskId}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getAllSystemTask: builder.mutation({
            query: (requestData) => ({
                url: encryptQueryString(`/SystemTask/GetAllSystemTask`),
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getWorkflowStepsByWorkflowId: builder.query({
            query: (requestData) => ({
                url: encryptQueryString(`/Workflow/GetWorkflowStepsByWorkflowId/?workflowId=${requestData.workflowId}&orderId=${requestData.orderId}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getWorkflowActionsByStepId: builder.query({
            query: (stepId) => ({
                url: encryptQueryString(`/Workflow/GetWorkflowActionsByStepId/?stepId=${stepId}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addSaveEmailLogs: builder.mutation({
            query: (requestData) => ({
                url: '/Common/AddSaveEmailLogs',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        executeAction: builder.mutation({
            query: (requestData) => ({
                url: 'Workflow/ExecuteAction',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addWorkflowResponse: builder.mutation({
            query: (requestData) => ({
                url: '/Workflow/AddWorkflowResponse',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getWorkflowResponseByOrderId: builder.query({
            query: (orderId) => ({
                url: encryptQueryString(`/Workflow/GetWorkflowResponseByOrderId/?orderId=${orderId}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getWorkflowResponseBySystemTaskId: builder.query({
            query: (systemTaskId) => ({
                url: encryptQueryString(`Workflow/GetWorkflowResponseBySystemTaskId?systemTaskId=${systemTaskId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getCustomFormFieldAndButtonByCustomFormId: builder.query({
            query: (customFormId) => ({
                url: encryptQueryString(`FormField/GetCustomFormFieldAndButtonByCustomFormId?customFormId=${customFormId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAllSystemTaskConfigurationByUserId: builder.query({
            query: (data) => ({
                url: encryptQueryString(`/Common/GetAllSystemTaskConfigurationByUserId?loginUserId=${data.loginUserId}&isOpen=${data.isOpen}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // system tasks Api

        getSystemTaskList: builder.mutation({
            query: (requestData) => ({
                url: '/SystemTask/GetSystemTaskList',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getAllSystemBadgeBySystemTaskId: builder.query({
            query: (data) => ({
                url: encryptQueryString(`/SystemTask/GetAllSystemBadgeBySystemTaskId?systemTaskId=${data.systemTaskId}&isShowPassBadges=${data.isShowPassBadges}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,

        }),
        getSystemTaskBadgeResponseBySystemTaskBadgeId: builder.query({
            query: (systemTaskBadgeId) => ({
                url: encryptQueryString(`/SystemTask/GetSystemTaskBadgeResponseBySystemTaskBadgeId?systemTaskBadgeId=${systemTaskBadgeId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateSystemTaskBadgeStatusById: builder.mutation({
            query: (requestData) => ({
                url: '/SystemTask/UpdateSystemTaskBadgeStatusById',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addEditSystemTaskComments: builder.mutation({
            query: (requestData) => ({
                url: '/SystemTask/AddEditSystemTaskComments',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getSystemTaskCommentsBySystemTaskBadgeId: builder.query({
            query: (systemTaskBadgeId) => ({
                url: encryptQueryString(`/SystemTask/GetSystemTaskCommentsBySystemTaskBadgeId?systemTaskBadgeId=${systemTaskBadgeId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addCustomerCommunicationEmail: builder.mutation({
            query: (requestData) => ({
                url: '/Order/AddCustomerCommunicationEmail',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getCustomerCommunicationDetailsBySystemTaskId: builder.query({
            query: (systemTaskId) => ({
                url: encryptQueryString(`/SystemTask/GetCustomerCommunicationDetailsBySystemTaskId?systemTaskId=${systemTaskId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,

        }),
    })
})

export const {
    useLazyGetSystemTaskDetailBySystemTaskIdQuery, useGetAllSystemTaskMutation, useLazyGetWorkflowStepsByWorkflowIdQuery,
    useLazyGetWorkflowActionsByStepIdQuery, useAddSaveEmailLogsMutation, useExecuteActionMutation,
    useAddWorkflowResponseMutation, useLazyGetWorkflowResponseByOrderIdQuery, useLazyGetWorkflowResponseBySystemTaskIdQuery,
    useLazyGetCustomFormFieldAndButtonByCustomFormIdQuery,
    useLazyGetAllSystemTaskConfigurationByUserIdQuery, useGetSystemTaskListMutation,
    useLazyGetAllSystemBadgeBySystemTaskIdQuery, useLazyGetSystemTaskBadgeResponseBySystemTaskBadgeIdQuery,
    useUpdateSystemTaskBadgeStatusByIdMutation, useAddEditSystemTaskCommentsMutation, useLazyGetSystemTaskCommentsBySystemTaskBadgeIdQuery,
    useAddCustomerCommunicationEmailMutation, useLazyGetCustomerCommunicationDetailsBySystemTaskIdQuery
} = systemTaskAPI

export default systemTaskAPI;