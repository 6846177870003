import Iconify from "../../../components/ui/iconify/Iconify";

const CustomFormsDetailInfoCard=({formData,editClick})=>{
    return (
    
            <div className="basic-customer-detail">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="d-flex gap-5 profile-info  justify-content-between col-12">
            <div className="col-4">
              <div className="field-desc">
                <div className="inf-label">Form Name </div>
                <b>&nbsp;:&nbsp;</b>
                <div className="info-desc">
                    {formData?.formName}
                    </div>
              </div>
            </div>
            <div className="col-4">
              <div className="field-desc">
                <div className="inf-label">Module </div>
                <b>&nbsp;:&nbsp;</b>
                <div className="info-desc">
                    {formData?.moduleName}
                    </div>
              </div>
            </div>
            <div className="col-4">
              <div className="field-desc">
                <div className="inf-label">Is Active</div>
                <b>&nbsp;&nbsp;&nbsp;:&nbsp;</b>
                <div className="info-desc">
                  {formData && formData?.isActive ? (
                  <i className="fa fa-check green-color"></i>
                ) : (
                  <i className="fa fa-times red-color"></i>
                )}
                </div>
              </div>
            </div>
        
           
          </div>
          <button className="edit-icons" style={{ border: 'unset' }}
            onClick={editClick}
           >
          
            <Iconify icon="tabler:pencil" />
          </button>
        </div>
      </div>
            
        )
}
export default CustomFormsDetailInfoCard;