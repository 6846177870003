import { createApi } from "@reduxjs/toolkit/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';


const orderAPI = createApi({
    reducerPath: 'orderAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        checkPoNumberExistOrNot: builder.mutation({
            query: (Details) => ({
                url: '/Order/CheckPoNumberExistOrNot',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getPoNumberDetailsByPoNumber: builder.query({
            query: (number) => ({
                url: encryptQueryString(`/Order/GetPoNumberDetailsByPoNumber/?poNumber=${String(number)}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addOrder: builder.mutation({
            query: (data) => ({
                url: '/Order/AddOrder',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getOrders: builder.mutation({
            query: (userQuery) => ({
                url: '/Order/GetOrders',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getOrderDetailByOrderId: builder.query({
            query: (orderId) => ({
                url: encryptQueryString(`Order/GetOrderDetailByOrderId?orderId=${orderId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getOrderInformationByOrderId: builder.query({
            query: (orderId) => ({
                url: encryptQueryString(`Order/GetOrderDetailByOrderId?orderId=${orderId}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getOrderItemsByOrderId: builder.query({
            query: (orderId) => ({
                url: encryptQueryString(`/Order/GetOrderItemsByOrderId/?orderId=${orderId}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        deleteOrder: builder.mutation({
            query: (data) => ({
                url: encryptQueryString(`/Order/DeleteOrder/?orderId=${data.orderId}&isHardDelete=${data.isHardDelete}`),
                method: 'DELETE'
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        downloadDocument: builder.query({
            query: (requestData) => ({
                url: encryptQueryString(`/Common/DownloadDocument/?bucketUuid=${requestData.bucketUuid}&fileUuidName=${requestData.fileUuidName}&filename=${requestData.fileName}`),
                Method: 'GET',
                responseHandler: (response) => response.blob()
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addOrderDocuments: builder.mutation({
            query: (requestData) => ({
                url: '/Order/AddOrderDocuments',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getAddresssByCustomerId: builder.query({
            query: (customerId) => ({
                url: encryptQueryString(`/Address/GetAddresssByCustomerId/?customerId=${customerId}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse

        }),
        getContactByCustomerId: builder.query({
            query: (data) => ({
                url: encryptQueryString(`/Contact/GetContactByCustomerId/?customerId=${data.customerId}&searchText=${data.searchText}&searchContactType=${data.searchContactType}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        updateOrderAddress: builder.mutation({
            query: (requestData) => ({
                url: '/Order/UpdateOrderAddress',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        deleteOrderItem: builder.mutation({
            query: (orderItemId) => ({
                url: encryptQueryString(`/Order/DeleteOrderItems/?orderItemId=${orderItemId}`),
                method: 'DELETE'
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateOrderContact: builder.mutation({
            query: (requestData) => ({
                url: '/Order/UpdateOrderContact',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getOrderItemByOrderItemId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/Order/GetOrderItemByOrderItemId/?orderItemId=${id}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateOrderItemByOrderItemId: builder.mutation({
            query: (requestData) => ({
                url: '/Order/UpdateOrderItemByOrderItemId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        deleteOrderDocuementById: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/Order/DeleteOrderDocuementById/?OrderDocumentId=${id}`),
                method: 'DELETE'
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateOrderDetail: builder.mutation({
            query: (requestData) => ({
                url: '/Order/UpdateOrderDetail',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getOrderHistoryByOrderId: builder.query({
            query: (orderId) => ({
                url: encryptQueryString(`/Order/GetOrderHistoryByOrderId/?orderId=${Number(orderId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getUndefinedOrderEmails: builder.mutation({
            query: (Details) => ({
                url: '/Order/GetUndefinedOrderEmails',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addUndefinedOrderEmails: builder.mutation({
            query: (Details) => ({
                url: '/Order/AddUndefinedOrderEmails',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getUndefinedOrdersEmailsById: builder.mutation({
            query: (emailId) => ({
                url: `/Order/GetUndefinedOrdersEmailsById/?mailid=${emailId}`,
                method: 'POST',
                //  body: transformRequest(emailId)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addEditDraftOrder: builder.mutation({
            query: (Details) => ({
                url: '/Order/AddEditDraftOrder',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        UpdateOrderBasicInfoByOrderId: builder.mutation({
            query: (requestData) => ({
                url: '/Order/UpdateOrderBasicInfoByOrderId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getDraftOrders: builder.mutation({
            query: (userQuery) => ({
                url: '/Order/GetDraftOrder',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        updateFinanceContactsByOrderId: builder.mutation({
            query: (Details) => ({
                url: '/Order/UpdateFinanceContactsByOrderId',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getApprovedOrders: builder.mutation({
            query: (userQuery) => ({
                url: '/Order/GetApprovedOrder',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getPendingApprovalOrder: builder.mutation({
            query: (userQuery) => ({
                url: '/Order/GetPendingApprovalOrder',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getPendingActionOrder: builder.mutation({
            query: (userQuery) => ({
                url: '/Order/GetPendingActionOrder',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getPendingActionStatusByOrderId: builder.query({
            query: (request) => ({
                url: encryptQueryString(`/Order/GetPendingActionStatusByOrderId/?orderId=${request.orderId}&isCustomer=${request.isCustomer}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getOrderCommentsByOrderId: builder.query({
            query: (orderId) => ({
                url: `/Order/GetOrderCommentsByOrderId/?orderId=${orderId}`,
                method: 'GET',

            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addOrderComments: builder.mutation({
            query: (requestData) => ({
                url: `/Order/AddOrderComments`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getOrderBadges: builder.mutation({
            query: (requestData) => ({
                url: `/Order/GetOrderBadges`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateOrderBadgeDetails: builder.mutation({
            query: (Details) => ({
                url: '/Order/UpdateOrderBadgeDetails',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addEditOrderOtherCharge: builder.mutation({
            query: (Details) => ({
                url: '/Order/AddEditOrderOtherCharge',
                method: 'POST',
                body: transformRequest(Details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        deleteOrderOtherChargeById: builder.mutation({
            query: (orderOtherChargeId) => ({
                url: encryptQueryString(`/Order/DeleteOrderOtherChargeById/?orderOtherChargeId=${orderOtherChargeId}`),
                method: 'DELETE'
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateOrderStatus: builder.mutation({
            query: (Details) => ({
                url: `/Order/UpdateOrderStatus?orderId=${Details.orderId}&orderStatusId=${Details.orderStatusId}`,
                method: 'POST',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getOrderCommunicationEmailHistoryByOrderId: builder.query({
            query: (orderId) => ({
                url: `/Order/GetOrderCommunicationEmailHistoryByOrderId/?orderId=${orderId}`,
                method: 'GET',

            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        validateOrderAndCustomerDetils: builder.query({
            query: (requestData) => ({
                url: `/SystemTask/ValidateOrderAndCustomerDetils/?orderId=${requestData.orderId}&orderSubStatusId=${requestData.orderSubStatusId}
                &validateAllConditions=${requestData.validateAllConditions}`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SplitOrderItem: builder.mutation({
            query: (requestData) => ({
                url: '/Order/SplitOrderItem',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        
    })
})

export const { useCheckPoNumberExistOrNotMutation,
    useLazyGetPoNumberDetailsByPoNumberQuery,
    useAddOrderMutation,
    useGetOrdersMutation,
    useLazyGetOrderDetailByOrderIdQuery,
    useLazyGetOrderInformationByOrderIdQuery,
    useLazyGetOrderItemsByOrderIdQuery,
    useDeleteOrderMutation,
    useLazyDownloadDocumentQuery,
    useAddOrderDocumentsMutation,
    useLazyGetAddresssByCustomerIdQuery,
    useLazyGetContactByCustomerIdQuery,
    useUpdateOrderAddressMutation,
    useDeleteOrderItemMutation,
    useUpdateOrderContactMutation,
    useLazyGetOrderItemByOrderItemIdQuery,
    useUpdateOrderItemByOrderItemIdMutation,
    useDeleteOrderDocuementByIdMutation,
    useUpdateOrderDetailMutation,
    useLazyGetOrderHistoryByOrderIdQuery,
    useGetUndefinedOrderEmailsMutation,
    useAddUndefinedOrderEmailsMutation,
    useGetUndefinedOrdersEmailsByIdMutation,
    useAddEditDraftOrderMutation,
    useUpdateOrderBasicInfoByOrderIdMutation,
    useGetDraftOrdersMutation,
    useUpdateFinanceContactsByOrderIdMutation,
    useGetApprovedOrdersMutation,
    useGetPendingApprovalOrderMutation,
    useGetPendingActionOrderMutation,
    useLazyGetPendingActionStatusByOrderIdQuery,
    useLazyGetOrderCommentsByOrderIdQuery,
    useAddOrderCommentsMutation,
    useGetOrderBadgesMutation,
    useUpdateOrderBadgeDetailsMutation,
    useAddEditOrderOtherChargeMutation,
    useDeleteOrderOtherChargeByIdMutation,
    useUpdateOrderStatusMutation,
    useLazyGetOrderCommunicationEmailHistoryByOrderIdQuery,
    useLazyValidateOrderAndCustomerDetilsQuery,
    useSplitOrderItemMutation
} = orderAPI;

export default orderAPI;    