/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef } from "react";
import FinalMolGrid from "../../../../../../components/FinalMolGrid/FinalMolGrid";
import { WorkflowGridConfig } from "./config/Workflow.data";
import { useLazyGetWorkflowDetailsByIdQuery } from "../../../../../../app/services/workFlowAPI";

const WorkflowList = ({ systemTaskConfigurationId, setListData, listData, setWorkFlowId, handleEditClick, childRef }) => {
  const molGridRef = useRef();
  const [getWorkflowDetailsById, { isLoading: isGetWorkflowDetailsByIdLoading, isSuccess: isGetWorkflowDetailsByIdSuccess, data: isGetWorkflowDetailsByIdData, },] = useLazyGetWorkflowDetailsByIdQuery();

  useEffect(() => {
    getWorkflowDetailsById(systemTaskConfigurationId)
  }, [systemTaskConfigurationId])

  useEffect(() => {
    if (isGetWorkflowDetailsByIdSuccess && isGetWorkflowDetailsByIdData) {

      if (isGetWorkflowDetailsByIdData) {
        setWorkFlowId(isGetWorkflowDetailsByIdData.workflowId)
        setListData([isGetWorkflowDetailsByIdData]);
      }
    }
  }, [isGetWorkflowDetailsByIdSuccess, isGetWorkflowDetailsByIdData]);

  const actionHandler = {
    EDIT: handleEditClick,

  };

  const onGetData = () => {
    getWorkflowDetailsById(systemTaskConfigurationId)
  }
  useImperativeHandle(childRef, () => ({
    callChildFunction: onGetData,
  }));
  return (<div className="row">
    <div className="col-md-12 table-striped api-provider">
      <FinalMolGrid
        ref={molGridRef}
        configuration={WorkflowGridConfig}
        dataSource={listData}
        allowPagination={false}
        isLoading={isGetWorkflowDetailsByIdLoading}
        onActionChange={actionHandler}
      />
    </div>
  </div>)
}
export default WorkflowList;