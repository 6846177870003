/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import FormCreator from "../../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../../components/ui/button/Buttons";
import { AddEditCustomFormButtonsFormData } from "../config/FormButtons.data";
import { useAddEditFormButtonMutation, useLazyGetFormButtonByFormButtonIdQuery } from "../../../../../app/services/customFormsAPI";
import { useEffect } from "react";
import ToastService from "../../../../../services/toastService/ToastService";
import { onResetForm } from "../../../../../utils/FormFields/ResetForm/handleResetForm";

const AddEditCustomFormButton = ({ isEdit, onSuccess, customFormId, onClose, initData }) => {

    const formFieldRef = useRef();

    const [formData, setFormData] = useState(AddEditCustomFormButtonsFormData);
    const [addEditCustomButton, { isLoading: isAddEditFormButtonLoading, isSuccess: isAddEditFormButtonSuccess, data: isAddEditFormButtonData, },] = useAddEditFormButtonMutation();

    const [getFormButtonByFormButtonId, { isFetching: isGetFormButtonByFormButtonIdFetching, isSuccess: isGetFormButtonByFormButtonIdSuccess, data: isGetFormButtonByFormButtonIdData, },] = useLazyGetFormButtonByFormButtonIdQuery();

    useEffect(() => {
        if (initData.formButtonId && isEdit) {
            getFormButtonByFormButtonId(initData.formButtonId);
        }
    }, [initData.formButtonId, isEdit]);


    useEffect(() => {
        if (!isGetFormButtonByFormButtonIdFetching && isGetFormButtonByFormButtonIdSuccess && isGetFormButtonByFormButtonIdData) {
            if (isGetFormButtonByFormButtonIdData) {
                let buttonformData = { ...formData };
                buttonformData.initialState = {
                    formButtonId: isGetFormButtonByFormButtonIdData.formButtonId,
                    buttonName: isGetFormButtonByFormButtonIdData.buttonName,
                    buttonDescription: isGetFormButtonByFormButtonIdData.buttonDescription,
                    eventAction: isGetFormButtonByFormButtonIdData.eventAction,
                    parameterJson: isGetFormButtonByFormButtonIdData.parameterJson
                };
                setFormData(buttonformData);
            }
        }
    }, [isGetFormButtonByFormButtonIdFetching, isGetFormButtonByFormButtonIdSuccess, isGetFormButtonByFormButtonIdData,]);

    useEffect(() => {
        if (isAddEditFormButtonSuccess && isAddEditFormButtonData) {
            if (isAddEditFormButtonData.errorMessage?.includes("exists")) {
                ToastService.warning(isAddEditFormButtonData.errorMessage);
                return;
              }
            ToastService.success(isAddEditFormButtonData.errorMessage);
            onSuccess();
            onClose();
            onResetForm(AddEditCustomFormButtonsFormData, setFormData, null);

        }
    }, [isAddEditFormButtonSuccess, isAddEditFormButtonData]);

    const handleResetAndClose = () => {
        onResetForm(AddEditCustomFormButtonsFormData, setFormData, null);
        onClose();
    };

    const handleSaveClick = () => {
        const formData = formFieldRef.current.getFormData();
        if (formData) {
            const requestData = {
                ...formData,

                customFormId: customFormId
            };
            addEditCustomButton(requestData);
        }
    };
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="vertical-form">
                    <FormCreator
                        ref={formFieldRef}
                        config={formData}
                    />
                </div>
            </div>
            <div className="col-md-12 mt-2">
                <div className="d-flex align-item-center justify-content-end">
                    <Buttons
                        buttonTypeClassName="theme-button"
                        buttonText={isEdit ? "Update" : "Save"}
                        onClick={handleSaveClick}
                        isLoading={isAddEditFormButtonLoading}
                    />
                    <Buttons
                        buttonTypeClassName="dark-btn ml-5"
                        buttonText="Cancel"
                        onClick={handleResetAndClose}
                    />
                </div>
            </div>
        </div>
    )
}

AddEditCustomFormButton.propTypes = {
    isEdit: PropTypes.bool,
    onSuccess: PropTypes.func,
    customFormId: PropTypes.number,
    onClose: PropTypes.func,
    initData: PropTypes.shape({
        formButtonId: PropTypes.number,
        buttonName: PropTypes.string,
        buttonDescription: PropTypes.string,
        eventAction: PropTypes.string,
        parameterJson: PropTypes.string
    })
};

export default AddEditCustomFormButton;