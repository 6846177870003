/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FinalMolGrid from "../../../../../../components/FinalMolGrid/FinalMolGrid";
import { useGetWorkflowStepsListByWorkflowIdMutation } from "../../../../../../app/services/workFlowAPI";
import { WorkflowStepsGridConfig } from "./config/WorkflowSteps.data";
import SidebarModel from "../../../../../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../../../../../data/appIcons";
import AddEditWorkflowAction from "../workflowAction/AddEditWorkflowAction";


const WorkflowStepsList = ({ workFlowId,handleEditClick ,stepRef}) => {
  const molGridRef = useRef();
  const [listData, setListData] = useState();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isModalOpen,setIsModelOpen]=useState(false);
  const [stepId,setStepId]=useState(null)

  const [getWorkflowStepsListByWorkflowId, { isLoading: isGetWorkflowStepsListByWorkflowIdLoading, isSuccess: isGetWorkflowStepsListByWorkflowIdSuccess, data: isGetWorkflowStepsListByWorkflowIdData, },] =  useGetWorkflowStepsListByWorkflowIdMutation();

  useEffect(() => {
    onGetData()
  }, [])

  const getLists = (pageObject, sortingString) => {
    const request = {
      pagination: {
        pageNumber: pageObject.pageNumber,
        pageSize: pageObject.pageSize,
      },
      filters: { searchText: "" },
      sortString: sortingString,
      workflowId: workFlowId,
    };
    getWorkflowStepsListByWorkflowId(request);
  };

  const handlePageChange = (page) => {
    getLists(page, molGridRef.current.generateSortingString());
  };
  const handleSorting = (shortString) => {
    getLists(molGridRef.current.getCurrentPageObject(), shortString);
  };
  const onGetData = () => {
    if (molGridRef.current) {
      const defaultPageObject = molGridRef.current.getCurrentPageObject();
      getLists(defaultPageObject, molGridRef.current.generateSortingString());
    }
  };

  useEffect(() => {
    if (isGetWorkflowStepsListByWorkflowIdSuccess && isGetWorkflowStepsListByWorkflowIdData) {
      if (isGetWorkflowStepsListByWorkflowIdData) {
        setListData(isGetWorkflowStepsListByWorkflowIdData.dataSource);
      }
      if (isGetWorkflowStepsListByWorkflowIdData.totalRecord) {
        setTotalRowCount(isGetWorkflowStepsListByWorkflowIdData.totalRecord);
      }
    }
  }, [isGetWorkflowStepsListByWorkflowIdSuccess, isGetWorkflowStepsListByWorkflowIdData]);


  const handleAddActionClick=(data)=>{
    setStepId(data.stepId)
    setIsModelOpen(true)
  }
  const onSidebarClose=()=>{
    setIsModelOpen(false)
  }
  const actionHandler = {
    EDIT: handleEditClick,
    ADDWORKFLOWACTION: handleAddActionClick
  };

  useImperativeHandle(stepRef, () => ({
    callChildFunction: onGetData,
  }));
  return (<div className="row">
    <div className="col-md-12 table-striped api-provider">
      <FinalMolGrid
        ref={molGridRef}
        configuration={WorkflowStepsGridConfig}
        dataSource={listData}
        allowPagination={true}
        pagination={{
          totalCount: totalRowCount,
          pageSize: 25,
          currentPage: 1,
        }}
        onPageChange={handlePageChange}
        onSorting={handleSorting}
        isLoading={isGetWorkflowStepsListByWorkflowIdLoading}
        onActionChange={actionHandler}
      />
    </div>

    <SidebarModel
        modalTitle={"Add/Edit Workflow Action"}
        contentClass="content-60"
         onClose={onSidebarClose}
         modalTitleIcon={AppIcons.AddIcon}
         isOpen={isModalOpen}
      >
        <AddEditWorkflowAction
          stepID={stepId}
          onClose={onSidebarClose}
          isModelOpen={isModalOpen}
          workFlowId={workFlowId}
        />
      </SidebarModel>
  </div>)
}
export default WorkflowStepsList;