import { validationTypes } from "../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";


export const SystemTaskConfigurationFormData = {
  initialState: {
    primaryUserId: "",
    secondaryUserId: "",
    moduleId: "",
    taskAction: "",
    actionId: "",
  },
  section: [
    {
      title: "ApiAuthentication Information Section",
      row: {},
      style: {
        sectionStyle: "row mb-3",
      },
      fields: [
        {
          id: "primaryUserId",
          label: "Primary User ",
          Field_Name: "Primary user",
          fieldType: FormFieldTypes.SELECT,
          dataField: "primaryUserId",
          fieldSetting: {
            placeholder: "Select Primary User ",
            isMultiSelect: false,
            options: []
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 col-12 mb-input",
          },
        },
        {
          id: "secondaryUserId",
          label: "Secondary User ",
          Field_Name: "Secondary user",
          fieldType: FormFieldTypes.SELECT,
          dataField: "secondaryUserId",
          fieldSetting: {
            placeholder: "Select Secondary User ",
            isMultiSelect: false,
            options: []
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 col-12 mb-input",
          },
        },
        {
          id: "moduleId",
          label: "Module",
          Field_Name: "Module",
          fieldType: FormFieldTypes.SELECT,
          dataField: "moduleId",
          fieldSetting: {
            placeholder: "Select Module",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2 mt-2",
          },
        },
      ]
    }
  ],
  formSetting: {
    isViewOnly: false
  }

}
export const SystemTaskConfigurationGridConfig = {
  columns: [
    {
      name: "Module",
      fieldName: "moduleName",
      colStyle: {
        width: "15%",
      },
      allowShort: true,
    },
    {
      name: "System Task Name",
      fieldName: "systemTaskName",
      colStyle: {
        width: "25%",
      },
      allowShort: true,
    },
    {

      name: "System Task Title",
      fieldName: "systemTaskTitle",
      colStyle: {
        width: "25%",
      },
      allowShort: true,
    },
    {
      name: "Primary User",
      fieldName: "primaryUserName",
      colStyle: {
        width: "15%",
      },
      allowShort: true,
    },
    {
      name: "Secondary User",
      fieldName: "secondaryUserName",
      colStyle: {
        width: "15%",
      },
      allowShort: true,
    },
    {
      name: "Action",
      colStyle: {
        width: "10%",
      },
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,
        allowDelete: false,
      },
    },
  ],

};