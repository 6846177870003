/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FinalMolGrid from "../../../../../components/FinalMolGrid/FinalMolGrid";
import { customFormsFieldsGridConfig } from "../config/FormFields.data";
import { useGetFormFieldsMutation } from "../../../../../app/services/formFieldsAPI";

const FormFieldsList = ({childRef,handleEditClick,customFormId}) => {

    const molGridRef = useRef();
    const [customFormList, setCustomFormList] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const [getFormFields, { isLoading: isGetFormFieldsLoading, isSuccess: isGetFormFieldsSuccess, data: isGetFormFieldsData },] =  useGetFormFieldsMutation();

    useEffect(() => {
        onGetData()
      }, [])
    
      const getLists = (pageObject, sortingString) => {
        const request = {
          pagination: {
            pageNumber: pageObject.pageNumber,
            pageSize: pageObject.pageSize,
          },
          filters: { searchText: "" },
          sortString: sortingString,
          customFormId:customFormId
        };
        getFormFields(request);
      };
    
      const handlePageChange = (page) => {
        getLists(page, molGridRef.current.generateSortingString());
      };
      const handleSorting = (shortString) => {
        getLists(molGridRef.current.getCurrentPageObject(), shortString);
      };
      const onGetData = () => {
        if (molGridRef.current) {
          const defaultPageObject = molGridRef.current.getCurrentPageObject();
          getLists(defaultPageObject, molGridRef.current.generateSortingString());
        }
      };
    
      useEffect(() => {
        if (isGetFormFieldsSuccess && isGetFormFieldsData) {
          if (isGetFormFieldsData) {
            setCustomFormList(isGetFormFieldsData.dataSource);
          }
          if (isGetFormFieldsData.totalRecord) {
            setTotalRowCount(isGetFormFieldsData.totalRecord);
          }
        }
      }, [isGetFormFieldsSuccess, isGetFormFieldsData]);

      useImperativeHandle(childRef, () => ({
        callChildFunction: onGetData,
      }));
      const actionHandler = {
        EDIT: handleEditClick,
       
       };
    return (<div className="row">
        <div className="col-md-12 table-striped api-provider">
            <FinalMolGrid
                ref={molGridRef}
                configuration={customFormsFieldsGridConfig}
                dataSource={customFormList}
                allowPagination={true}
                pagination={{
                    totalCount: totalRowCount,
                    pageSize: 25,
                    currentPage: 1,
                }}
                onPageChange={handlePageChange}
                onSorting={handleSorting}
                isLoading={isGetFormFieldsLoading}
                onActionChange={actionHandler}
            />
        </div>
    </div>)
}
export default FormFieldsList;