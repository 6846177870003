import { GridColumnType } from "../../../../components/FinalMolGrid/libs/data/gridColumnType";

export const SystemTaskOrderItems = {
  columns: [
    {
      name: "Chemical Name",
      fieldName: "chemicalName",
      allowShort: false,
      colStyle: {
        width: "28%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        const orderbadges = rowData?.["orderItemBadgeList"]?.length > 0;
        return <div className={`${orderbadges ? "chip-red" : ""}`}>{rowData?.["chemicalName"]}</div>
      }
    },
    {
      name: "Catalog",
      fieldName: "catalogId",
      allowShort: false,
      colStyle: {
        width: "15%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        const orderbadges = rowData?.["orderItemBadgeList"]?.length > 0;
        return <div className={`${orderbadges ? "chip-red" : ""}`}>{rowData?.["catalogId"]}</div>
      }
    },
    {
      name: "Cas Number",
      fieldName: "casNumber",
      allowShort: false,
      colStyle: {
        width: "19%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        const orderbadges = rowData?.["orderItemBadgeList"]?.length > 0;
        return <div className={`${orderbadges ? "chip-red" : ""}`}>{rowData?.["casNumber"]}</div>
      }
    },
    {
      name: "Pack Size",
      fieldName: "packSize",
      allowShort: false,
      colStyle: {
        width: "18%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        const orderbadges = rowData?.["orderItemBadgeList"]?.length > 0;
        return <div className={`${orderbadges ? "chip-red" : ""}`}>
          {rowData?.["quantity"]} X {rowData?.["packSize"]} {rowData?.["unit"]}
        </div>
      },
    },
    {
      name: "Unit Price",
      fieldName: "itemUnitPrice",
      allowShort: false,
      colType: GridColumnType.CUSTOM,
      colStyle: {
        width: "18%",
      },
      renderCustomCol: (rowData) => {
        const orderbadges = rowData?.["orderItemBadgeList"]?.length > 0;
        const priceValue = parseFloat(rowData?.["itemUnitPrice"]) || 0;
        return <div className={`${orderbadges ? "chip-red" : ""}`}>
          {`$ ${priceValue?.toFixed(2)}`}
        </div>
      },
    },

  ]
}