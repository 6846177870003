import React from "react";
import PropTypes from "prop-types";
import { AddOrderContextProvider } from "../../../utils/Order/AddOrderContext";

//** Compoent's */
const OrderPOUpload = React.lazy(() => import("../undefinedOrder/features/orderPOUpload/OrderPOUpload"));

const AddOrderTabs = ({ draftOrderId, emailIntentDocData, isDraftEntryPage = false, draftOrderDetails, closeOrderEntryPopup }) => {
    return (
        <AddOrderContextProvider>
            <OrderPOUpload closeOrderEntryPopup={closeOrderEntryPopup} emailIntentDocData={emailIntentDocData}
                isDraftEntryPage={isDraftEntryPage} draftOrderDetails={draftOrderDetails} draftOrderId={draftOrderId} />
        </AddOrderContextProvider>
    );
};

AddOrderTabs.propTypes = {
    draftOrderId: PropTypes.number,
    isDraftEntryPage: PropTypes.bool,
    draftOrderDetails: PropTypes.object,
    closeOrderEntryPopup: PropTypes.func,
    emailIntentDocData: PropTypes.object,
};

export default AddOrderTabs;
