/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import CustomerNotApproved from "./CustomerNotApproved";
import PaymentTermMismatch from "./PaymentTerm";
import PoNumberWrong from "./PoNumberWrong";
import CreditLimitCrossed from "./CreditLimitCrossed";
import BillingAddressMismatch from "./BillingAddress";
import InvoiceEmailMismatch from "./InvoiceEmail";
import IncotermMismatch from "./Incoterms";
import PastDue from "./PastDue";
import LeadTime from "./LeadTime";
import Replacement from "./Replacement";
import StockVarification from "./StockVarification";
import PriceMismatch from "./Price";
 

const SystemBadges = ({ systemTaskDetails, customerDetails, orderDetails, paymentTermDetail, customerId, orderItemDetail }) => {

    const getBadges = (id) => {
        switch (id) {
            case 1:
                return <CustomerNotApproved />;
            case 2:
                return <PaymentTermMismatch orderDetails={orderDetails} paymentTermDetail={paymentTermDetail} />;
            case 3:
                return <PoNumberWrong />;
            case 4:
                return <CreditLimitCrossed paymentTermDetail={paymentTermDetail} orderItemDetail={orderItemDetail} />;
            case 5:
                return <BillingAddressMismatch customerId={customerId} orderDetails={orderDetails} />;
            case 6:
                return <InvoiceEmailMismatch customerId={customerId} orderDetails={orderDetails} />;
            case 7:
                return <IncotermMismatch customerDetails={customerDetails} orderDetails={orderDetails} />;
            case 8:
                return <PastDue />;
            case 9:
                return <Replacement orderItemDetail={orderItemDetail} />;
            case 10:
                return <LeadTime orderItemDetail={orderItemDetail} />;
            case 11:
                return <StockVarification orderItemDetail={orderItemDetail} />;
            case 12:
                return <PriceMismatch orderItemDetail={orderItemDetail} />;
            default:
                return <CustomerNotApproved orderItemDetail={orderItemDetail} />;
        }

    }

    return (
        <>
            {/* <BadgesDetails badgeStatus={systemTaskDetails?.badgeStatus} subStatus={systemTaskDetails?.subStatus} /> */}
            {getBadges(systemTaskDetails?.orderSubStatusId)}
        </>
    )
}

SystemBadges.propTypes = {
    systemTaskDetails: PropTypes.shape({
        badgeStatus: PropTypes.string,
        subStatus: PropTypes.string,
        orderSubStatusId: PropTypes.number
    }),
    customerDetails: PropTypes.object,
    orderDetails: PropTypes.object,
    paymentTermDetail: PropTypes.object,
    customerId: PropTypes.string,
    orderItemDetail: PropTypes.array
};

export default SystemBadges;