/* eslint-disable react-hooks/exhaustive-deps */
import RenderTabs from "../../../components/ui/tabs/RenderTabs";
import CustomFormButtons from "./CustomFormButtons/CustomFormButtons";
import CustomFormFields from "./CustomFormFields/CustomFormFields";

const CustomFormsTabs = ({ customFormId }) => {

  const tabs = [
    {
      sMenuItemCaption: "Form Fields",
      icon: "fa fa-address-book-o",
      component: (
        <div className="mt-2 contact-accrodiaon-scroll">
          <CustomFormFields customFormId={customFormId} />
        </div>
      ),

    }, {
      sMenuItemCaption: "Form Buttons",
      icon: "fa fa-address-book-o",
      component: (
        <div className="mt-2 contact-accrodiaon-scroll">
          <CustomFormButtons customFormId={customFormId} />
        </div>
      ),

    },

  ];
  return (<RenderTabs tabs={tabs} />)
}
export default CustomFormsTabs;