import Iconify from "../../../../components/ui/iconify/Iconify";

const SystemTaskConfigurationInfoCard=({formData,editClick})=>{

   
    return( 
    <div className="basic-customer-detail">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="d-flex gap-5 profile-info  justify-content-between col-12">
          <div className="col-3">
              <div className="field-desc">
                <div className="inf-label fw-bold">System Task Name</div>
                <b>&nbsp;:&nbsp;</b>
                <div className="info-desc">
                  {formData?.systemTaskName}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="field-desc">
                <div className="inf-label fw-bold">Primary User </div>
                <b>&nbsp;:&nbsp;</b>
                <div className="info-desc">
                    {formData?.primaryUserName}
                    </div>
              </div>
            </div>
            <div className="col-3">
              <div className="field-desc">
                <div className="inf-label fw-bold">Secondary User</div>
                <b>&nbsp;&nbsp;&nbsp;:&nbsp;</b>
                <div className="info-desc">
                  {formData?.secondaryUserName}
                </div>
              </div>
            </div>
            <div className="col-3 ">
              <div className="field-desc">
                <div className="inf-label fw-bold">Module </div>
                <b>&nbsp;:&nbsp;</b>
                <div className="info-desc">
                    {formData?.moduleName}
                    </div>
              </div>
            </div>
          </div>
          <button className="edit-icons" style={{ border: 'unset' }}
           onClick={editClick}
           >
            {/* <Image imagePath={AppIcons.editThemeIcon} altText="Website Icon" /> */}
            <Iconify icon="tabler:pencil" />
          </button>
        </div>
      </div>)
}
export default SystemTaskConfigurationInfoCard;