import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";
import PropTypes from "prop-types";

const OtherEmails=()=>{
    return( <EmailManagement emailIntentId={EmailInboxTypesEnum.OTHERS} intentName="Other Emails" />)
}
EmailManagement.propTypes = {
    emailIntentId: PropTypes.oneOf(Object.values(EmailInboxTypesEnum)).isRequired, 
    intentName: PropTypes.string.isRequired, 
  };
export default OtherEmails;