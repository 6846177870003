/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";
// import Breadcome from "../components/ui/breadcome/Breadcome";
import Footer from "./components/footer/Footer";
import PropTypes from "prop-types";
// import { hasPermission } from "../utils/AuthorizeNavigation/authorizeNavigation";
// import Unauthorize from "../pages/unauthorize/Unauthorize";
import Iconify from "../components/ui/iconify/Iconify";

const Layout = (props) => {
  // Get the current location using React Router's useLocation hook
  // const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Extract the last segment of the pathname as routhPath
  // let routhPath = location.pathname.replace("/", "");
  // const segments = location.pathname.split("/");
  // if (location.pathname.includes("configuration")) {
  //   // Use the full pathname if "configuration" is present
  //   routhPath = segments[2];
  // } else if (segments.length > 1) {
  //   // Otherwise, use the second segment
  //   routhPath = segments[1];
  // }

  // Find the matching component route based on the routhPath
  // const renderComponent = props.componentRoutes?.find(
  //   (x) => x.id.toLowerCase() === routhPath.toLowerCase()
  // );

  // Check if the user is authorized to access the current route
  // const isAuthorize = renderComponent?.securityKey
  //   ? hasPermission(renderComponent.securityKey)
  //   : false;

  // Handler to toggle the collapse state
  const handleToggleClick = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <React.Fragment>
      <div className={`main-page-layout ${isCollapsed ? "collapsed" : ""}`}>
        <div className="top-sec">
          <div className="sidebar-section">
            <Sidebar componentRoutes={props.componentRoutes} />
            <div className="collapse-btn">
              <div className="click-btn" onClick={handleToggleClick}>
                {/* <Image imagePath={AppIcons.arrowIcon} /> */}
                <Iconify icon="solar:alt-arrow-down-outline" />
              </div>
            </div>
          </div>

          <div className={`middle-page-section`}>
            <Header />

            {/* {isAuthorize ? */}
            <div className="center-content-part">
              <div className="content-desc-section">
                {/* <Breadcome componentRoutes={props.componentRoutes} /> */}
                <div className="center-container container-fluid">
                  <Outlet />
                </div>
                <Footer />
              </div>
            </div>
            {/* : <Unauthorize />
              } */}
          </div>
        </div>
        <div className="tab-bg"></div>

      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  componentRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      securityKey: PropTypes.string,
    })
  ),
};

export default Layout;
