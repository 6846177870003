import { validationTypes } from "../../../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../../../data/gridColumnType";

export const addEditWorkflowActionFormData = {
    initialState: {
        actionName:"",
        executionType:"",
        actionType:"",
        parameters_JSON:"",
        parentStepID:"",
        nextStepID:"",
        instructions:"",
        customFormId:""

    },
    section: [
        {
            title: "Workflow Action Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "actionName",
                    label: "Action Name ",
                    Field_Name: "Action name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "actionName",
                    fieldSetting: {
                        placeholder: "Enter Action Name",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                    id: "executionType",
                    label: "Execution Type ",
                    Field_Name: "Execution type",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "executionType",
                    fieldSetting: {
                        placeholder: "Select Execution Type",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                    id: "actionType",
                    label: "Action Type ",
                    Field_Name: "Action type",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "actionType",
                    fieldSetting: {
                        placeholder: "Select Action Type",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                    id: "customFormId",
                    label: "Custom form ",
                    Field_Name: "Custom form",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "customFormId",
                    fieldSetting: {
                        placeholder: "Select Custom form",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
               
                {
                    id: "instructions",
                    label: "Instructions ",
                    Field_Name: "Instructions",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "instructions",
                    fieldSetting: {
                        placeholder: "Enter Instructions",
                        allowSpace: true,
                    },
                    // validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                    id: "parentStepID",
                    label: "Parent Step Id ",
                    Field_Name: "Parent step id",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "parentStepID",
                    fieldSetting: {
                      placeholder: "Select Parent Step Id",
                      isEnableOnChange: true,
                      isMultiSelect: false,
                      isDisable:true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                  },
                  {
                    id: "nextStepID",
                    label: "Next Step Id ",
                    Field_Name: "Next step Id",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "nextStepID",
                    fieldSetting: {
                      placeholder: "Select Next Step Id",
                      isEnableOnChange: true,
                      isMultiSelect: false,
                    },
                   
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                  },
                  {
                    id: "parameters_JSON",
                    label: "Parameter JSON ",
                    Field_Name: "Parameter JSON",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "parameters_JSON",
                    fieldSetting: {
                        placeholder: "Enter Parameter JSON",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.VALIDATEJSON }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }

}

export const WorkflowActionsGridConfig = {
    columns: [

        {
            name: "Action Name",
            fieldName: "actionName",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Action Type",
            fieldName: "actionType",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
      
        {
            name: "Execution Type",
            fieldName: "executionType",
            colStyle: {
                width: "15%",
            },
            allowShort: true,
        },
        // {
        //     name: "Parameters JSON",
        //     fieldName: "parameters_JSON",
        //     colStyle: {
        //         width: "25%",
        //     },
        //     allowShort: true,
        // },
        {
            name: "Created Date",
            fieldName: "createdAt",
            colType: GridColumnType.DATE,
            allowShort: true,
            colSettings: {
              format: "MM/DD/YYYY",
            },
            colStyle: {
                width: "20%",
            },
          },

        {
            name: "Action",
            colStyle: {
                width: "15%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: false,
            },
           
        },
    ],

};