/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ToastService from "../../../../../../../../services/toastService/ToastService";
import Buttons from "../../../../../../../../components/ui/button/Buttons";
import FormCreator from "../../../../../../../../components/FinalForms/FormCreator";
import { onResetForm } from "../../../../../../../../utils/FormFields/ResetForm/handleResetForm";
import { AddEditRequireParameterData } from "../config/AddEventRequiredFields.data";
import {
  useAddEditApiEventResponseFieldMutation,
  useLazyGetApiEventResponseFieldByApiEventResponseFieldIdQuery,
} from "../../../../../../../../app/services/thirdPartyAPI";
import { ApiParametersDataTypes } from "../../../../../../../../utils/Enums/commonEnums";
import { getFieldData } from "../../../../../../../../utils/FormFields/FieldsSetting/SetFieldSetting";

const AddEditRequiredFields = (props) => {
  const addEditRequireRef = useRef();
  const [addEditRequireData, setAddEditRequireData] = useState(
    AddEditRequireParameterData
  );
  const [
    addEditApiEventResponseField,
    {
      isLoading: isAddEditApiEventResponseFieldLoading,
      isSuccess: isAddEditApiEventResponseFieldSuccess,
      data: allAddEditApiEventResponseFieldData,
    },
  ] = useAddEditApiEventResponseFieldMutation();
  const [
    getApiEventResponseFieldByApiEventResponseFieldId,
    {
      isFetching: isGetApiEventResponseFieldByApiEventResponseFieldIdFetching,
      isSuccess: isGetApiEventResponseFieldByApiEventResponseFieldIdSucess,
      data: allGetApiEventResponseFieldByApiEventResponseFieldIdData,
    },
  ] = useLazyGetApiEventResponseFieldByApiEventResponseFieldIdQuery();

  useEffect(() => {
    if (
      isAddEditApiEventResponseFieldSuccess &&
      allAddEditApiEventResponseFieldData
    ) {
      if (allAddEditApiEventResponseFieldData.errorMessage.includes("exists")) {
        ToastService.warning(allAddEditApiEventResponseFieldData.errorMessage);

        return;
      }
      ToastService.success(allAddEditApiEventResponseFieldData.errorMessage);
      handleResetAndClose();
      props.onGetData();
    }
  }, [
    isAddEditApiEventResponseFieldSuccess,
    allAddEditApiEventResponseFieldData,
  ]);

  const handleAddEditAPIPRovider = () => {
    const formData = addEditRequireRef.current.getFormData();
    if (formData) {
      let request = {
        ...formData,
        fieldName: formData.fieldName,
        apiEventResponseFieldId:
          formData.apiEventResponseFieldId &&
          typeof formData.apiEventResponseFieldId === "object"
            ? formData.apiEventResponseFieldId.value
            : formData.apiEventResponseFieldId,
        fieldDescription: formData.fieldDescription,
        fieldType:
          formData.fieldType && typeof formData.fieldType === "object"
            ? formData.fieldType.value
            : formData.fieldType,
        apiEventId: props.keyId,
      };
      addEditApiEventResponseField(request);
    }
  };

  useEffect(() => {
    onResetForm(AddEditRequireParameterData, setAddEditRequireData, null);
  }, [props.isOpen]);

  const handleResetAndClose = () => {
    onResetForm(AddEditRequireParameterData, setAddEditRequireData, null);
    props.onClose();
  };

  useEffect(() => {
    if (props.getData) {
      getApiEventResponseFieldByApiEventResponseFieldId(
        props.getData.apiEventResponseFieldId
      );
    }
  }, [props.getData]);

  useEffect(() => {
    const dropdownField = getFieldData(
      AddEditRequireParameterData,
      "fieldType"
    );
    dropdownField.fieldSetting.options = Object.entries(
      ApiParametersDataTypes
    ).map(([key, value]) => ({
      label: key,
      value: value,
    }));
  }, []);

  useEffect(() => {
    if (
      !isGetApiEventResponseFieldByApiEventResponseFieldIdFetching &&
      isGetApiEventResponseFieldByApiEventResponseFieldIdSucess &&
      allGetApiEventResponseFieldByApiEventResponseFieldIdData
    ) {
      let setData = { ...addEditRequireData };
      setData.initialState = {
        fieldType:
        allGetApiEventResponseFieldByApiEventResponseFieldIdData.fieldType,
        fieldDescription:
        allGetApiEventResponseFieldByApiEventResponseFieldIdData.fieldDescription,
        fieldName:
        allGetApiEventResponseFieldByApiEventResponseFieldIdData.fieldName,
        apiEventResponseFieldId:
        allGetApiEventResponseFieldByApiEventResponseFieldIdData.apiEventResponseFieldId,
      };
      setAddEditRequireData(setData);
    }
  }, [
    isGetApiEventResponseFieldByApiEventResponseFieldIdFetching,
    isGetApiEventResponseFieldByApiEventResponseFieldIdSucess,
    allGetApiEventResponseFieldByApiEventResponseFieldIdData,
  ]);

  return (
    <div className="row mt-2 add-address-form">
      <div className="col-12">
        <FormCreator config={addEditRequireData} ref={addEditRequireRef} />
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex align-item-end justify-content-end">
          <Buttons
            buttonTypeClassName="theme-button"
            buttonText="Save"
            onClick={handleAddEditAPIPRovider}
            isLoading={isAddEditApiEventResponseFieldLoading}
          />
          <Buttons
            buttonTypeClassName="dark-btn ml-5"
            buttonText="Cancel"
            onClick={handleResetAndClose}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditRequiredFields;
