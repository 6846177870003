/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom"
import Buttons from "../../../components/ui/button/Buttons"
import CardSection from "../../../components/ui/card/CardSection"
import { AppIcons } from "../../../data/appIcons"
import CustomFormsDetailInfoCard from "./CustomFormsDetailInfoCard"
import { decryptUrlData } from "../../../services/CryptoService"
import { useEffect, useState } from "react"
import { useLazyGetCustomFormsByCustomFormIdQuery } from "../../../app/services/customFormsAPI"
import CustomFormsTabs from "./CustomFormsTabs"
import SidebarModel from "../../../components/ui/sidebarModel/SidebarModel"
import AddEditCustomForms from "./AddEditCustomForms"

const CustomFormsDetail = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const customFormId = id ? decryptUrlData(id) : 0;
    const [formData, setFormData] = useState(null);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [getCustomFormsByCustomFormId, { isFetching: isGetCustomFormsByCustomFormIdFetching, isSuccess: isGetCustomFormsByCustomFormIdSuccess, data: GetCustomFormsByCustomFormIdData, },] = useLazyGetCustomFormsByCustomFormIdQuery();

    useEffect(() => {
        if (customFormId) {
            getCustomFormsByCustomFormId(customFormId);
        }
    }, [customFormId]);

    useEffect(() => {
        if (isGetCustomFormsByCustomFormIdSuccess && GetCustomFormsByCustomFormIdData && !isGetCustomFormsByCustomFormIdFetching) {
            if (GetCustomFormsByCustomFormIdData) {

                setFormData(GetCustomFormsByCustomFormIdData);
            }
        }
    }, [isGetCustomFormsByCustomFormIdSuccess, GetCustomFormsByCustomFormIdData, isGetCustomFormsByCustomFormIdFetching]);

    const handleBackClick = () => {
        navigate('/configuration/customForms')
    }

    const onSidebarClose = () => {
        setIsModelOpen(false)
    }
    const handleToggleModal = () => {
        setIsModelOpen(true)
    }
    return (
        <>
            <div className="card-bottom-m-0">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 basic-left-part customer-desc-left-sec mb-2 mt-2">
                        <CardSection>
                            <CustomFormsDetailInfoCard
                                editClick={handleToggleModal}
                                formData={formData}
                           
                            />
                        </CardSection>
                    </div>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 other-info-tab">
                        <Buttons
                            buttonTypeClassName="back-button btn dark-btn"
                            onClick={handleBackClick}
                            textWithIcon={true}
                            buttonText="Back"
                            imagePath={AppIcons.BackArrowIcon}
                        ></Buttons>
                        <div className="customer-detail-tab-sec">
                            <CustomFormsTabs customFormId={customFormId} />
                        </div>
                    </div>
                </div>
            </div>
            <SidebarModel
                modalTitle={"Update Custom Form"}
                contentClass="content-35"
                onClose={onSidebarClose}
                modalTitleIcon={AppIcons.AddIcon}
                isOpen={isModelOpen}
            >
                <AddEditCustomForms
                    isEdit={true}
                 isGetdetails={true}
                 getCustomFormsByCustomFormId={getCustomFormsByCustomFormId}
                    customFormId={customFormId}
                    isOpen={isModelOpen}
                    onClose={onSidebarClose}
                />
            </SidebarModel>

        </>)
}
export default CustomFormsDetail;