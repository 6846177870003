import React, { useId } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

/**
 * Tooltip component that wraps children and displays a tooltip when hovered over.
 * It uses the `react-tooltip` library for the tooltip functionality.
 * 
 * @param {string} label - The text content to display inside the tooltip.
 * @param {string} [position='top'] - The placement of the tooltip. Accepted values: 'top', 'bottom', 'left', 'right'.
 * @param {string} [className=''] - Additional custom CSS class for styling the tooltip.
 * @param {ReactNode} children - The child element(s) that trigger the tooltip when hovered.
 * @param {string} [variant='dark'] - The variant (style) of the tooltip. Accepted values: 'dark', 'light'.
 * @param {number} [opacity=1] - The opacity level of the tooltip. Values range from 0 to 1.
 * @param {number} [delayShow=0] - The delay in milliseconds before the tooltip shows up after hovering.
 * @param {boolean} [isOpen=false] - A boolean to control the visibility of the tooltip.
 * 
 * @returns {JSX.Element} The Tooltip component.
 */
const Tooltip = ({
  label,
  position = "top",
  className = "",
  children,
  variant = "dark",
  opacity = 1,
  delayShow = 0,
  isOpen,
}) => {
  // Generate a unique ID for each Tooltip instance
  const uniqueId = useId();

  return (
    <>
      {/* Tooltip trigger (children element) */}
      <div
        data-tooltip-id={uniqueId}  // Associate this element with the tooltip using the unique ID
        data-tooltip-content={label}  // Content to be displayed in the tooltip
        data-tooltip-place={position}  // Position of the tooltip relative to the trigger element
        style={{
          display: "inline-block",  // Ensure the trigger element is inline-block for correct positioning
          width: "fit-content",     // Make sure the width fits the content of the children
        }}
      >
        {children}
      </div>

      {/* ReactTooltip component that displays the actual tooltip */}
      <ReactTooltip
        id={uniqueId}       // Associate the tooltip with the unique ID
        place={position}       // Define where the tooltip appears relative to the trigger
        className={className} // Custom class for styling the tooltip
        variant={variant}   // Define the color scheme for the tooltip (dark or light)
        opacity={opacity}   // Set the opacity of the tooltip
        delayShow={delayShow} // Set the delay before showing the tooltip
        isOpen={isOpen}     // Control the visibility of the tooltip
      />
    </>
  );
};

export default Tooltip;
