import { useRef, useState } from "react";
import CardSection from "../../components/ui/card/CardSection";
import SidebarModel from "../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../data/appIcons";
import AddEditCustomForms from "./feature/AddEditCustomForms";
import CustomFormsList from "./feature/CustomFormsList";
import { AddEditCustomFormsFormData } from "./config/CustomForms.data";
import { onResetForm } from "../../utils/FormFields/ResetForm/handleResetForm";


const CustomForms=()=>{
    const [isEdit, setIsEdit] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [customFormId,setCustomFormId]=useState(null)
  const [, setFormData] = useState(AddEditCustomFormsFormData);
  const childRef = useRef();

  const handleToggleModal=()=>{
    setIsModelOpen(true)
  }

  const onSidebarClose=()=>{
    onResetForm(AddEditCustomFormsFormData, setFormData, null);
    setIsModelOpen(false);
    setCustomFormId(null)
    setIsEdit(false)
  }

  const handleEditClick=(data)=>{
    setIsModelOpen(true)
    setCustomFormId(data.customFormId)
    setIsEdit(true)
  }

  const onGetData = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
  };
  
    return(
        <div className="custom-forms">
        <CardSection
          cardTitle="Custom Forms"
          buttonClassName="btn theme-button"
          rightButton={true}
          textWithIcon={true}
          iconImg={AppIcons.PlusIcon}
         titleButtonClick={handleToggleModal}
   
        >
          <CustomFormsList
            handleEditClick={handleEditClick} childRef={childRef}
          />
        </CardSection>
  
        <SidebarModel
          modalTitle={isEdit ? "Update Custom Form" : "Add Custom Form"}
          contentClass="content-35"
          onClose={onSidebarClose}
          modalTitleIcon={AppIcons.AddIcon}
          isOpen={isModelOpen}
        >
          <AddEditCustomForms
             isEdit={isEdit}
             isGetdetails={false}
             onGetData={onGetData}
             customFormId={customFormId}
            isOpen={isModelOpen}
             onClose={onSidebarClose}
          />
        </SidebarModel>
      </div>
    )

}
export default CustomForms;