import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";
import PropTypes from "prop-types";

const NewCompanySetupEmails=()=>{
    return(  <EmailManagement emailIntentId={EmailInboxTypesEnum.NEWCOMPANYSETUP} intentName="New Company Setup Emails"/>)
}
EmailManagement.propTypes = {
    emailIntentId: PropTypes.oneOf(Object.values(EmailInboxTypesEnum)).isRequired, 
    intentName: PropTypes.string.isRequired, 
  };
export default NewCompanySetupEmails;
