export const securityKey = {

     //** Dashboard */
     DASHBOARD: "Dashboard",

     // My Task 
     MYTASK: "My Task",

     // Order
     ORDER: "Order",
     ADDORDER: "Add Order",
     ORDERLIST: "Order List",

     //** User Management */
     USERMANAGEMENT: "User Management",
     ADDUSER: "Add User",
     EDITUSER: "Edit User",
     DELETEUSER: "Delete User",
     USERLOGINHISTORY: "User Login History",

     SECURITY: "Security",

     //** Security Role Management */
     SECURITYROLEMANAGEMENT: "Security Role Management",
     ADDSECURITYROLE: "Add Security Role",
     EDITSECURITYROLE: "Edit Security Role",
     DELETESECURITYROLE: "Delete Security Role",

     //** Assing User */
     ASSIGNUSERS: "Assign Users",
     ADDASSIGNUSERS: "Add Assign Users",
     DELETEASSIGNUSERS: "Delete Assign Users",


     //** Customer */
     CUSTOMER: "Customer",
     ADDCUSTOMER: "Add Customer",
     CUSTOMERLIST: "Customer List",
     //** EDITCUSTOMER */
     EDITCUSTOMER: "Edit Customer",
     BLOCKCUSTOMER: "Block Customer",
     UNBLOCKCUSTOMER: "UnBlock Customer",
     DISABLECUSTOMER: "Disable Customer",
     FREEZECUSTOMER: "Freeze Customer",
     UNFREEZECUSTOMER: "UnFreeze Customer",
     ACTIVECUSTOMER: "Active Customer",
     REJECTCUSTOMER: "Reject Customer",
     EDITBASICCUSTOMERDETAILS: "Edit Basic Customer Details",
     //** Customer Address */
     CUSTOMERADDRESS: "Customer Address",
     ADDCUSTOMERADDRESS: "Add Customer Address",
     EDITCUSTOMERADDRESS: "Edit Customer Address",
     //** Customer Contact */
     CUSTOMERCONTACT: "Customer Contact",
     ADDCUSTOMERCONTACT: "Add Customer Contact",
     EDITCUSTOMERCONTACT: "Edit Customer Contact",
     //** Customer Document */
     CUSTOMERDOCUMENT: "Customer Document",
     ADDCUSTOMERDOCUMENT: "Add Customer Document",
     DELETECUSTOMERDOCUMENT: "Delete Customer Document",
     DOWNALODCUSTOMERDOCUMENT: "Downalod Customer Document",
     //** Customer Setting */
     CUSTOMERSETTING: "Customer Setting",
     CUSTOMERFINANCIALSETTING: "Customer Financial Setting",
     ADDEDITCUSTOMERFINANCIAL: "AddEdit Customer Financial",
     CUSTOMERSHIPPINGSETTING: "Customer Shipping Setting",
     ADDEDITCUSTOMERSHIPPINGSETTING: "AddEdit Customer Shipping Setting",
     //** Customer Note */
     CUSTOMERNOTES: "Customer Notes",
     ADDCUSTOMERNOTE: "Add Customer Notes",
     EDITCUSTOMERNOTE: "Edit Customer Notes",
     //** Customer History */
     CUSTOMERHISTORY: "Customer History",

     LINKSUBCUSTOMER: "Link Sub-Customer",


     //** SUPPLIER */
     SUPPLIER: "Supplier",
     ADDSUPPLIER: "Add Supplier",
     SUPPLIERLIST: "Supplier List",
     //** EDITSUPPLIER */
     EDITSUPPLIER: "Edit Supplier",
     BLOCKSUPPLIER: "Block Supplier",
     UNBLOCKSUPPLIER: "UnBlock Supplier",
     DISABLESUPPLIER: "Disable Supplier",
     FREEZESUPPLIER: "Freeze Supplier",
     UNFREEZESUPPLIER: "UnFreeze Supplier",
     ACTIVESUPPLIER: "Active Supplier",
     REJECTSUPPLIER: "Reject Supplier",
     EDITBASICSUPPLIERDETAILS: "Edit Basic Supplier Details",
     //** Supplier Address */
     SUPPLIERADDRESS: "Supplier Address",
     ADDSUPPLIERADDRESS: "Add Supplier Address",
     EDITSUPPLIERADDRESS: "Edit Supplier Address",
     //** Supplier Contact */
     SUPPLIERCONTACT: "Supplier Contact",
     ADDSUPPLIERCONTACT: "Add Supplier Contact",
     EDITSUPPLIERCONTACT: "Edit Supplier Contact",
     //** Supplier Document */
     SUPPLIERDOCUMENT: "Supplier Document",
     ADDSUPPLIERDOCUMENT: "Add Supplier Document",
     DELETESUPPLIERDOCUMENT: "Delete Supplier Document",
     DOWNALODSUPPLIERDOCUMENT: "Downalod Supplier Document",
     //** Supplier Note */
     SUPPLIERNOTES: "Supplier Notes",
     ADDSUPPLIERNOTE: "Add Supplier Notes",
     EDITSUPPLIERNOTE: "Edit Supplier Notes",
     //** Supplier History */
     SUPPLIERHISTORY: "Supplier History",


     //** Api Configration */
     APICONFIGURATION: "API Configuration",

     APIPROVIDERS: "API Providers",
     APIENDPOINTS: "API EndPoints",
     APIPARAMETERS: "API Parameters",
     APIAUTHENTICATION: "API Authentication",


     //** Wating for the generate keys */
     //  ADDCUSTOMERSETTING: "Add Customer Setting",
     //  EDITCUSTOMERSETTING: "Edit Customer Setting",

     //** Permissions Management */ -- Pending 
     PERMISSIONMANAGEMENT: "Permissions Management",
     SAVEPERMISSIONS: "Save Permission",

     CONFIGURATION: "Configuration",
     APPROVALRULES: "Approval Rules",
     FUNCTIONALCONFIGURATION: "Functional Configuration",
     THIRDPARTYAPI: "Third Party Api",

     ORGANIZATION: "Organization",
     DICTIONARY: "Dictionary",

     CONFIGURATIONMANAGEMENT:"Configuration Management",


     //Order 
    EDITORDER:"Edit Order",
    DELETEORDER:"Delete Order",

    SHOWORDERTIMELINE:"Show Order Timeline",
    SHOWORDERCOMMENTS:"Show Order Comments",
    CUSTOMERCOMMUNICATION:"Customer Communication",

    EDITBASICORDERDETAILS:"Edit Basic Order Details",
    //Order Address
    ORDERADDRESS:"Order Address",
    ADDORDERBILLINGADDRESS:"Add Order Billing Address",
    CHANGEORDERBILLINGADDRESS:"Change Order Billing Address",
    ADDORDERSHIPPINGADDRESS:"Add Order Shipping Address",
    CHANGEORDERSHIPPINGADDRESS:"Change Order Shipping Address",
    //Order Contact
    ORDERCONTACT:"Order Contact",
    ADDORDERENDUSERCONTACT:"Add Order End User Contact",
    CHANGEORDERENDUSERCONTACT:"Change Order End User Contact",
    ADDORDERINVOICESUBMISSIONCONTACT:"Add Order Invoice Submission Contact",
    CHANGEORDERINVOICESUBMISSIONCONTACT:"Change Order Invoice Submission Contact",
    ADDORDERPURCHASINGCONTACT:"Add Order Purchasing Contact",
    CHANGEORDERPURCHASINGCONTACT:"Change Order Purchasing Contact",
    //Order document
    ORDERDOCUMENT:"Order Document",
    ADDORDERDOCUMENT:"Add Order Document",
    VIEWORDERDOCUMENT:"View Order Document",
    DELETEORDERDOCUMENT:"Delete Order Document",
    //Order Charges
    ADDORDERCHARGES :"Add Order Charges",
    EDITORDERCHARGES :"Edit Order Charges",
    DELETEORDERCHARGES :"Delete Order Charges",
    //Order Items
    ORDERITEM :"Order Item",
    ADDORDERITEM :"Add Order Item",
    EDITORDERITEM :"Edit Order Item",
    DELETEORDERITEM :"Delete Order Item",
    
    SYSTEMTASKCONFIGURATION:"System Task Configuration",
    CUSTOMFORMS:"Custom Forms",

    RULES:"Rules",
    PROVIDERS:"Providers",
 
    SYSTEMEVENT:"System Event",
    EMAIL:"Email",
    EMAILTEMPLATE:"Email Template",
    SNIPPETS:"Snippets",
    EMAILACTION:"Email Action",

    SYSTEMCONSTRAINTS:"System Constraints",
    ORDERBADGES:"Order Badges"




};