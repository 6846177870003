import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLazyGetContactByCustomerIdQuery } from "../../../../app/services/orderAPI";
import { ContactType } from "../../../../utils/Enums/commonEnums";
import './InvoiceEmail.scss';

const InvoiceEmailMismatch = ({ orderDetails, customerId }) => {
    const [getContactByCustomerId, { isFetching: isGetContactByCustomerIdFetching, isSuccess: isGetContactByCustomerIdSuccess, data: isGetContactByCustomerIdData },] = useLazyGetContactByCustomerIdQuery();
    const [invoiceContactDetail, setInvoiceContactDetail] = useState(null)

    useEffect(() => {
        if (customerId) {
            const req = {
                customerId: customerId,
                searchText: "",
                searchContactType: ContactType.INVOICESUBMISSION,
            }
            getContactByCustomerId(req);
        }
    }, [customerId])

    useEffect(() => {
        if (!isGetContactByCustomerIdFetching && isGetContactByCustomerIdSuccess && isGetContactByCustomerIdData) {
            const filteredData = isGetContactByCustomerIdData.find(
                (contact) => contact.type === "Invoice Submission" && contact.isPrimary === true);
            setInvoiceContactDetail(filteredData)
        }
    }, [isGetContactByCustomerIdFetching, isGetContactByCustomerIdSuccess, isGetContactByCustomerIdData]);

    const isMismatch = invoiceContactDetail && orderDetails?.orderContactList?.length > 0 &&
        (invoiceContactDetail.emailAddressList[0]?.emailAddress !== orderDetails.orderContactList[0].emailAddressList[0]?.emailAddress ||
            invoiceContactDetail.phoneNumberList[0]?.phoneNumber !== orderDetails.orderContactList[0].phoneNumberList[0]?.phoneNumber);

    return (
        <div className="invoice-email-container">
            <div className="comparison-header">
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:account-outline"></i>
                    <span>Customer</span>
                </div>
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:file-document-outline"></i>
                    <span>Order</span>
                </div>
            </div>
            <div className="comparison-content">
                <div className={`contact-box ${isMismatch ? 'mismatch' : ''}`}>
                    {invoiceContactDetail ? (
                        <div className="contact-details">
                            <div className="contact-name">
                                {`${invoiceContactDetail.firstName} ${invoiceContactDetail.lastName}`}
                            </div>
                            <div className="contact-email">
                                {invoiceContactDetail.emailAddressList[0]?.emailAddress || 'N/A'}
                            </div>
                            <div className="contact-phone">
                                {invoiceContactDetail.phoneNumberList[0]?.phoneNumber ? 
                                    `${invoiceContactDetail.phoneNumberList[0].phoneCode}${invoiceContactDetail.phoneNumberList[0].phoneNumber}` : 
                                    'N/A'}
                            </div>
                        </div>
                    ) : (
                        <div className="no-data">N/A</div>
                    )}
                </div>
                <div className={`contact-box ${isMismatch ? 'mismatch' : ''}`}>
                    {orderDetails?.orderContactList?.length > 0 ? (
                        <div className="contact-details">
                            <div className="contact-name">
                                {`${orderDetails.orderContactList[0].firstName} ${orderDetails.orderContactList[0].lastName}`}
                            </div>
                            <div className="contact-email">
                                {orderDetails.orderContactList[0].emailAddressList[0]?.emailAddress || 'N/A'}
                            </div>
                            <div className="contact-phone">
                                {orderDetails.orderContactList[0].phoneNumberList[0]?.phoneNumber ? 
                                    `${orderDetails.orderContactList[0].phoneNumberList[0].phoneCode}${orderDetails.orderContactList[0].phoneNumberList[0].phoneNumber}` : 
                                    'N/A'}
                            </div>
                        </div>
                    ) : (
                        <div className="no-data">N/A</div>
                    )}
                </div>
            </div>
        </div>
    );
};

InvoiceEmailMismatch.propTypes = {
    customerId: PropTypes.string,
    orderDetails: PropTypes.shape({
        orderContactList: PropTypes.arrayOf(PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            emailAddressList: PropTypes.arrayOf(PropTypes.shape({
                emailAddress: PropTypes.string
            })),
            phoneNumberList: PropTypes.arrayOf(PropTypes.shape({
                phoneNumber: PropTypes.string,
                phoneCode: PropTypes.string
            }))
        }))
    })
};

export default InvoiceEmailMismatch;