/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import CardSection from "../../../../components/ui/card/CardSection";
import SidebarModel from "../../../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../../../data/appIcons";
import AddEditFormFields from "./feature/AddEditFormFields";
import FormFieldsList from "./feature/FormFieldsList";
import { AddEditCustomFormFieldsFormData } from "./config/FormFields.data";
import { onResetForm } from "../../../../utils/FormFields/ResetForm/handleResetForm";


const CustomFormFields = ({ customFormId }) => {

  const [isModelOpen, setIsModelOpen] = useState(false)
  const [formFieldId, setFormFieldId] = useState(null)
  const [isEdit, setIsEdit] = useState(false);
  const [, setFormData] = useState(AddEditCustomFormFieldsFormData);
  const childRef = useRef();
  const handleToggleModal = () => {
    setIsModelOpen(true)
    onResetForm(AddEditCustomFormFieldsFormData, setFormData, null);
  }
  const onSidebarClose = () => {
    onResetForm(AddEditCustomFormFieldsFormData, setFormData, null);
    setIsModelOpen(false)
    setFormFieldId(null)
    setIsEdit(false)
  }
  const handleEditClick = (data) => {
    setIsModelOpen(true)
    setFormFieldId(data.formFieldId)
    setIsEdit(true)
  }


  const onGetData = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
  };
  return (
    <div className="mt-3">
      <CardSection
        cardTitle="Custom Form Fields"
        buttonClassName="btn theme-button"
        rightButton={true}
        textWithIcon={true}
        iconImg={AppIcons.PlusIcon}
        titleButtonClick={handleToggleModal}
      >
        <FormFieldsList
          handleEditClick={handleEditClick} childRef={childRef} customFormId={customFormId}
        />
      </CardSection>

      <SidebarModel
        modalTitle={isEdit ? "Update Form Fields" : "Add Form Fields"}
        contentClass="content-45"
        onClose={onSidebarClose}
        modalTitleIcon={AppIcons.AddIcon}
        isOpen={isModelOpen}
      >
        <AddEditFormFields
          isEdit={isEdit}
          onGetData={onGetData}
          customFormId={customFormId}
          formFieldId={formFieldId}
          isOpen={isModelOpen}
          onClose={onSidebarClose}
        />
      </SidebarModel>
    </div>)
}
export default CustomFormFields;