import { validationTypes } from "../../../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../../../data/gridColumnType";

export const addEditWorkflowStepsFormData = {
    initialState: {
        stepName:"",
        isInitial:false,
        isFinal: false,
    },
    section: [
        {
            title: "Workflow  Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "stepName",
                    label: "Step Name ",
                    Field_Name: "Step name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "stepName",
                    fieldSetting: {
                        placeholder: "Enter Step Name",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
      
                {
                    id: "isInitial",
                    label: "Is Initial",
                    Field_Name: "IsInitial",
                    fieldType: FormFieldTypes.CHECKBOX,
                    dataField: "isInitial",
                    style: {
                      containerCss: "col-xxl-4 col-xl-6 col-md-12 col-12 col-12 mb-input",
                    },
                  },
                  {
                    id: "isFinal",
                    label: "Is Final",
                    Field_Name: "Is Final",
                    fieldType: FormFieldTypes.CHECKBOX,
                    dataField: "isFinal",
                    style: {
                      containerCss: "col-xxl-4 col-xl-6 col-md-12 col-12 col-12 mb-input",
                    },
                  }
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }

}

export const WorkflowStepsGridConfig = {
    columns: [

        {
            name: "Step Name",
            fieldName: "stepName",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Is Initial",
            fieldName: "isInitial",
            colStyle: {
                width: "20%",
            },
            colType: GridColumnType.CHECKBOX,
            colSettings: {
              isDisabled: true,
            },
            allowShort: true,
        },
        {
            name: "Is Final",
            fieldName: "isFinal",
            colStyle: {
                width: "20%",
            },
            colType: GridColumnType.CHECKBOX,
            colSettings: {
              isDisabled: true,
            },
            allowShort: true,
        },
        {
            name: "Created Date",
            fieldName: "createdAt",
            colType: GridColumnType.DATE,
            allowShort: true,
            colSettings: {
              format: "MM/DD/YYYY",
            },
            colStyle: {
                width: "20%",
            },
          },

        {
            name: "Action",
            colStyle: {
                width: "15%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: false,
            },
            customAction: [
                {
                  name: "ADDWORKFLOWACTION",
                  iconName: "ci:list-add",
                  title: "Add Workflow Action",
                  className: "addparameter-icon",
                  isActive: true
                },
              ],
        },
    ],

};