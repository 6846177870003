import { validationTypes } from "../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";
export const AddEditCustomFormsFormData = {
    initialState: {
        formName:"",
        description:"",
        moduleId:"",
        isActive:false,
    },
    section: [
        {
            title: "Custom Forms Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "formName",
                    label: "Form Name ",
                    Field_Name: "Form name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "formName",
                    fieldSetting: {
                        placeholder: "Enter Form Name",
                        allowSpace: true,
                        maxLength:255,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
                {
                    id: "moduleId",
                    label: "Module ",
                    Field_Name: "Module",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "moduleId",
                    fieldSetting: {
                        placeholder: "Select Module ",
                        isMultiSelect: true,
                    },

                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2",
                    },
                },
                {
                    id: "description",
                    label: "Description",
                    Field_Name: "Description",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "description",
                    fieldSetting: {
                      placeholder: "Please enter description",
                      allowSpace: true,
                      maxLength:255
                    },
                   // validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                      containerCss: "col-xxl-12 col-xl-12 col-md-12 mb-2",
                    },
                  },
                {
                    id: "isActive",
                    label: "Is Active",
                    Field_Name: "Is Active",
                    fieldType: FormFieldTypes.CHECKBOX,
                    dataField: "isActive",
                    fieldSetting: {
                        placeholder: "",
                    },
                    style: {
                        containerCss: "col-xxl-5 col-xl-5 col-md-12 col-12 col-12 ",
                    },
                },
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }

}

export const customFormsGridConfig = {
    columns: [

        {
            name: "Forms Name",
            fieldName: "formName",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Module",
            fieldName: "moduleName",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },

        {
            name: "Description",
            fieldName: "formDescription",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Is Active",
            fieldName: "isActive",
            colStyle: {
                width: "15%",
            },
            colType: GridColumnType.CHECKBOX,
            colSettings: {
              isDisabled: true,
            },
            allowShort: true,
        },
        {
            name: "Action",
            colStyle: {
                width: "10%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: false,
            },
            customAction: [
            {
                name: "VIEWFORMFIELDS",
                iconName: "lets-icons:view",
                title: "View",
                className: "view-icon",
                isActive: true
            },
        ],
        },
    ],

};