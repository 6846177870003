/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLazyGetAddresssByCustomerIdQuery } from "../../../../app/services/orderAPI";
import './BillingAddress.scss';

const BillingAddressMismatch = ({ customerId, orderDetails }) => {
    const [customerAddressDetail, setCustomeraddressDetail] = useState(null)
    const [getAddresssByCustomerId, { isFetching: isGetAddresssByCustomerIdFetching, isSuccess: isGetAddresssByCustomerIdSuccess, data: isGetAddresssByCustomerIdData, },] = useLazyGetAddresssByCustomerIdQuery();

    useEffect(() => {
        if (customerId) {
            getAddresssByCustomerId(customerId)
        }
    }, [customerId])

    useEffect(() => {
        if (!isGetAddresssByCustomerIdFetching && isGetAddresssByCustomerIdSuccess && isGetAddresssByCustomerIdData) {
            const preferredBillingAddress = isGetAddresssByCustomerIdData.find(
                (address) => address.type === "Billing" && address.isPreferredBilling === true
            );
            setCustomeraddressDetail(preferredBillingAddress)
        }
    }, [isGetAddresssByCustomerIdFetching, isGetAddresssByCustomerIdSuccess, isGetAddresssByCustomerIdData]);

    const isMismatch = customerAddressDetail && orderDetails?.orderAddressInformation?.billingAddress &&
        (customerAddressDetail.addressLine1 !== orderDetails.orderAddressInformation.billingAddress.addressLine1 ||
            customerAddressDetail.cityName !== orderDetails.orderAddressInformation.billingAddress.cityName ||
            customerAddressDetail.countryName !== orderDetails.orderAddressInformation.billingAddress.countryName ||
            customerAddressDetail.zipCode !== orderDetails.orderAddressInformation.billingAddress.zipCode);

    return (
        <div className="billing-address-container">
            <div className="comparison-header">
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:account-outline"></i>
                    <span>Customer</span>
                </div>
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:file-document-outline"></i>
                    <span>Order</span>
                </div>
            </div>
            <div className="comparison-content">
                <div className={`address-box ${isMismatch ? 'mismatch' : ''}`}>
                    {customerAddressDetail ? (
                        <div className="address-details">
                            <div className="address-line">{customerAddressDetail.addressLine1}</div>
                            {customerAddressDetail.addressLine2 && (
                                <div className="address-line">{customerAddressDetail.addressLine2}</div>
                            )}
                            {customerAddressDetail.addressLine3 && (
                                <div className="address-line">{customerAddressDetail.addressLine3}</div>
                            )}
                            {customerAddressDetail.addressLine4 && (
                                <div className="address-line">{customerAddressDetail.addressLine4}</div>
                            )}
                            {customerAddressDetail.addressLine5 && (
                                <div className="address-line">{customerAddressDetail.addressLine5}</div>
                            )}
                            <div className="address-line">
                                {`${customerAddressDetail.cityName}, ${customerAddressDetail.countryName} ${customerAddressDetail.zipCode}`}
                            </div>
                        </div>
                    ) : (
                        <div className="no-data">N/A</div>
                    )}
                </div>
                <div className={`address-box ${isMismatch ? 'mismatch' : ''}`}>
                    {orderDetails?.orderAddressInformation?.billingAddress ? (
                        <div className="address-details">
                            <div className="address-line">{orderDetails.orderAddressInformation.billingAddress.addressLine1}</div>
                            {orderDetails.orderAddressInformation.billingAddress.addressLine2 && (
                                <div className="address-line">{orderDetails.orderAddressInformation.billingAddress.addressLine2}</div>
                            )}
                            {orderDetails.orderAddressInformation.billingAddress.addressLine3 && (
                                <div className="address-line">{orderDetails.orderAddressInformation.billingAddress.addressLine3}</div>
                            )}
                            {orderDetails.orderAddressInformation.billingAddress.addressLine4 && (
                                <div className="address-line">{orderDetails.orderAddressInformation.billingAddress.addressLine4}</div>
                            )}
                            {orderDetails.orderAddressInformation.billingAddress.addressLine5 && (
                                <div className="address-line">{orderDetails.orderAddressInformation.billingAddress.addressLine5}</div>
                            )}
                            <div className="address-line">
                                {`${orderDetails.orderAddressInformation.billingAddress.cityName}, ${orderDetails.orderAddressInformation.billingAddress.countryName} ${orderDetails.orderAddressInformation.billingAddress.zipCode}`}
                            </div>
                        </div>
                    ) : (
                        <div className="no-data">N/A</div>
                    )}
                </div>
            </div>
        </div>
    );
};

BillingAddressMismatch.propTypes = {
    customerId: PropTypes.string,
    orderDetails: PropTypes.shape({
        orderAddressInformation: PropTypes.shape({
            billingAddress: PropTypes.shape({
                addressLine1: PropTypes.string,
                addressLine2: PropTypes.string,
                addressLine3: PropTypes.string,
                addressLine4: PropTypes.string,
                addressLine5: PropTypes.string,
                cityName: PropTypes.string,
                countryName: PropTypes.string,
                zipCode: PropTypes.string
            })
        })
    })
};

export default BillingAddressMismatch;