
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const thirdPartyAPI = createApi({
    reducerPath: "thirdPartyAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        addEditApiEvent: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/AddEditApiEvent',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEvents: builder.mutation({
            query: (userQuery) => ({
                url: '/ApiEventManagement/GetApiEvents',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventByApiEventId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetApiEventByApiEventId/?apiEventId=${Number(id)}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApiEvent: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/DeleteApiEvent/?apiEventId=${id}`),
                method: 'DELETE',
                body: transformRequest(id)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addApiEventMapping: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/AddApiEventMapping',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventMappings: builder.mutation({
            query: (userQuery) => ({
                url: '/ApiEventManagement/GetApiEventMappings',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventParameters: builder.mutation({
            query: (userQuery) => ({
                url: '/ApiEventManagement/GetApiEventParameters',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiParameterMappings: builder.mutation({
            query: (userQuery) => ({
                url: '/ApiEventManagement/GetApiParameterMappings',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllAPIParametersByEndpointId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetAllAPIParametersByEndpointId?endpointId=${id}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllEventParameterByEventId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetAllEventParameterByEventId?apiEventId=${id}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApiEventMapping: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/DeleteApiEventMapping/?apiEventMappingId=${id}`),
                method: 'DELETE',
                body: transformRequest(id)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addEditApiEventParameter: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/AddEditApiEventParameter',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventParameterByApiEventParametersId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetApiEventParameterByApiEventParametersId/?apiEventId=${Number(id)}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApiEventParameter: builder.mutation({
            query: (request) => ({
                url: encryptQueryString(`/ApiEventManagement/DeleteApiEventParameter/?parameterId=${request.parameterId}&apiEventParametersId=${request.apiEventParametersId}`),
                method: 'DELETE',
                body: transformRequest(request)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApiParameterMapping: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/DeleteApiParameterMapping/?apiParameterMappingId=${id}`),
                method: 'DELETE',
                body: transformRequest(id)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addApiParameterMapping: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/AddApiParameterMapping',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllApiEventParameterByApiEventId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/Common/GetAllApiEventParameterByApiEventId/?apiEventId=${Number(id)}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addEditApiEventResponseField: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/AddEditApiEventResponseField',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventResponseFieldByApiEventResponseFieldId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetApiEventResponseFieldByApiEventResponseFieldId/?apiEventResponseFieldId=${Number(id)}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllResponseFieldsByEventId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetAllResponseFieldsByEventId/?apiEventId=${Number(id)}`),
                method: 'GET',
            }),
            // providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApiEventResponseField: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/DeleteApiEventResponseField/?apiEventResponseFieldId=${id}`),
                method: 'DELETE',
                body: transformRequest(id)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApiEventResponseFieldsMapping: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/DeleteApiEventResponseFieldsMapping/?apiEventResponseFieldsMappingId=${id}`),
                method: 'DELETE',
                body: transformRequest(id)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventResponseFields: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/GetApiEventResponseFields',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventResponseFieldsMappings: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/GetApiEventResponseFieldsMappings',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addApiEventResponseFieldsMapping: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/AddApiEventResponseFieldsMapping',
                method: 'POST',
                body: transformRequest(data)
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        thirdPartyAPICall: builder.mutation({
            query: (data) => ({
                url: '/ApiConfiguration/ThirdPartyAPICall',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getApiEventLogByEventId: builder.mutation({
            query: (data) => ({
                url: '/ApiEventManagement/GetApiEventLogByEventId',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getApiEventLogsByGuidandEventId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/ApiEventManagement/GetApiEventLogsByGuidandEventId/?eventId=${(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    })
})

export const {
    useAddEditApiEventMutation,
    useAddApiEventMappingMutation,
    useAddEditApiEventResponseFieldMutation,
    useAddApiEventResponseFieldsMappingMutation,


    useAddApiParameterMappingMutation,
    useAddEditApiEventParameterMutation,
    useGetApiEventsMutation,
    useGetApiEventResponseFieldsMutation,
    useLazyGetAllAPIParametersByEndpointIdQuery,
    useLazyGetAllEventParameterByEventIdQuery,
    useGetApiParameterMappingsMutation,
    useGetApiEventMappingsMutation,

    useGetApiEventResponseFieldsMappingsMutation,

    useGetApiEventParametersMutation,
    useLazyGetApiEventByApiEventIdQuery,
    useLazyGetApiEventParameterByApiEventParametersIdQuery,
    useLazyGetApiEventResponseFieldByApiEventResponseFieldIdQuery,
    useLazyGetAllResponseFieldsByEventIdQuery,


    useLazyGetAllApiEventParameterByApiEventIdQuery,
    useDeleteApiEventMutation,
    useDeleteApiEventMappingMutation,
    useDeleteApiEventParameterMutation,
    useDeleteApiParameterMappingMutation,
    useDeleteApiEventResponseFieldMutation,
    useDeleteApiEventResponseFieldsMappingMutation,

    useThirdPartyAPICallMutation,
    useGetApiEventLogByEventIdMutation,
    useLazyGetApiEventLogsByGuidandEventIdQuery
} = thirdPartyAPI

export default thirdPartyAPI;