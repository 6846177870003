/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import {  AddEditParameterFormData } from '../config/AddEditParameter.data';
import { useAddEditApiEventParameterMutation, useLazyGetApiEventParameterByApiEventParametersIdQuery } from '../../../../../../../../app/services/thirdPartyAPI';
import { ApiParametersDataTypes, JsonTypes, ParameterType } from '../../../../../../../../utils/Enums/commonEnums';
import ToastService from '../../../../../../../../services/toastService/ToastService';
import { onResetForm } from '../../../../../../../../utils/FormFields/ResetForm/handleResetForm';
import FormCreator from '../../../../../../../../components/FinalForms/FormCreator';
import Buttons from '../../../../../../../../components/ui/button/Buttons';
import { getFieldData } from '../../../../../../../../utils/FormFields/FieldsSetting/SetFieldSetting';
import PropTypes from 'prop-types';
import { removeFormFields } from '../../../../../../../../utils/FormFields/RemoveFields/handleRemoveFields';

const AddEditEventParameter = (props) => {
  const addEditParameterRef = useRef();
  const [addEditParameterData, setAddEditParameterData] = useState(AddEditParameterFormData)
  const [addEditApiEventParameter, { isLoading: isAddEditApiEventParameterLoading, isSuccess: isAddEditApiEventParameterSuccess, data: allAddEditApiEventParameterData, },] = useAddEditApiEventParameterMutation();
  const [getApiEventParameterByApiEventParametersId, { isFetching: isGetApiEventParameterByApiEventParametersIdFetching,
    isSuccess: isGetApiEventParameterByApiEventParametersIdSucess, data: allGetApiEventParameterByApiEventParametersIdData }] = useLazyGetApiEventParameterByApiEventParametersIdQuery();


  useEffect(() => {
    const dropdownField = getFieldData(AddEditParameterFormData, 'dataType');
    dropdownField.fieldSetting.options = Object.entries(ApiParametersDataTypes).map(([key, value]) => ({
      label: key,
      value: value,
    }));
  }, []);

  const handleJsonTypes = () => {
    const dropdownField = getFieldData(AddEditParameterFormData, 'jsonType');
    dropdownField.fieldSetting.options = Object.entries(JsonTypes).map(([key, value]) => ({
      label: value,
      value: value,
    }));
  }


  useEffect(() => {
    if (isAddEditApiEventParameterSuccess && allAddEditApiEventParameterData) {
      if (allAddEditApiEventParameterData.errorMessage.includes("EXISTS")) {
        ToastService.warning(allAddEditApiEventParameterData.errorMessage);

        return;
      }
      ToastService.success(allAddEditApiEventParameterData.errorMessage);
      handleResetAndClose();
      props.onGetData()
    }
  }, [isAddEditApiEventParameterSuccess, allAddEditApiEventParameterData]);


  const handleAddEditAPIPRovider = () => {
    
    const formData = addEditParameterRef.current.getFormData();
    if (formData && !formData.parameterId) {
      let request = {
      
        apiEventId: props.keyId ? props.keyId : 0,
        parameterName: formData.parameterName,
        dataType: formData.dataType && typeof formData.dataType === "object" ? formData.dataType.value : formData.dataType,
        parameterType: ParameterType.EVENT,
        isRequired: formData.isRequired,
        defaultValue: formData.defaultValue,
        jsonType: formData.jsonType && typeof formData.jsonType === "object" ? formData.jsonType.value : formData.jsonType,
      };
      addEditApiEventParameter(request);
    }
      else if(formData &&  formData.parameterId){
        let request = {
          parameterId:formData.parameterId ,
          apiEventId: props.keyId ? props.keyId : 0,
          parameterName: formData.parameterName,
          dataType: formData.dataType && typeof formData.dataType === "object" ? formData.dataType.value : formData.dataType,
          parameterType: ParameterType.EVENT,
          isRequired: formData.isRequired,
          defaultValue: formData.defaultValue,
          jsonType: formData.jsonType && typeof formData.jsonType === "object" ? formData.jsonType.value : formData.jsonType,
        };
        addEditApiEventParameter(request);
      
    }
  };

  useEffect(() => {
    if (props.isOpen && !props.getData) {
      onResetForm(AddEditParameterFormData, setAddEditParameterData, null);
      const modifyFormFields = removeFormFields(AddEditParameterFormData, ["jsonType"]);
      setAddEditParameterData(modifyFormFields);
    }
  }, [props.isOpen])

  const handleResetAndClose = () => {
    onResetForm(AddEditParameterFormData, setAddEditParameterData, null);
    props.onClose();
  };

  useEffect(() => {
    if (props.getData) {
      getApiEventParameterByApiEventParametersId(props.getData.parameterId)
       const dataTypeValue= props.getData.dataType.split(" (")[0];
      let formData = { ...AddEditParameterFormData }
      if (dataTypeValue === ApiParametersDataTypes.Json) {
      setAddEditParameterData(formData);
        handleJsonTypes();
      } else {
        formData = removeFormFields(formData, ["jsonType"]);
        setAddEditParameterData(formData);
      }
    }
  }, [props.getData])

  useEffect(() => {
    if (!isGetApiEventParameterByApiEventParametersIdFetching && isGetApiEventParameterByApiEventParametersIdSucess && allGetApiEventParameterByApiEventParametersIdData) {
      let setData = { ...addEditParameterData }
    
      setData.initialState = {
        ...allGetApiEventParameterByApiEventParametersIdData,
        parameterName: allGetApiEventParameterByApiEventParametersIdData.parameterName,
        dataType: allGetApiEventParameterByApiEventParametersIdData.dataType,
        apiEventParametersId: allGetApiEventParameterByApiEventParametersIdData.apiEventParametersId,
        apiEventId: allGetApiEventParameterByApiEventParametersIdData.apiEventId,
        defaultValue: allGetApiEventParameterByApiEventParametersIdData.defaultValue,
        parameterType: ParameterType.EVENT,
        isRequired: allGetApiEventParameterByApiEventParametersIdData.isRequired
      }


      setAddEditParameterData(setData)
    }
  }, [isGetApiEventParameterByApiEventParametersIdFetching, isGetApiEventParameterByApiEventParametersIdSucess, allGetApiEventParameterByApiEventParametersIdData]);


  const handleChange = (dataField, updatedData) => {
    
    if (dataField === "dataType") {
      let manageData = { ...AddEditParameterFormData };
      manageData.initialState = { ...updatedData };
      if (updatedData.dataType.value === ApiParametersDataTypes.Json) {
        manageData.initialState.jsonType = "";
        setAddEditParameterData(manageData);
        handleJsonTypes();
      } else {
        manageData = removeFormFields(manageData, ["jsonType"]);
        manageData.initialState.jsonType = "";
        setAddEditParameterData(manageData);
      }
    }
  }
  return (
    <div className="row mt-2 add-address-form">
      <div className='col-12'>
        <FormCreator config={addEditParameterData} ref={addEditParameterRef} onColumnChange={handleChange}

        />
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex align-item-end justify-content-end">
          <Buttons
            buttonTypeClassName="theme-button"
            buttonText="Save"
            onClick={handleAddEditAPIPRovider}
            isLoading={isAddEditApiEventParameterLoading}
          />
          <Buttons
            buttonTypeClassName="dark-btn ml-5"
            buttonText="Cancel"
            onClick={handleResetAndClose}
          />
        </div>
      </div>
    </div>
  )
}

AddEditEventParameter.propTypes = {
  keyId: PropTypes.number,
  isOpen: PropTypes.bool,
  getData: PropTypes.object,
  onGetData: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddEditEventParameter