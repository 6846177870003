import { securityKey } from "../../../data/SecurityKey";

export const Menu = [
  {
    groupLabel: "Main",
    items: [
      {
        name: "Dashboard",
        id: "Dashboard",
        to: "/",
        iconClass: "ri:home-3-line",
        subMenu: false,
        securityKey: securityKey.DASHBOARD,
        children: [],
      },
      {
        name: "System Approval",
        id: "SystemApproval",
        to: "/SystemApproval",
        iconClass: "octicon:tasklist-24",
        subMenu: false,
        securityKey: securityKey.MYTASK,
        children: [],
      },
      {
        name: "System Task",
        id: "SystemTask",
        to: "/SystemTask",
        iconClass: "octicon:tasklist-24",
        subMenu: false,
        securityKey: securityKey.MYTASK,
        children: [],
      },
    ],
  },
  {
    groupLabel: "Management",
    items: [
      {
        id: "customer",
        name: "Customer Details",
        iconClass: "vaadin:clipboard-user",
        subMenu: true,
        to: "#",
        securityKey: securityKey.CUSTOMER,
        children: [
          {
            id: "addCustomer",
            submenuName: "Add Customer",
            to: "/customer/create",
            securityKey: securityKey.ADDCUSTOMER,
          },
          {
            id: "Customers",
            submenuName: "Customer",
            to: "/customer/list",
            securityKey: securityKey.CUSTOMERLIST,
          },
          {
            id: "PotentialCustomer",
            submenuName: "Potential Customer",
            to: "/customer/potentialCustomer",
            securityKey: securityKey.CUSTOMERLIST,
          },
        ],
      },
      {
        id: "supplier",
        name: "Supplier Details",
        iconClass: "streamline:shipping-truck",
        subMenu: true,
        to: "#",
        securityKey: securityKey.SUPPLIER,
        children: [
          {
            id: "addSupplier",
            submenuName: "Add Supplier",
            to: "/supplier/create",
            securityKey: securityKey.ADDSUPPLIER,
          },
          {
            id: "Suppliers",
            submenuName: "Supplier",
            to: "/supplier/list",
            securityKey: securityKey.SUPPLIERLIST,
          },
          {
            id: "supplierWarehouse",
            submenuName: "Supplier Warehouse",
            to: "/supplierWarehouse/list",
            securityKey: securityKey.SUPPLIERLIST,
          },
        ],
      },
    ],
  },

  {
    groupLabel: "Orders",
    items: [
      {
        name: "Order Details",
        id: "Order",
        to: "#",
        iconClass: "lets-icons:order",
        subMenu: true,
        securityKey: securityKey.ORDER,
        children: [
          {
            id: "orderList",
            submenuName: "Order",
            to: "/order/list",
            securityKey: securityKey.ORDER,
          },
      
        ],
      },

    ],
  },

];

export const ConfigurationMenu = [
  {
    items:
      [
        {
          id: "administration",
          name: "Administration",
          iconClass: "vaadin:clipboard-user",
          subMenu: true,
          to: "#",
          securityKey: securityKey.CONFIGURATIONMANAGEMENT,
          children: [
            {
              submenuName: "Organization",
              id: "Organization",
              to: "/configuration/Organization",
              iconClass: "solar:buildings-2-outline",
              securityKey: securityKey.ORGANIZATION,
            },
            {
              submenuName: "User Management",
              id: "User Management",
              to: "/configuration/usermanagement/list",
              iconClass: "mdi:account-group-outline",
              securityKey: securityKey.USERMANAGEMENT,
            },
            {
              submenuName: "Role Management",
              id: "Role Management",
              iconClass: "mdi:shield-account-outline",
              to: "/configuration/rolemanagement/list",
              securityKey: securityKey.SECURITYROLEMANAGEMENT,
            },
            {
              submenuName: "System Task Configuration",
              id: "System Task Configuration",
              iconClass: "mdi:shield-account-outline",
              to: "/configuration/systemTaskConfiguration/list",
              securityKey: securityKey.SYSTEMTASKCONFIGURATION,
            },
            {
              submenuName: "Custom Forms",
              id: "Custom Forms",
              iconClass: "mdi:shield-account-outline",
              to: "/configuration/customForms",
              securityKey: securityKey.CUSTOMFORMS,
            },

          ]
        }
      ],
  },
  {
    groupLabel: "System Configurations",
    items: [
      {
        id: "approvalRules",
        name: "Approval Rules",
        iconClass: "vaadin:clipboard-user",
        subMenu: true,
        to: "#",
        securityKey: securityKey.RULES,
        children: [
          {
            id: "approvalRules",
            to: "/configuration/approvalrules/list",
            securityKey: securityKey.APPROVALRULES,
            submenuName: "Approval Rules",
            iconClass: "hugeicons:validation-approval",
            subMenu: false,
            children: [],
          },
          {
            id: "functionalConfiguration",
            to: "/configuration/functionalconfiguration/list",
            securityKey: securityKey.FUNCTIONALCONFIGURATION,
            submenuName: "Functional Configuration",
            iconClass: "solar:settings-broken",
            subMenu: false,
            children: [],
          },
        ]
      },
      {
        id: "provider",
        name: "Provider",
        iconClass: "vaadin:clipboard-user",
        subMenu: true,
        to: "#",
        securityKey: securityKey.PROVIDERS,
        children: [
          {
            id: "apiProviders",
            to: "/configuration/apiproviders/list",
            securityKey: securityKey.APIPROVIDERS,
            submenuName: "API Providers",
            iconClass: "icon-park-outline:api",
            subMenu: false,
            children: [],
          },
          {
            id: "Api Configuration",
            to: "/configuration/systemevent/list",
            securityKey: securityKey.SYSTEMEVENT,
            submenuName: "System Event",
            iconClass: "hugeicons:api",
            subMenu: false,
            children: [],
          },
        ]
      },
      {
        id: "email",
        name: "Email",
        iconClass: "vaadin:clipboard-user",
        subMenu: true,
        to: "#",
        securityKey: securityKey.EMAIL,
        children: [
          {
            id: "Email Template",
            to: "/configuration/emailtemplate/list",
            securityKey: securityKey.EMAILTEMPLATE,
            submenuName: "Email Template",
            iconClass: "ic:outline-email",
            subMenu: false,
            children: [],
          },
          {
            id: "Snippet",
            to: "/configuration/snippet/list",
            securityKey: securityKey.SNIPPETS,
            submenuName: "Snippets",
            iconClass: "nonicons:snippet-16",
            subMenu: false,
            children: [],
          },
          {
            id: "Email Actions",
            to: "/configuration/emailactions/list",
            securityKey: securityKey.EMAILACTION,
            submenuName: "Email Actions",
            iconClass: "ic:outline-email",
            subMenu: false,
            children: [],
          },
        ]
      },

      {
        id: "Dictionary",
        to: "/configuration/dictionary/list",
        securityKey: securityKey.DICTIONARY,
        name: "Dictionary",
        iconClass: "streamline:dictionary-language-book",
        subMenu: false,
        children: [],
      },
      {
        id: "System Constraints",
        to: "/configuration/systemConstraints/list",
        securityKey: securityKey.SYSTEMCONSTRAINTS,
        name: "System Constraints",
        iconClass: "ic:outline-email",
        subMenu: false,
        children: [],
      },

      {
        id: "orderBadges",
        name: "System Badges",
        to: "/configuration/orderBadges/list",
        securityKey: securityKey.ORDERBADGES,
        iconClass: "lets-icons:order",
        subMenu: false,
        children: [],
      },
    ],
  },
];

export const EmailIntentMenu = [
  {
    groupLabel: "Email Intent",
    items: [
      {
        id: "quotation",
        to: "/emailIntent/Quotation",
        securityKey: securityKey.ORDER,
        name: "Quotation",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "orderfollowUp",
        to: "/emailIntent/OrderFollowUp",
        securityKey: securityKey.ORDER,
        name: "Order FollowUp ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "purchaseOrder",
        to: "/emailIntent/PurchaseOrder",
        securityKey: securityKey.ORDER,
        name: "Purchase Order ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "documentRequest",
        to: "/emailIntent/DocumentRequest",
        securityKey: securityKey.ORDER,
        name: "Document Request ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "qualityIssue",
        to: "/emailIntent/QualityIssue",
        securityKey: securityKey.ORDER,
        name: "Quality Issue ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "accounting",
        to: "/emailIntent/Accounting",
        securityKey: securityKey.ORDER,
        name: "Accounting ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "newCompanySetup",
        to: "/emailIntent/NewCompanySetup",
        securityKey: securityKey.ORDER,
        name: "New Company Setup ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
      {
        id: "other",
        to: "/emailIntent/Other",
        securityKey: securityKey.ORDER,
        name: "Other ",
        iconClass: "hugeicons:validation-approval",
        subMenu: false,
        children: [],
      },
    ],
  },
];
