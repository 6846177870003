
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const formFieldsAPI = createApi({
    reducerPath: "formFieldsAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addEditFormField: builder.mutation({
            query: (requestData) => ({
                url: '/FormField/AddEditFormField',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
     
        getFormFields: builder.mutation({
            query: (requestData) => ({
                url: '/FormField/GetFormFields',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
  
        geFormFieldsByFormFieldId: builder.query({
            query: (formFieldId) => ({
                url: encryptQueryString(`/FormField/GeFormFieldsByFormFieldId/?formFieldId=${Number(formFieldId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
       
    })
})

export const {
     useAddEditFormFieldMutation, useGetFormFieldsMutation,useLazyGeFormFieldsByFormFieldIdQuery,
    
} = formFieldsAPI

export default formFieldsAPI;