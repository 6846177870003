
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useImperativeHandle, useState } from "react";

//** Lib's */
import formatDate from "../../../../lib/formatDate";
import Buttons from "../../../../components/ui/button/Buttons";
import Iconify from "../../../../components/ui/iconify/Iconify";
import Tooltip from "../../../../components/ui/tooltip2/Tooltip";
import { TextInputType } from "../../../../data/formControlTypes";
import Input from "../../../../components/FinalForms/ui/inputs/input/Input";
import { FirstSecondLetter } from "../../../../utils/FirstSecLetter/FirstSecondLetter";

//** Service's */
import NoRecordFound from "../../../../components/FinalMolGrid/ui/noRecordFound/NoRecordFound";
import { useAddEditSystemTaskCommentsMutation, useLazyGetSystemTaskCommentsBySystemTaskBadgeIdQuery } from "../../../../app/services/systemTaskAPI";
import ToastService from "../../../../services/toastService/ToastService";
import DataLoader from "../../../../components/ui/dataLoader/DataLoader";


const SystemComments = ({ systemTaskBadgeId, childRef }) => {

    const [comments, setComments] = useState("");
    const [oldComment, setOldComment] = useState({ oldInternalComment: "", oldCustomerComment: "" });
    // const [oldCustomerComment,setOldCustomerComment]=useState("")
    const [systemCommentsList, setSystemCommentsList] = useState([])
    const [isEditId, setIsEditId] = useState(null)
    const [isCusEditId, setIsCusEditId] = useState(null)

    const [taskComments, setTaskComments] = useState("");
    const [customerEditComments, setCustomerEditComments] = useState("");
    const [addEditSystemTaskComments, { isLoading, isSuccess: isAddEditSystemTaskCommentsSuccess, data: isAddEditSystemTaskCommentsData, },] = useAddEditSystemTaskCommentsMutation();
    const [
        getSystemTaskCommentsBySystemTaskBadgeId,
        {
            isFetching: isGetSystemTaskCommentsBySystemTaskBadgeIdFetching,
            isSuccess: isGetSystemTaskCommentsBySystemTaskBadgeIdSuccess,
            data: GetSystemTaskCommentsBySystemTaskBadgeIdData,
        },
    ] = useLazyGetSystemTaskCommentsBySystemTaskBadgeIdQuery();


    useEffect(() => {
        if (systemTaskBadgeId) {
            getSystemTaskCommentsBySystemTaskBadgeId(systemTaskBadgeId)
        }
    }, [systemTaskBadgeId])

    useEffect(() => {
        if (!isGetSystemTaskCommentsBySystemTaskBadgeIdFetching && isGetSystemTaskCommentsBySystemTaskBadgeIdSuccess && GetSystemTaskCommentsBySystemTaskBadgeIdData) {
            if (GetSystemTaskCommentsBySystemTaskBadgeIdData) {
                setSystemCommentsList(GetSystemTaskCommentsBySystemTaskBadgeIdData);
            }
        }
    }, [isGetSystemTaskCommentsBySystemTaskBadgeIdFetching, isGetSystemTaskCommentsBySystemTaskBadgeIdSuccess, GetSystemTaskCommentsBySystemTaskBadgeIdData]);

    useEffect(() => {
        if (isAddEditSystemTaskCommentsSuccess && isAddEditSystemTaskCommentsData) {
            ToastService.success(isAddEditSystemTaskCommentsData.errorMessage);
            setComments("")
            systemTaskBadgeId && getSystemTaskCommentsBySystemTaskBadgeId(systemTaskBadgeId)
        }
    }, [isAddEditSystemTaskCommentsSuccess, isAddEditSystemTaskCommentsData])

    const handleSaveComments = () => {
        if (comments.trim() !== "") {
            const requestData = {
                systemTaskCommentsId: 0,
                internalComments: comments,
                customerComments: null,
                systemTaskBadgeId: systemTaskBadgeId
            }
            addEditSystemTaskComments(requestData)
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (comments.trim() !== "") {
                handleSaveComments();
            }
        }
    };

    const handleEdit = (id, oldInternalComment, cusComment) => {
        setIsEditId(id)
        setTaskComments(oldInternalComment)
        setOldComment({ oldInternalComment: oldInternalComment, oldCustomerComment: cusComment })
    }

    const handleCancelEdit = (type) => {
        if (type === "Comment") {

            setIsEditId(null)
            setTaskComments(null);
        }
        else {
            setIsCusEditId(null);
            setCustomerEditComments(null)
        }

    }

    const onGetData = () => {
        getSystemTaskCommentsBySystemTaskBadgeId(systemTaskBadgeId)
    }

    const handleSubmitEdit = (commentId) => {

        const editRequest = {
            systemTaskCommentsId: commentId,
            internalComments: taskComments || oldComment.oldInternalComment,
            systemTaskBadgeId: systemTaskBadgeId
        };

        if (oldComment.oldCustomerComment) {
            editRequest.customerComments = customerEditComments || oldComment.oldCustomerComment;
        }

        addEditSystemTaskComments(editRequest);
        setIsEditId(null);
        setIsCusEditId(null);
    };


    const handleOnChange = (event, type) => {
        if (type === "Comment") {

            setTaskComments(event.target.value)
        } else {
            setCustomerEditComments(event.target?.value)
        }
    }

    const handleCustomerEdit = (id, commentValue, oldCusCommentVal) => {
        setIsCusEditId(id);
        setCustomerEditComments(oldCusCommentVal)
        setOldComment({ oldInternalComment: commentValue, oldCustomerComment: oldCusCommentVal })
    }

    useImperativeHandle(childRef, () => ({
        callChildFunction: onGetData,
    }));

    return (
        <React.Fragment>
            {!isGetSystemTaskCommentsBySystemTaskBadgeIdFetching && !isLoading ?
                <>
                    {/* {selectedSystemTaskObj && workFlowResponse ? */}
                    <div className="comment-sections">
                        <div className="comment-main-card">
                            <div className="title-sec">
                                <h6 className="titles">Comments</h6>
                                <h6 className="comments-count">{systemCommentsList?.length}</h6>
                            </div>
                            <div className="comment-list">
                                {
                                    systemCommentsList.length > 0 ?
                                        (systemCommentsList.map((response) => {
                                            return (
                                                <div className="comment-sec-inner" key={response.systemTaskCommentsId}>
                                                    <div className="profile-parts">
                                                        <div className="profile-icons">
                                                            {FirstSecondLetter(response?.userName)}
                                                        </div>
                                                        <div className="name-time">
                                                            <div className="name-parts">{response.userName}</div>
                                                            <div className="time-parts">{formatDate(response.createdAt)}</div>
                                                            {response.requestData && (
                                                                <Tooltip label="Request Data" position="right">
                                                                    <Iconify
                                                                        icon="lucide:file-json-2"
                                                                        style={{ cursor: "pointer" }}
                                                                    // onClick={() => handleShowRowData(response.requestData, response.actionType)}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {response.internalComments ? (<div className="comment-parts d-flex align-items-center">
                                                        <span className="mr-2 fw-bold">Comment:</span>
                                                        {isEditId === response.systemTaskCommentsId ? (
                                                            <textarea
                                                                value={taskComments}
                                                                onChange={(e) => handleOnChange(e, "Comment")}
                                                                rows="2"
                                                                cols="30"
                                                            />
                                                        ) : (
                                                            <span>{response.internalComments}</span>
                                                        )}

                                                        <span className="ml-1">
                                                            {isEditId === response.systemTaskCommentsId ? (
                                                                <div className="d-flex align-items-center">
                                                                    <Iconify
                                                                        icon="tabler:check"
                                                                        onClick={() => handleSubmitEdit(response.systemTaskCommentsId)}
                                                                    />
                                                                    <Iconify icon="tabler:x" onClick={() => handleCancelEdit("Comment")} />
                                                                </div>
                                                            ) : (
                                                                <Iconify
                                                                    icon="fluent:edit-20-regular"
                                                                    onClick={() => handleEdit(response.systemTaskCommentsId, response.internalComments, response.customerComments)}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>) : null}

                                                    {response.customerComments ? (
                                                        <div className="comment-parts d-flex align-items-center">
                                                            <span className="mr-2 fw-bold">Customer Comment:</span>
                                                            {isCusEditId === response.systemTaskCommentsId ? (
                                                                <textarea
                                                                    value={customerEditComments}
                                                                    onChange={(e) => handleOnChange(e, "CustomerComment")}
                                                                    rows="2"
                                                                    cols="30"
                                                                />
                                                            ) : (
                                                                <span>{response.customerComments}</span>
                                                            )}

                                                            <span className="ml-1">
                                                                {isCusEditId === response.systemTaskCommentsId ? (
                                                                    <div className="d-flex align-items-center">
                                                                        <Iconify
                                                                            icon="tabler:check"
                                                                            onClick={() => handleSubmitEdit(response.systemTaskCommentsId)}
                                                                        />
                                                                        <Iconify icon="tabler:x" onClick={() => handleCancelEdit("CustomerComment")} />
                                                                    </div>
                                                                ) : (
                                                                    <Iconify
                                                                        icon="fluent:edit-20-regular"
                                                                        onClick={() => handleCustomerEdit(response.systemTaskCommentsId, response.internalComments, response.customerComments,)}
                                                                    />
                                                                )}
                                                            </span>
                                                        </div>) : null
                                                    }

                                                </div>
                                            );

                                        }))
                                        : <NoRecordFound />
                                }

                            </div>
                            <div className="comments-input">
                                <form onKeyPress={handleKeyDown}>
                                    <div className="input-btn-part">
                                        <div className="input-part">
                                            <Input name="Please Enter Your Comments" type={TextInputType.TEXT}
                                                value={comments} onChange={(e) => setComments(e.target.value)} />
                                        </div>
                                        <div className="btn-part">
                                            <Buttons isIcon={true} iconClass="tabler:send" onClick={handleSaveComments}
                                                buttonTypeClassName="theme-button" buttonText="" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* : ""} */}
                </>
                : <DataLoader />
            }

        </React.Fragment >
    )
}
export default SystemComments;