import PropTypes from 'prop-types';
import './CreditLimitCrossed.scss';

const CreditLimitCrossed = ({ paymentTermDetail, orderItemDetail }) => {
    const grandTotal = orderItemDetail?.map(item => item.quantity * item.itemUnitPrice).reduce((sum, itemTotal) => sum + itemTotal, 0);

    const isExceeded = grandTotal > paymentTermDetail?.creditLimit;

    return (
        <div className="credit-limit-container">
            <div className="comparison-header">
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:credit-card-outline"></i>
                    <span>Credit Limit</span>
                </div>
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:currency-usd"></i>
                    <span>Order Total</span>
                </div>
            </div>
            <div className="comparison-content">
                <div className={`limit-box ${isExceeded ? 'mismatch' : ''}`}>
                    <div className="limit-details">
                        <div className="limit-value">
                            {paymentTermDetail?.creditLimit ? `$${paymentTermDetail.creditLimit}` : 'N/A'}
                        </div>
                    </div>
                </div>
                <div className={`limit-box ${isExceeded ? 'mismatch' : ''}`}>
                    <div className="limit-details">
                        <div className="limit-value">
                            {grandTotal ? `$${grandTotal}` : 'N/A'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CreditLimitCrossed.propTypes = {
    paymentTermDetail: PropTypes.shape({
        creditLimit: PropTypes.number
    }),
    orderItemDetail: PropTypes.arrayOf(PropTypes.shape({
        quantity: PropTypes.number,
        itemUnitPrice: PropTypes.number
    }))
};

export default CreditLimitCrossed;