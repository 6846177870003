import React, {   useRef, useState } from 'react'
import { AppIcons } from '../../../data/appIcons';
import CardSection from "../../../components/ui/card/CardSection";

import SidebarModel from '../../../components/ui/sidebarModel/SidebarModel';
import AddEditSnippet from './features/AddEditSnippet';
import SnippedList from './features/config/SnippetList';
import { onResetForm } from '../../../utils/FormFields/ResetForm/handleResetForm';
import { SnippetListData } from './features/config/SnippetFormData';
import { useEffect } from 'react';

 const Snippet = ()=> {
  
  const getDataRef=useRef();
  const childRef = useRef();
  const [formData, setFormData] = useState(SnippetListData);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
 
  const [search, setSearch] = useState("");


  const handleToggleModal = () => {
    onResetForm(SnippetListData,setFormData, null);
    setIsModelOpen(true);
  };

  const onSidebarClose = () => {
    setIsModelOpen(false);
    setIsEdit(false);
    if(childRef.current){
      childRef.current.callChildFunction();
    }
  };

  const handleEditClick = (data) => {
    onResetForm(formData,setFormData, null);
    setIsModelOpen(true);
    setFormData(data);
    setIsEdit(true);  
  };

  const onSuccess = () => {
    if (getDataRef.current) {
      getDataRef.current.callChildFunction();
    }
  };

  useEffect(() => {
    if (search.length > 0 ) {
      onSuccess();
    }
  }, [search]);

  const handleChange = (event) => {
      setSearch(event.target.value);
      // if(event.target.value.length > 0){
      //   onSuccess();
      // }
    };

 

    const handleClear = () => {
      setSearch("");
       
    };
    return (
       
        <div className='snippets'>
        <CardSection
          cardTitle="Snippets"
          buttonClassName="btn theme-button"
          rightButton={true}
          textWithIcon={true}
          iconImg={AppIcons.PlusIcon}
          titleButtonClick={handleToggleModal}
          handleChange={handleChange}
          searchInputName="Search By Snippet Name"
          searchInput={true}
         
          clearButton={true}
          clearTitleButtonClick={handleClear}
          clearButtonText="Clear"
          clearButtonClassName="dark-btn"
       
          clearTextWithIcon={true}
          clearIconImg={AppIcons.ClearIcone}
          searchValue={search}
       
        
        >
          <SnippedList 
            getDataRef={getDataRef}
            handleEditClick={handleEditClick}
            initData={formData}
            handleChange={handleChange}
         
            search={search}
            handleClear={handleClear}
          />
        </CardSection>
  
        <SidebarModel
          modalTitle={
             isEdit? "Update Snippet":"Add Snippet"
          }
          contentClass="content-40"
          onClose={onSidebarClose}
          modalTitleIcon={AppIcons.AddIcon}
          isOpen={isModelOpen}
        >
          <AddEditSnippet
           isEdit={isEdit}
            initData={formData}
           onClose={onSidebarClose}
           onSuccess={onSuccess}
            isModelOpen={isModelOpen}
            childRef = {childRef}
          /> 
        </SidebarModel>
      </div>
       
    )
  }
 export default Snippet;