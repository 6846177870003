import React from 'react';
import AppRoutes from './layouts/navigation/AppRoutes';
import { ComponentNavigation } from './layouts/navigation/ComponentNavigation';

export const App = () => {
  return (
    <div>
      <AppRoutes componentRoutes={ComponentNavigation} />
    </div>
  )
};