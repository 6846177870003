import SystemTaskOrderItemList from "./SystemTaskOrderItemList";

const LeadTime = ({  orderItemDetail }) => {
    return (

        <div className="mb-2">
            <div className="row mb-2">
            <SystemTaskOrderItemList orderItemDetail={orderItemDetail} />
   </div>
  
        </div>
    )
}
export default LeadTime;