import { validationTypes } from "../../../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../../../data/gridColumnType";

export const addEditWorkflowFormData = {
    initialState: {
        workflowName:"",
        description:"",
    },
    section: [
        {
            title: "Workflow  Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "workflowName",
                    label: "Workflow Name ",
                    Field_Name: "Workflow name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "workflowName",
                    fieldSetting: {
                        placeholder: "Enter workflow name",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
      
                {
                    id: "description",
                    label: "Description",
                    Field_Name: "Description",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "description",
                    fieldSetting: {
                      placeholder: "Please enter description",
                      allowSpace: true,
                    },
                   // validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                      containerCss: "col-xxl-12 col-xl-12 col-md-12 mb-2",
                    },
                  },
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }

}

export const WorkflowGridConfig = {
    columns: [

        {
            name: "System Task Name",
            fieldName: "systemTaskName",
            colStyle: {
                width: "20%",
            },
            allowShort: true,
        },
        {
            name: "Work flow Name",
            fieldName: "workflowName",
            colStyle: {
                width: "15%",
            },
            allowShort: true,
        },
        {
            name: "Description",
            fieldName: "description",
            colStyle: {
                width: "40%",
            },
            allowShort: true,
        },
        {
            name: "Created Date",
            fieldName: "createdAt",
            colType: GridColumnType.DATE,
            allowShort: true,
            colSettings: {
              format: "MM/DD/YYYY",
            },
            colStyle: {
                width: "15%",
            },
          },

        {
            name: "Action",
            colStyle: {
                width: "10%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: false,
            },
        },
    ],

};