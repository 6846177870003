import './PaymentTerm.scss';

const PaymentTermMismatch = ({ paymentTermDetail, orderDetails }) => {
    const isMismatch = paymentTermDetail?.paymentTerm !== orderDetails?.paymentTerm;

    return (
        <div className="payment-term-container">
            <div className="comparison-header">
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:account-outline"></i>
                    <span>Customer</span>
                </div>
                <div className="header-item">
                    <i className="iconify" data-icon="mdi:file-document-outline"></i>
                    <span>Order</span>
                </div>
            </div>
            <div className="comparison-content">
                <div className={`term-value ${isMismatch ? 'mismatch' : ''}`}>
                    {paymentTermDetail?.paymentTerm || "N/A"}
                </div>
                <div className={`term-value ${isMismatch ? 'mismatch' : ''}`}>
                    {orderDetails?.paymentTerm || "N/A"}
                </div>
            </div>
        </div>
    );
};

export default PaymentTermMismatch;