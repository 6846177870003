import FileViewer from "react-file-viewer";
import { FormFieldTypes } from "../../components/FinalForms/libs/data/formFieldType";
import { TextInputType } from "../../components/FinalForms/libs/data/formControlTypes";


export const getDropdownLabelName = (apiResponseData, valueField, labelField, selectedId) => {
    if (apiResponseData && selectedId && valueField && labelField) {
        // Map the data to the required format
        const mappedData = apiResponseData.map((item) => ({
            value: item[valueField],
            label: item[labelField]
        }));

        // Find the matching entry based on selectedId
        const labelName = mappedData.find(data => Number(data.value) === Number(selectedId));
        if (labelName) {
            return labelName.label; // Return the label field value
        }
    }
    return null;
}


export const removeIdSuffix = (fieldName) => {
    if (fieldName.toLowerCase().endsWith('id')) {
        return fieldName.slice(0, -2); // Remove the last 2 characters ("Id")
    }
    return fieldName;
};

export const getValue = (data) => {
    return data && typeof data === "object" ? data.value : data;
};

export const extractEmail = (email) => {
    const match = email.match(/<(.+?)>/);
    return match ? match[1] : email; // Fallback to the original string if no match
};



export const renderDocumentViewContent = (selectedDocument, getFileType) => {
    if (selectedDocument && getFileType) {
        if (getFileType === "pdf" || getFileType === "txt") {
            return (
                <div className="pdf-iframe">
                    <iframe className="ifream-doc"
                        src={selectedDocument}
                        title="PDF Preview"
                        style={{ width: "100%", height: "200%" }}
                    />
                </div>
            );
        } else if (getFileType === "jpeg" || getFileType === "jpg") {
            return (
                <div className="pdf-iframe">
                    {/* <iframe
                       
                       className="ifream-doc"
                        src={selectedDocument}
                        title="Image Preview"
                        style={{ width: "100%", height: "100%" }}
                    /> */}
                    <img  src={selectedDocument} />
                </div>
            );
        }
        else {
            return (
                <FileViewer
                    fileType={getFileType}
                    filePath={selectedDocument}
                    onError={(error) => console.error("Error:", error)}
                />
            );
        }
    }
    return null;
};

export const getClassForBadges = (status) => {
    const classMap = {
        Incoterms: { color: "#80bc29" },
        pendingcustomer: { color: "#fe0808" },
        "Payment Terms Not Matching": { color: "#d84444" },
        ponumberiswrong: { color: "#003087" },
        creditlimitcrossed: { color: "#ff9a00" },
        billingaddressmismatch: { color: "#1e87ca" },
        invoiceemailmismatch: { color: "#9e027e" },
        pastdue: { color: "#683bb5" },
        replacement: { color: "#393939" },
        leadtime: { color: "#de481e" },
        stockverification: { color: "#ffc82b" },
        pricemismatch: { color: "#00abe6" },
    };

    return classMap[status] || { color: "#000000" }; // Fallback to black if status is not found
};


export const parseDynamicRequestData = (requestData) => {
    try {
        // First, parse the outer requestData
        let parsedData = JSON.parse(requestData);

        // List of known fields that may contain JSON strings inside them
        const jsonFields = ["ApiRequestData", "Message", "Data"];  // Add any additional fields here

        // Helper function to check if a string is valid JSON
        const isValidJson = (str) => {
            try {
                // If the string is a valid JSON, return parsed JSON
                return JSON.parse(str);
            } catch (e) {
                // If parsing fails, it might contain a valid message with a JSON part
                return parseMessage(str); // Use the `parseMessage` function
            }
        };

        // Recursive function to check and parse JSON strings inside the data
        const convertToJson = (data) => {
            if (typeof data === 'string' && data.trim() !== '') {
                // If the string is a valid JSON, parse it, else leave it as is
                return isValidJson(data);
            } else if (Array.isArray(data)) {
                // Recursively process array elements
                return data.map(item => convertToJson(item));
            } else if (typeof data === 'object' && data !== null) {
                // Recursively process object fields
                const newObj = {};
                for (const key in data) {
                    // Check if the key matches one of the known fields (like ApiRequestData, Message, etc.)
                    if (jsonFields.includes(key) && typeof data[key] === 'string') {
                        // Try to parse the string inside these fields if non-empty
                        newObj[key] = data[key].trim() !== '' ? isValidJson(data[key]) : data[key];
                    } else {
                        // Otherwise, process the field normally
                        newObj[key] = convertToJson(data[key]);
                    }
                }
                return newObj;
            }
            return data;  // For other types, return as is
        };

        // Apply the recursive function to the parsed data
        parsedData = convertToJson(parsedData);

        // Return the fully processed data
        return parsedData;

    } catch (error) {
        console.error("Error parsing the requestData:", error);
        return null;  // Return null or handle the error as needed
    }
};

const parseMessage = (message) => {
    try {
        // Find the position of the actual JSON content (looking for the first '{')
        const jsonStartIndex = message.indexOf("{");

        // If a valid JSON starting point is found, extract the JSON string
        if (jsonStartIndex !== -1) {
            const jsonString = message.substring(jsonStartIndex);

            // Remove any trailing characters (like extra quotes or spaces) that might cause issues
            const cleanedJsonString = jsonString.replace(/['"]*$/, "");

            // Parse the extracted JSON string
            return JSON.parse(cleanedJsonString);
        } else {
            // Return the original message if no JSON content is found
            return message;
        }
    } catch (error) {
        console.error("Error parsing the message:", error);
        return message; // Return the original message if there's an error
    }
};


export const ReplaceEmailBody = (emailBody, requestData, data) => {

    const convertKeysToLowerCase = (obj) => {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
            newObj[key.toLowerCase()] = value;
        });
        return newObj;
    };

    const lowerCasedRequestData = convertKeysToLowerCase(requestData);
    const lowerCasedData = convertKeysToLowerCase(data);

    const replacePlaceholders = (body, data) => {
        Object.entries(data).forEach(([key, value]) => {
            body = body.replaceAll("#" + key + "#", value || "");
        });
        return body;
    };

    const lowerCaseEmailBody = emailBody.replace(/#([a-zA-Z0-9_]+)#/g, (match, data) => {
        return `#${data.toLowerCase()}#`;
    });
    let body = lowerCaseEmailBody;
    body = replacePlaceholders(body, lowerCasedRequestData);
    body = replacePlaceholders(body, lowerCasedData);

    return body;
};


export const PlaceHolderConverter = (data, requestData = "") => {

    const convertKeysToLowerCase = (obj) => {
        const newObj = {};
        Object.entries(obj || {}).forEach(([key, value]) => {
            newObj[key.toLowerCase()] = value ?? ""; // Ensure null/undefined are replaced safely
        });
        return newObj;
    };

    const lowerCasedRequestData = convertKeysToLowerCase(requestData);

    const replacePlaceholders = (body, data) => {
        return body.replace(/#([a-zA-Z0-9_]+)#/g, (match, key) => {
            return data[key.toLowerCase()] ?? match; // Replace if found, else keep original placeholder
        });
    };

    return replacePlaceholders(data, lowerCasedRequestData);
};

export const getFileSize = (file) => {
    if (file.size) {
        return file.size; // If size exists, return it
    }

    if (file.base64Data) {
        // If attachment is Base64, calculate size
        const base64Length = file.base64Data.replace(/^data:.*;base64,/, "").length;
        return (base64Length * (3 / 4)); // Approximate size in bytes
    }

    return 0; // Default if no size info is available
};


export const fieldTypeToInputType = (fieldtype) => {
    switch (fieldtype) {
        case FormFieldTypes.PASSWORD:
            return TextInputType.PASSWORD;
        case FormFieldTypes.NUMERIC:
            return TextInputType.NUMBER;
        case FormFieldTypes.CHECKBOX:
            return TextInputType.CHECKBOX;
        case FormFieldTypes.DATEPICKER:
            return TextInputType.DATEPICKER;
        default:
            return TextInputType.TEXT;
    }
};

export const formatMessage = (message) => {
    const data = message.charAt(0).toUpperCase() + message.slice(1).toLowerCase();
    return data;
};


export const getStatusColor = (status) => {
    const statusLower = status?.toLowerCase();
    if (statusLower?.includes('pass') || statusLower?.includes('success') || statusLower?.includes('approved')) {
        return 'success';
    } else if (statusLower?.includes('fail') || statusLower?.includes('reject') || statusLower?.includes('denied')) {
        return 'error';
    }
    return 'default';
};