/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./CommandPalette.scss";
import { AppIcons } from "../../../data/appIcons";
import Image from "../../../components/image/Image";
import Iconify from "../../../components/ui/iconify/Iconify";
import { encryptUrlData } from "../../../services/CryptoService";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import DataLoader from "../../../components/FinalMolGrid/ui/dataLoader/DataLoader";
import { useLazySearchCommandPaletteByTextQuery } from "../../../app/services/commonAPI";

//** Compoent's */
const AddOrderTabs = React.lazy(() =>
  import("../../../pages/order/AddOrderTabs/addOrderTabs")
);

const CommandPalette = ({ isOpen, onClose }) => {

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const authState = useSelector((state) => state.auth);
  const [customSearch, setCustomSearch] = useState(false);
  const commandList = [
    {
      listTitle: "Customer",
      buttons: [
        {
          icon: "ant-design:user-add-outlined",
          label: "Add Customer",
          onClick: () => { navigate("/customer/create"); onClose(true); },
        },
        {
          icon: "lsicon:user-all-outline",
          label: "View Customer",
          onClick: () => { navigate("/customer/list"); onClose(true); },
        },
      ],
    },
    {
      listTitle: "Supplier",
      buttons: [
        {
          icon: "icon-park-outline:shopping-cart-two",
          label: "Add Supplier",
          onClick: () => { navigate("/supplier/create"); onClose(true); },
        },
        {
          icon: "clarity:shopping-cart-outline-badged",
          label: "View Supplier",
          onClick: () => { navigate("/supplier/list"); onClose(true); },
        },
      ],
    },
    {
      listTitle: "Order",
      buttons: [
        {
          icon: "fluent:clipboard-task-add-20-regular",
          label: "Add Order",
          onClick: () => {
            setshowOrderEntryPopup(true);
          },
        },
        {
          icon: "carbon:task-view",
          label: "View Order",
          onClick: () => { navigate("/order/list"); onClose(true) },
        },
      ],
    },
    {
      listTitle: "Emails",
      buttons: [
        {
          icon: "material-symbols:stacked-email-outline-sharp",
          label: "View Email",
          onClick: () => { navigate("/emailIntent/Quotation"); onClose(true) },
        },
      ],
    },
    {
      listTitle: "Organization",
      buttons: [
        {
          icon: "vaadin:user-card",
          label: "View Profile",
          onClick: () => {
            navigate(`/configuration/usermanagement/edit/${encryptUrlData(
              authState?.user?.userID
            )}`); onClose(true)
          },
        },
        {
          icon: "ant-design:question-circle-outlined",
          label: "View User",
          onClick: () => { navigate("/configuration/usermanagement/list"); onClose(true) }
        },
      ],
    },
  ];
  const [customCommandList, setCustomCommandList] = useState(commandList);
  const [showOrderEntryPopup, setshowOrderEntryPopup] = useState(false);

  const [
    searchCommand,
    {
      isFetching: isSearchCommandLoading,
      isSuccess: isSearchCommandSuccess,
      data: isSearchCommandData,
    },
  ] = useLazySearchCommandPaletteByTextQuery();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        onClose(false); // Open the command palette
      } else if (event.key === "Escape") {
        onClose(true); // Close the command palette
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (
      !isSearchCommandLoading &&
      isSearchCommandSuccess &&
      isSearchCommandData
    ) {
      const groupedData = isSearchCommandData.reduce((acc, item) => {
        if (!acc[item.moduleName]) {
          acc[item.moduleName] = [];
        }
        acc[item.moduleName].push(item);
        return acc;
      }, {});
      setCustomSearch(false);
      setCustomCommandList(commandList);
      setGroupedData(groupedData);
    }
  }, [isSearchCommandLoading, isSearchCommandSuccess && isSearchCommandData]);

  const closeOrderEntryPopup = () => {
    setshowOrderEntryPopup(false);
  };

  const handleSearch = (value) => {
    const keywords = ["add", "view", "vie"];
    const lowerCaseValue = value.toLowerCase();
    if (value.length >= 3) {
      if (
        value !== "" &&
        keywords.some((keyword) => lowerCaseValue.includes(keyword))
      ) {
        const filteredCommands = commandList
          .map((category) => {
            const filteredButtons = category.buttons.filter((button) =>
              button.label.toLowerCase().includes(lowerCaseValue)
            );
            if (
              category.listTitle.toLowerCase().includes(lowerCaseValue) ||
              filteredButtons.length > 0
            ) {
              return {
                ...category,
                buttons: filteredButtons,
              };
            }
            return null;
          })
          .filter(Boolean);
        setGroupedData({});
        setCustomCommandList(filteredCommands);
        setCustomSearch(true);
      } else if (
        value !== "" &&
        !keywords.some((keyword) => lowerCaseValue.includes(keyword))
      ) {
        searchCommand(value);
      }
    } else if (value === "") {
      setCustomCommandList(commandList);
    }
    setSearchTerm(value);
  };

  if (!isOpen) return null;

  const handleOnRedirect = (redirectUrl, keyId) => {
    navigate(`${redirectUrl}/${encryptUrlData(keyId)}`);
    onClose();
  };

  const renderCustomCommond = () => {
    return (
      <>
        {customCommandList.length > 0 ? (
          <div className="main-search-list">
            {customCommandList.map((item, index) => (
              <div key={index} className="command-palette-list-item">
                <div className="command-palette-list-title">
                  {item.listTitle}
                </div>
                <div className="command-palette-list-group">
                  {item.buttons.map((button, idx) => (
                    <button
                      key={idx}
                      className="command-palette-list-btn"
                      onClick={button.onClick}
                    >
                      <Iconify icon={button.icon} />
                      <span>{button.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          renderNoFound()
        )}
      </>
    );
  };

  const renderNoFound = () => {
    return (
      <div className="no-results">
        <div className="no-results-img">
          <Image
            imgCustomClassName="order-icon"
            imagePath={AppIcons.Noresults}
            altText="Arrow Icon"
          />
        </div>
        <div className="no-results-text">No results for "{searchTerm}"</div>
        <div className="other-suggestions">
          <div className="other-title">Try searching for </div>
          <div className="search-list">
            <button className="search-item">
              <Iconify icon="ri:arrow-right-double-fill" width={20} />
              Customers
            </button>
            <button className="search-item">
              <Iconify icon="ri:arrow-right-double-fill" width={20} />
              Suppliers
            </button>
            <button className="search-item">
              <Iconify icon="ri:arrow-right-double-fill" width={20} />
              Orders
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <button className="command-palette-backdrop">
        <div className="command-palette" onClick={(e) => e.stopPropagation()}>
          <div className="command-palette-search">
            <div className="search-input-bar">
              <Iconify icon="mdi:magnify" width={24} />
              <input
                type="text"
                className="command-palette-search-input"
                placeholder="Type a command..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
              />
            </div>
            <button className="search-input-esc" onClick={() => onClose(true)}>
              esc
            </button>
          </div>
          <div className="command-palette-list search-text-list">
            {!customSearch &&
              searchTerm &&
              groupedData &&
              isSearchCommandData ? (
              isSearchCommandLoading ? (
                <DataLoader />
              ) : Object.keys(groupedData).length > 0 ? (
                Object.keys(groupedData).map((moduleName) => (
                  <div key={moduleName} className="module-group">
                    <h3>{moduleName}</h3>
                    <ul>
                      {groupedData[moduleName].map((item, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleOnRedirect(item.redirectUrl, item.keyId)
                          }
                        >
                          <div className="search-resuls">
                            <div className="name-status">
                              <p>{item.keyName}</p>
                              <p
                                className={`status ${item.status.toLowerCase()}`}
                              >
                                {item.status}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                renderNoFound()
              )
            ) : (
              renderCustomCommond()
            )}
          </div>
          <div className="command-palette-footer">
            Search by: <span className="">OMS</span>
          </div>
        </div>
      </button>
      {showOrderEntryPopup && (
        <CenterModel
          showModal={showOrderEntryPopup}
          className="center-model po-model"
          headerClassName="po-model-title"
          handleToggleModal={closeOrderEntryPopup}
          modalTitle="Order Entry Details"
          modelSizeClass="width-98"
        >
          <AddOrderTabs closeOrderEntryPopup={closeOrderEntryPopup} />
        </CenterModel>
      )}
    </>
  );
};

export default CommandPalette;
