import { validationTypes } from "../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";



export const AddEditCustomFormFieldsFormData = {
    initialState: {
        fieldName:"",
        dataField:"",
        controlType:"",
        validationList:"",
        description:"",
        maxLength:"",
        minLength:"",
        isActive:true,
    },
    section: [
        {
            title: "Form Fields Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "fieldName",
                    label: "Field Name ",
                    Field_Name: "Field name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "fieldName",
                    fieldSetting: {
                        placeholder: "Enter Field Name",
                        allowSpace: true,
                        maxLength:255
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                id: "dataField",
                label: "Data Field",
                Field_Name: "Data field",
                fieldType: FormFieldTypes.INPUT,
                dataField: "dataField",
                fieldSetting: {
                    placeholder: "Enter Data Field ",
                    allowSpace: false,
                    maxLength:50
                },
                validation: [{ type: validationTypes.REQUIRE }],
                style: {
                    containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                },
            },
                {
                    id: "controlType",
                    label: "Control Type",
                    Field_Name: "Control type ",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "controlType",
                    fieldSetting: {
                        placeholder: "Select Control Type  ",
                        isMultiSelect: false,
                        maxLength:50
                    },

                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2",
                    },
                },
                {
                    id: "validationList",
                    label: "Validation List",
                    Field_Name: "Validation list",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "validationList",
                    fieldSetting: {
                        placeholder: "Select Validation List",
                        isMultiSelect: true,
                        maxLength:255
                    },

                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2",
                    },
                },
               
                  {
                    id: "maxLength",
                    label: "Max Length",
                    Field_Name: "Max Length",
                    fieldType: FormFieldTypes.NUMERIC,
                    dataField: "maxLength",
                    fieldSetting: {
                        placeholder: "Enter Max Length",
                        allowSpace: true,
                        maxLength:4
                    },
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                    id: "minLength",
                    label: "Min Length",
                    Field_Name: "Min Length",
                    fieldType: FormFieldTypes.NUMERIC,
                    dataField: "minLength",
                    fieldSetting: {
                        placeholder: "Enter Min Length",
                        allowSpace: true,
                        maxLength:4
                    },
                 
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-2 mt-2",
                    },
                },
                {
                    id: "description",
                    label: "Description",
                    Field_Name: "Description",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "description",
                    fieldSetting: {
                      placeholder: "Please enter description",
                      allowSpace: true,
                      maxLength:255,
                    },
                    style: {
                      containerCss: "col-xxl-12 col-xl-12 col-md-12 mb-2",
                    },
                  },

                {
                    id: "isActive",
                    label: "Is Active",
                    Field_Name: "Is Active",
                    fieldType: FormFieldTypes.CHECKBOX,
                    dataField: "isActive",
                    fieldSetting: {
                        placeholder: "",
                        allowSpace: true, 
                    },
                    style: {
                        containerCss: "col-xxl-5 col-xl-5 col-md-6 col-6 col-6 ",
                    },
                }, 
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }

}

export const customFormsFieldsGridConfig = {
    columns: [
        {
            name: "Field Name",
            fieldName: "fieldName",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Data Field",
            fieldName: "dataField",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Control Type",
            fieldName: "controlType",
            colStyle: {
                width: "25%",
            },
            allowShort: true,
        },
        {
            name: "Is Active",
            fieldName: "isActive",
            colStyle: {
                width: "15%",
            },
            colType: GridColumnType.CHECKBOX,
            colSettings: {
              isDisabled: true,
            },
            allowShort: true,
        },
        {
            name: "Action",
            colStyle: {
                width: "10%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: false,
            },

        },
    ],

};