
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const systemTaskConfigurationAPI = createApi({
    reducerPath: "systemTaskConfigurationAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        updateSystemTaskConfiguration: builder.mutation({
            query: (requestData) => ({
                url: '/SystemTaskConfiguration/UpdateSystemTaskConfiguration',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getSystemTaskConfiguration: builder.mutation({
            query: (userQuery) => ({
                url: '/SystemTaskConfiguration/GetSystemTaskConfiguration',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
 

        getSystemTaskConfigurationById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/SystemTaskConfiguration/GetSystemTaskConfigurationById/?id=${Number(id)}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getAllSystemTaskConfiguration: builder.query({
            query: () => ({
                url: encryptQueryString('/SystemTaskConfiguration/GetAllSystemTaskConfiguration'),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getWorkflowActionsAndType: builder.query({
            query: () => ({
                url: encryptQueryString('/Common/GetWorkflowActionsAndType'),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    })
})

export const {
    useGetSystemTaskConfigurationMutation, useUpdateSystemTaskConfigurationMutation, useLazyGetSystemTaskConfigurationByIdQuery,useLazyGetAllSystemTaskConfigurationQuery,useLazyGetWorkflowActionsAndTypeQuery 
} = systemTaskConfigurationAPI

export default systemTaskConfigurationAPI;