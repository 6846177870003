/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Buttons from "../../../../components/ui/button/Buttons";
import CardSection from "../../../../components/ui/card/CardSection";
import SidebarModel from "../../../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../../../data/appIcons";
import UpdateSystemTaskConfiguration from "../UpdateSystemTaskconfiguration";
import SystemTaskConfigurationInfoCard from "./SystemTaskConfigurationInfoCard";
import { useNavigate, useParams } from "react-router-dom";
import { decryptUrlData } from "../../../../services/CryptoService";
import { useLazyGetSystemTaskConfigurationByIdQuery } from "../../../../app/services/systemTaskConfigurationAPI";
import WorkFlow from "./feature/workflow/Workflow";

const SystemTaskConfigurationDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const systemTaskConfigurationId = id ? decryptUrlData(id) : 0;
    const [formData, setFormData] = useState(null);

    const [isModelOpen, setIsModelOpen] = useState(false);
    // const [systemTaskId, setSystemTaskId] = useState(null);
    const [
        getSystemTaskConfigurationById,
        {
            isFetching: isGetSystemTaskConfigurationByIdFetching,
            isSuccess: isGetSystemTaskConfigurationByIdSuccess,
            data: GetSystemTaskConfigurationByIdData,
        },
    ] = useLazyGetSystemTaskConfigurationByIdQuery();

    const onSidebarClose = () => {
        setIsModelOpen(false)
    }

    const handleToggleModal = () => {
        setIsModelOpen(true)
    }

    useEffect(() => {
        getSystemTaskConfigurationById(systemTaskConfigurationId)
    }, [systemTaskConfigurationId])

    useEffect(() => {
        if (
            !isGetSystemTaskConfigurationByIdFetching &&
            isGetSystemTaskConfigurationByIdSuccess &&
            GetSystemTaskConfigurationByIdData
        ) {
            setFormData(GetSystemTaskConfigurationByIdData);
        }
    }, [isGetSystemTaskConfigurationByIdFetching, isGetSystemTaskConfigurationByIdSuccess, GetSystemTaskConfigurationByIdData]);

    const handleBackClick = () => {
        navigate("/configuration/systemTaskConfiguration/list");
    };
   
    return (
        <>
            <div className="card-bottom-m-0">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 basic-left-part customer-desc-left-sec mb-2 mt-2">
                        <CardSection>
                            <SystemTaskConfigurationInfoCard
                                editClick={handleToggleModal}
                                formData={formData}
                            />
                        </CardSection>
                    </div>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-12 other-info-tab">
                        <div className="d-flex justify-content-end mb-2">
                        <Buttons
                            buttonTypeClassName="btn dark-btn"
                            onClick={handleBackClick}
                            textWithIcon={true}
                            buttonText="Back"
                            imagePath={AppIcons.BackArrowIcon}
                        ></Buttons>
                        </div>
                   
                    <div className="customer-detail-tab-sec">
                        <WorkFlow systemTaskConfigurationId={systemTaskConfigurationId} />
                    </div>
                    </div>
                </div>
            </div>

            <SidebarModel
                modalTitle="Update System Task Configuration"
                contentClass="content-40"
                onClose={onSidebarClose}
                modalTitleIcon={AppIcons.AddIcon}
                isOpen={isModelOpen}
            >
                <UpdateSystemTaskConfiguration
                    systemTaskId={systemTaskConfigurationId}
                    onClose={onSidebarClose}
                     onSuccess={true}
                    isModelOpen={isModelOpen}
                />
            </SidebarModel>
        </>)
}
export default SystemTaskConfigurationDetail;