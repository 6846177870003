import { validationTypes } from "../../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../../data/formFieldType";

export const AddEditThirdPartyApiData = {
    // name: "Email From"
    initialState: { apiEventId: 0, eventName: "", description: "" ,eventTitle :"", eventType:""},
    section: [
        {
            title: "ThirdParty API Information Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "eventName",
                    label: "Event Name ",
                    Field_Name: "Event name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "eventName",
                    fieldSetting: {
                        placeholder: "Enter Event Name",
                        allowSpace: true,
                        maxLength: 100,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "eventTitle",
                    label: "Event Title",
                    Field_Name: "Event title",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "eventTitle",
                    fieldSetting: {
                        placeholder: "Please Enter Event Title",
                        maxLength: 100,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
                {
                    id: "eventType",
                    label: "Event Type ",
                    Field_Name: "Event type",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "eventType",
                    fieldSetting: {
                      placeholder: "Select Event Type",
                      isEnableOnChange: true,
                    },
          
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                      containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
                    },
                  },
            
                {
                    id: "description",
                    label: "Description",
                    Field_Name: "Description",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "description",
                    fieldSetting: {
                        placeholder: "Please Enter Description",
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-input",
                    },
                },
               
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }
};