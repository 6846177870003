/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FinalMolGrid from "../../../components/FinalMolGrid/FinalMolGrid";
import { SystemTaskConfigurationGridConfig } from "../config/SystemTaskConfiguration.data";
import { useGetSystemTaskConfigurationMutation } from "../../../app/services/systemTaskConfigurationAPI";

const SystemTaskConfigurationList=({handleEditClick,getDataRef})=>{

    const molGridRef=useRef();
    const [listData, setListData] = useState();
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [getSystemTaskConfiguration, { isLoading: isGetSystemTaskConfigurationLoading, isSuccess: isGetSystemTaskConfigurationSuccess, data: isGetSystemTaskConfigurationeData },] = useGetSystemTaskConfigurationMutation();

    useEffect(() => {
        onGetData()
      }, []);
    
      useEffect(() => {
        if (isGetSystemTaskConfigurationSuccess && isGetSystemTaskConfigurationeData) {
          
          if (isGetSystemTaskConfigurationeData) {
            setListData(isGetSystemTaskConfigurationeData.dataSource);
          }
          if (isGetSystemTaskConfigurationeData.totalRecord) {
            setTotalRowCount(isGetSystemTaskConfigurationeData.totalRecord);
          }
        }
      }, [isGetSystemTaskConfigurationSuccess, isGetSystemTaskConfigurationeData]);

      const getLists = (pageObject, sortingString) => {

        const request = {
          pagination: {
            pageNumber: pageObject.pageNumber,
            pageSize: pageObject.pageSize,
          },
          filters: { searchText: "" },
          sortString: sortingString,
      
        };
        getSystemTaskConfiguration(request);
      };
    
      const handlePageChange = (page) => {
        getLists(page, molGridRef.current.generateSortingString());
      };
    
      const handleSorting = (shortString) => {
        getLists(molGridRef.current.getCurrentPageObject(), shortString);
      }
      const onGetData = () => {
    
        if (molGridRef.current) {
          const defaultPageObject = molGridRef.current.getCurrentPageObject();
          getLists(defaultPageObject, molGridRef.current.generateSortingString());
        }
      }

      useImperativeHandle(getDataRef, () => ({
        callChildFunction: onGetData
      }));
      const actionHandler = {
        EDIT: handleEditClick,
      
      };
    
    return( <div className="row">
        <div className="col-md-12 table-striped ">
          <FinalMolGrid
            ref={molGridRef}
             configuration={SystemTaskConfigurationGridConfig}
            dataSource={listData}
            allowPagination={true}
            pagination={{
              totalCount: totalRowCount,
              pageSize: 25,
              currentPage: 1,
            }}
            onPageChange={handlePageChange}
            onSorting={handleSorting}
            isLoading={isGetSystemTaskConfigurationLoading}
            onActionChange={actionHandler}
          />
        </div>
      </div>)
}

export default SystemTaskConfigurationList;