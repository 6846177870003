import { validationTypes } from "../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../components/FinalForms/libs/data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";

export const AddEditCustomFormButtonsFormData = {
    initialState: {
        buttonName:"",
        buttonDescription:"",
        eventAction:"",
        parameterJson:"",
    },
    section: [
        {
            title: "Form Fields Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "buttonName",
                    label: "Button Name ",
                    Field_Name: "Button name",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "buttonName",
                    fieldSetting: {
                        placeholder: "Enter Button Name",
                        allowSpace: true,
                        maxLength: 50,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
                {
                    id: "eventAction",
                    label: "Event Action",
                    Field_Name: "Event action",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "eventAction",
                    fieldSetting: {
                        placeholder: "Enter Event Action ",
                        allowSpace: true,
                        maxLength: 50,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
                {
                    id: "parameterJson",
                    label: "Parameter Json",
                    Field_Name: "Parameter json",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "parameterJson",
                    fieldSetting: {
                        placeholder: "Enter Parameter Json ",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE },{ type: validationTypes.VALIDATEJSON }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
                {
                    id: "buttonDescription",
                    label: "Button Description",
                    Field_Name: "Button description",
                    fieldType: FormFieldTypes.TEXTAREA,
                    dataField: "buttonDescription",
                    fieldSetting: {
                        placeholder: "Enter Button Description ",
                        allowSpace: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
                    },
                },
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }
}

export const customFormsButtonsGridConfig = {
    columns: [
        {
            name: "Button Name",
            fieldName: "buttonName",
            colStyle: {
                width: "45%",
            },
            allowShort: true,
        },
        {
            name: "Event Action",
            fieldName: "eventAction",
            colStyle: {
                width: "45%",
            },
            allowShort: true,
        },
        {
            name: "Action",
            colStyle: {
                width: "10%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: false,
            },
        },
    ],

};