import { validationTypes } from "../../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../../data/formFieldType";

export const AdvancedEmailSettingsFormData = {
  initialState: {},
  section: [
    {
      title: "User Information Section",
      row: {},
      style: {
        sectionStyle: "row mb-3",
      },
      fields: [
        {
          id: "from",
          label: "From ",
          Field_Name: "From",
          fieldType: FormFieldTypes.INPUT,
          dataField: "from",
          fieldSetting: {
            placeholder: "Enter From Email",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 ",
          },
        },
        {
          id: "to",
          label: "To ",
          Field_Name: "To",
          fieldType: FormFieldTypes.INPUT,
          dataField: "to",
          fieldSetting: {
            placeholder: "Enter To Email",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2",
          },
        },
        {
          id: "subject",
          label: "Subject ",
          Field_Name: "Subject",
          fieldType: FormFieldTypes.INPUT,
          dataField: "subject",
          fieldSetting: {
            placeholder: "Enter Email Subject",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
          },
        },
        {
          id: "includes",
          label: "Includes the words ",
          Field_Name: "Includes",
          fieldType: FormFieldTypes.INPUT,
          dataField: "Includes",
          fieldSetting: {
            placeholder: "Enter included words",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
          },
        },
        {
          id: "doesnt",
          label: "Does'nt have",
          Field_Name: "Does'nt",
          fieldType: FormFieldTypes.INPUT,
          dataField: "doesnt",
          fieldSetting: {
            placeholder: "Enter words that shouldn't be included",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
          },
        },
        {
          id: "size",
          label: "Size",
          Field_Name: "Size",
          fieldType: FormFieldTypes.SELECT,
          dataField: "primaryUserId",
          fieldSetting: {
            placeholder: "Select Size",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-4 col-xl-4 col-md-4 col-12 mb-2 mt-2 mt-2",
          },
        },
        {
          id: "sizeinput",
          label: "nbsp",
          Field_Name: "Size input",
          fieldType: FormFieldTypes.INPUT,
          dataField: "sizeinput",
          fieldSetting: {
            placeholder: "",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss:
              "col-xxl-4 col-xl-4 col-md-4 col-12 mb-2 mt-2 remove-label",
          },
        },
        {
          id: "size",
          label: "Size",
          Field_Name: "Size",
          fieldType: FormFieldTypes.SELECT,
          dataField: "primaryUserId",
          fieldSetting: {
            placeholder: "Select Size",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss:
              "col-xxl-4 col-xl-4 col-md-4 col-12 mb-2 mt-2 remove-label",
          },
        },
        {
          id: "date",
          label: "Date within",
          Field_Name: "Date",
          fieldType: FormFieldTypes.SELECT,
          dataField: "date",
          fieldSetting: {
            placeholder: "Select Date",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2 mt-2",
          },
        },
        {
          id: "datepicker",
          label: "datepicker",
          Field_Name: "Date",
          fieldType: FormFieldTypes.DATEPICKER,
          dataField: "tokenExpires",
          fieldSetting: {
            placeholder: "Select Date",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-2 remove-label",
          },
        },
        {
          id: "searchfield",
          label: "Search",
          Field_Name: "Search",
          fieldType: FormFieldTypes.SELECT,
          dataField: "search",
          fieldSetting: {
            placeholder: "Select ",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss:
              "col-xxl-12 col-xl-12 col-md-12 col-12 mb-2 mt-2",
          },
        },
        {
            id: "hasAttachments",
            label: "Has Attachment",
            Field_Name: "HasAttachments",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "hasAttachments",
            style: {
              containerCss: "col-xxl-4 col-xl-6 col-md-12 col-12 col-12 mt-2 mb-input",
            },
          },
          {
            id: "dontincludechats",
            label: "Don't include Chats",
            Field_Name: "Dontincludechats",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "dontincludechats",
            style: {
              containerCss: "col-xxl-4 col-xl-6 col-md-12 col-12 col-12 mt-2 mb-input",
            },
          },
      ],
    },
  ],
};
