import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIUrl } from "../API/fetchBaseQuery";
import { transformHistoryRequest } from "../API/requestMiddleware";


export const logUserLoginLogoutHistory = createAsyncThunk('auth/logUserLoginLogoutHistory',
    async ({ userId, isLogin }, { rejectWithValue }) => {
        try {
            // Transform the request data
            const request = {
                userId: userId,
                isLogin: isLogin
            }
            const requestData = transformHistoryRequest(request);

            // Make the POST request with transformed data
            const response = await axios.post(`${APIUrl}Authentication/AddUserLoginLogoutHistory`, requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data || error.message);
        }
    }
);