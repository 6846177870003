import PropTypes from "prop-types";
import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";

const AccountingEmails=()=>{
    return(   <EmailManagement emailIntentId={EmailInboxTypesEnum.ACCOUNTINGRELATED} intentName="Accounting Emails" />)
}

EmailManagement.propTypes = {
    emailIntentId: PropTypes.oneOf(Object.values(EmailInboxTypesEnum)).isRequired,
    intentName: PropTypes.string.isRequired, 
  };
export default AccountingEmails;