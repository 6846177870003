import React from "react";
import PropTypes from "prop-types";
import SearchBar from "../../../common/features/component/SearchBar";
import Buttons from "../button/Buttons";
import Filter from "../../filter/Filter";
import DropDown from "../dropdown/DropDrown";
import Shorting from "../../shorting/Shorting";
import GridToggleButton from "../../FinalMolGrid/ui/toggleButton/GridToggleButton";

function CardSection({
  children,
  cardTitle,
  cardSubTitle,
  rightButton,
  buttonClassName,
  titleButtonClick,
  buttonText,
  buttonTextBack,
  searchInput,
  searchInputName,
  textWithIcon,
  textWithIconBack,
  iconImg,
  iconImgBack,
  handleChange,
  isLoading,
  titleText,
  isFilter,
  filterHeaderTitle,
  // isButtonVisible
  optionsValue,
  handleChangeDropdown,
  searchFilter,
  selectedOptions,
  isMultiSelect,
  isCardSection,
  isdropdownOpen,
  placeholder,
  clearButton,
  clearTitleButtonClick,
  clearButtonText,
  searchTitleButtonClick,
  searchButton,
  searchbuttonText,
  clearButtonClassName,
  searchIconImg,
  searchTextWithIcon,
  clearTextWithIcon,
  clearIconImg,
  searchValue,
  multipleButton,
  rightButtonArray,
  isIcon,
  iconClass,
  isShort,
  selectedSortOrder,
  filtersOptions,
  selectedFilterOptions,
  handleKeyPress,
  isCenterTile,
  CenterTitleTxt,
  CenterBtnIcon,
  centerBtnTitle,
  centerBtnOnClick,
  isTooltip,
  tootipText,
  headerContent,
  className = " ",
  isShowToggle, isChecked, handleToggle, togglelabel
}) {
  return (
    <div
      className={`card ${className}${cardTitle ? "card-section-left" : ""}${searchInput && rightButton ? " card-section-between" : ""
        }${rightButton ? " card-button-only" : ""}`}
    >
      {(cardTitle || rightButton || searchFilter || searchInput) && (
        <div className="card-top-title-btn responsive-grid-title">
          {cardTitle && (
            <div className="section-title mr-3">
              <h4>{cardTitle}</h4>
              <p>{cardSubTitle}</p>
            </div>
          )}
          {headerContent && (
            <div className="card-header-content">{headerContent}</div>
          )}
          <div className="manage-customer-dropdown">
            {searchInput && (
              <div className="col-md-4">
                <div className="searchbar-sec">
                  <SearchBar
                    searchValue={searchValue}
                    searchText={searchInputName}
                    handleChange={handleChange}
                    handleKeyPress={handleKeyPress}
                  />
                </div>
              </div>
            )}
            {searchFilter && (
              <div className="col-md-4">
                <div className="ml-2">
                  <DropDown
                    value={selectedOptions}
                    options={optionsValue}
                    isMultiSelect={isMultiSelect}
                    onChange={handleChangeDropdown}
                    isCardSection={isCardSection}
                    isdropdownOpen={isdropdownOpen}
                    placeholder={placeholder}
                  />
                </div>
              </div>
            )}

            {searchButton && (

              <div className="btn-right-sec card-title-btn-part">
                {/* Button to open the Add Craft modal */}
                <Buttons
                  onClick={searchTitleButtonClick}
                  buttonText={searchbuttonText}
                  buttonTypeClassName={buttonClassName}
                  textWithIcon={searchTextWithIcon}
                  imagePath={searchIconImg}
                />
              </div>

            )}
            {clearButton && (

              <div className="btn-right-sec card-title-btn-part">
                {/* Button to open the Add Craft modal */}
                <Buttons
                  onClick={clearTitleButtonClick}
                  buttonText={clearButtonText}
                  buttonTypeClassName={clearButtonClassName}
                  textWithIcon={clearTextWithIcon}
                  imagePath={clearIconImg}
                />
              </div>

            )}
            {isCenterTile && (

              <div className="center-title-text card-title-btn-center-part">
                <h4>{CenterTitleTxt}</h4>
                {CenterBtnIcon && (
                  <Buttons
                    isIcon={isIcon}
                    iconClass={CenterBtnIcon}
                    titleText={centerBtnTitle}
                    onClick={centerBtnOnClick}
                    isTooltip={isTooltip}
                    tootipText={tootipText}
                  />
                )}
              </div>

            )}
          </div>

          {
            // isButtonVisible &&(
            rightButton && (
              <div className="btn-right-sec">
                {isFilter ? (

                  <Filter
                    headerTitle={filterHeaderTitle}
                    placeholder="Select Filter"
                  />

                ) : (
                  ""
                )}
                {isShort ? (

                  <Shorting
                    selectedSortOrder={selectedSortOrder}
                    filtersOptions={filtersOptions}
                    selectedFilterOptions={selectedFilterOptions}
                  />

                ) : null}
                {multipleButton && (
                  <div className="btn-right-sec card-title-btn-multi-part">
                    {rightButtonArray.map((button, index) => (
                      <Buttons
                        key={index}
                        buttonTypeClassName={button.buttonTypeClassName}
                        onClick={button.onClick}
                        buttonText={button.buttonText}
                        buttonTextBack={buttonTextBack}
                        textWithIcon={button.textWithIcon}
                        textWithIconBack={textWithIconBack}
                        imagePath={button.imagePath}
                        imagePathBack={iconImgBack}
                        isLoading={isLoading}
                        titleText={titleText}
                        isIcon={button.isIcon}
                        iconClass={button.iconClass}
                        isTooltip={button.isTooltip}
                        tootipText={button.tootipText}
                      />
                    ))}
                  </div>
                )}
                {
                  isShowToggle && (
                    <div className="toggle-button">
                      <span className="toggle-label">{togglelabel}</span>
                      <GridToggleButton
                        value="check"
                        onChange={handleToggle}
                        isChecked={isChecked}
                      ></GridToggleButton>
                    </div>
                  )
                }
                <div className="card-title-btn-right-part last-btn">
                  <Buttons
                    buttonTypeClassName={buttonClassName}
                    onClick={titleButtonClick}
                    buttonText={buttonText}
                    buttonTextBack={buttonTextBack}
                    textWithIcon={textWithIcon}
                    textWithIconBack={textWithIconBack}
                    imagePath={iconImg}
                    imagePathBack={iconImgBack}
                    isLoading={isLoading}
                    titleText={titleText}
                    isIcon={isIcon}
                    iconClass={iconClass}
                    isTooltip={isTooltip}
                    tootipText={tootipText}
                  />
                </div>
              </div>
            )
            // )
          }
        </div>
      )}
      <div className="card-body-sec">{children}</div>
    </div>
  );
}

CardSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  cardTitle: PropTypes.string,
  cardSubTitle: PropTypes.string,
  rightButton: PropTypes.bool,
  buttonClassName: PropTypes.string,
  titleButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonTextBack: PropTypes.string,
  searchInput: PropTypes.bool,
  searchInputName: PropTypes.string,
  textWithIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  textWithIconBack: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  iconImg: PropTypes.string,
  iconImgBack: PropTypes.string,
  handleChange: PropTypes.func,
  isLoading: PropTypes.bool,
  titleText: PropTypes.string,
  isFilter: PropTypes.bool,
  filterHeaderTitle: PropTypes.string,
  optionsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleChangeDropdown: PropTypes.func,
  searchFilter: PropTypes.bool,
  selectedOptions: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  isCardSection: PropTypes.bool,
  isdropdownOpen: PropTypes.bool,
  placeholder: PropTypes.string,
  clearButton: PropTypes.bool,
  clearTitleButtonClick: PropTypes.func,
  clearButtonText: PropTypes.string,
  searchTitleButtonClick: PropTypes.func,
  searchButton: PropTypes.bool,
  searchbuttonText: PropTypes.string,
  clearButtonClassName: PropTypes.string,
  searchIconImg: PropTypes.string,
  searchTextWithIcon: PropTypes.bool,
  clearTextWithIcon: PropTypes.bool,
  clearIconImg: PropTypes.string,
  searchValue: PropTypes.string,
  multipleButton: PropTypes.bool,
  rightButtonArray: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTypeClassName: PropTypes.string,
      onClick: PropTypes.func,
      buttonText: PropTypes.string,
      textWithIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      imagePath: PropTypes.string,
    })
  ),
  headerContent: PropTypes.node,
  isIcon: PropTypes.bool,
  iconClass: PropTypes.string,
  isShort: PropTypes.bool,
  selectedSortOrder: PropTypes.string,
  filtersOptions: PropTypes.array,
  selectedFilterOptions: PropTypes.array,
  handleKeyPress: PropTypes.func,
  isCenterTile: PropTypes.bool,
  CenterTitleTxt: PropTypes.string,
  CenterBtnIcon: PropTypes.string,
  centerBtnTitle: PropTypes.string,
  centerBtnOnClick: PropTypes.func,
  isTooltip: PropTypes.bool,
  tootipText: PropTypes.string,

};

export default CardSection;
