
import SystemTaskOrderItemList from "./SystemTaskOrderItemList";

const PriceMismatch = ({  orderItemDetail }) => {

    return (

        <div className="mb-3">
            <div className="row mb-2">
                <SystemTaskOrderItemList orderItemDetail={orderItemDetail} />
            </div>
        </div>
    )
}
export default PriceMismatch;