import { createApi } from "@reduxjs/toolkit/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';

const systemConstraintsAPI = createApi({
    reducerPath: 'systemConstraintsAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addEditSystemConstraints: builder.mutation({
            query: (data) => ({
                url: '/SystemConstraints/AddEditSystemConstraints',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getSystemConstraints: builder.mutation({
            query: (userQuery) => ({
                url: '/SystemConstraints/GetSystemConstraints',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getSystemConstraintsById: builder.query({
            query: (systemConstraintsId) => ({
                url: encryptQueryString(`/SystemConstraints/GetSystemConstraintsById/?SystemConstraintsId=${Number(systemConstraintsId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteSystemConstraintsById: builder.mutation({
            query: (systemConstraintsId) => ({
                url: encryptQueryString(`/SystemConstraints/DeleteSystemConstraintsById/?SystemConstraintsId=${systemConstraintsId}`),
                method: 'DELETE',
                body: transformRequest(systemConstraintsId)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getAllSystemConstraints: builder.query({
            query: () => ({
                url: '/SystemConstraints/GetAllSystemConstraints',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        updateSystemConfigurable: builder.mutation({
            query: (data) => ({
                url: '/SystemConstraints/UpdateSystemConfigurable',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    })
})

export const { useAddEditSystemConstraintsMutation, useGetSystemConstraintsMutation,
    useLazyGetSystemConstraintsByIdQuery,useDeleteSystemConstraintsByIdMutation,
    useLazyGetAllSystemConstraintsQuery,useUpdateSystemConfigurableMutation
} = systemConstraintsAPI;

export default systemConstraintsAPI;