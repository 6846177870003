/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FormCreator from "../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../components/ui/button/Buttons";
import { AddEditSystemTaskCommentsFormData } from "../config/AddEditSystemTaskComment.data";
import { removeFormFields } from "../../../../utils/FormFields/RemoveFields/handleRemoveFields";
import { useUpdateSystemTaskBadgeStatusByIdMutation } from "../../../../app/services/systemTaskAPI";
import ToastService from "../../../../services/toastService/ToastService";
import PropTypes from 'prop-types';
import { onResetForm } from "../../../../utils/FormFields/ResetForm/handleResetForm";

const AddEditSystemTaskComments = forwardRef(({ btnType, onClose, onSystemTaskDetailRefresh, systemTaskBadgeId, systemTaskId,
    onCommentRefresh, onBadgeRefresh, orderSubStatusId, orderId, isOpen }, ref) => {
    const formRef = useRef();

    const [formData, setFormData] = useState(AddEditSystemTaskCommentsFormData);
    const [updateSystemTaskBadgeStatusById, { isLoading: isUpdateSystemTaskBadgeStatusByIdLoading, isSuccess: isUpdateSystemTaskBadgeStatusByIdSuccess, data: isUpdateSystemTaskBadgeStatusByIdData, },] = useUpdateSystemTaskBadgeStatusByIdMutation();

    useEffect(() => {
        if (isOpen && btnType === "Pass") {
            const modifyFormFields = removeFormFields(AddEditSystemTaskCommentsFormData, ["customerComments"]);
            onResetForm(modifyFormFields, setFormData, modifyFormFields.initialState);
        } else if (isOpen && btnType === "Fail") {
            onResetForm(AddEditSystemTaskCommentsFormData, setFormData, AddEditSystemTaskCommentsFormData.initialState);
        }
    }, [isOpen, btnType]);

    const handleSaveClick = () => {
        const commentFormData = formRef.current.getFormData();
        if (commentFormData) {
            const request = {
                systemTaskBadgeId: systemTaskBadgeId,
                badgeStatus: btnType,
                internalComments: commentFormData?.internalComments,
                customerComments: commentFormData?.customerComments || null,
                systemTaskId: systemTaskId,
                orderSubStatusId: orderSubStatusId,
                orderId: orderId
            }
            updateSystemTaskBadgeStatusById(request)
        }
    }

    const handleSave = (data) => {
        if (data) {
            const request = {
                systemTaskBadgeId: data?.systemTaskDetails?.systemTaskBadgeId,
                badgeStatus: "Pass",
                internalComments: "System passed this badge",
                systemTaskId: data?.systemTaskDetails?.systemTaskId,
                orderSubStatusId: data?.systemTaskDetails?.orderSubStatusId,
                orderId: data?.orderId
            }
            updateSystemTaskBadgeStatusById(request)
        }
    }

    useImperativeHandle(ref, () => ({
        handleSave
    }))

    useEffect(() => {
        if (isUpdateSystemTaskBadgeStatusByIdSuccess && isUpdateSystemTaskBadgeStatusByIdData) {
            if (isUpdateSystemTaskBadgeStatusByIdData.keyValue === 0) {
                ToastService.warning(isUpdateSystemTaskBadgeStatusByIdData.errorMessage);
                return;
            }
            ToastService.success(isUpdateSystemTaskBadgeStatusByIdData.errorMessage);
            onClose();
            onCommentRefresh();
            onBadgeRefresh();
            onSystemTaskDetailRefresh();
        }
    }, [isUpdateSystemTaskBadgeStatusByIdSuccess, isUpdateSystemTaskBadgeStatusByIdData])


    return (
        <div className="p-3">
            <div className="col-12">
                <FormCreator config={formData} ref={formRef} />
            </div>
            <div className="d-flex justify-content-end mt-3">
                <Buttons
                    buttonTypeClassName="theme-button"
                    buttonText="Save"
                    onClick={handleSaveClick}
                    textWithIcon={false}
                    isLoading={isUpdateSystemTaskBadgeStatusByIdLoading}
                ></Buttons>
                <Buttons
                    buttonTypeClassName="dark-btn ml-5"
                    buttonText="Cancel"
                    onClick={onClose}
                    textWithIcon={false}
                ></Buttons>
            </div>
        </div>
    )
});

AddEditSystemTaskComments.propTypes = {
    btnType: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSystemTaskDetailRefresh: PropTypes.func.isRequired,
    systemTaskBadgeId: PropTypes.number.isRequired,
    systemTaskId: PropTypes.number.isRequired,
    onCommentRefresh: PropTypes.func.isRequired,
    onBadgeRefresh: PropTypes.func.isRequired,
    orderSubStatusId: PropTypes.number.isRequired,
    orderId: PropTypes.number.isRequired
};

export default AddEditSystemTaskComments;