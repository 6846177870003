/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useGetSystemTaskListMutation } from "../../app/services/systemTaskAPI";
import CardSection from "../../components/ui/card/CardSection";
import RenderTabs from "../../components/ui/tabs/RenderTabs";
import SystemArchiveTask from "./feature/SystemArchiveTask";
import SystemOpenTask from "./feature/SystemOpenTask";
import SystemTasksDetails from "./feature/SystemTasksDetails";
import "./SystemTask.scss";
import { useSelector } from "react-redux";


const SystemTasks = () => {
    const badgeRef=useRef();
    const [tabId, setTabId] = useState(null);
    const [selectedId, setSelectedId] = useState(null)
    const authState = useSelector((state) => state.auth);
    const [customerId, setCustomerId] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [selectedBadgeId, setSelectedBadgeId] = useState(null)
    const [getSystemTaskList, { isLoading,isFetching: isGetSystemTaskListFetching, isSuccess: isGetSystemTaskListSucess, data: allGetSystemTaskListData }] = useGetSystemTaskListMutation();
    
    const storedsystemTaskId = Number(localStorage.getItem("setSelectedId"));
    const storedOrderId = Number(localStorage.getItem("orderId"));
  
    useEffect(() => {
        if (tabId === null) {
            setTabId(0)
        } else {
            let request = {
                loginUserId: authState?.user?.userID,
                isOpen: tabId === 0 ? true : false,
            }
            getSystemTaskList(request)      
        }
    }, [tabId, getSystemTaskList])


    useEffect(() => {
        if (!isGetSystemTaskListFetching && isGetSystemTaskListSucess && allGetSystemTaskListData) {
            setOrderList(allGetSystemTaskListData);
            if (allGetSystemTaskListData) {
                if ( storedsystemTaskId) {
                    setSelectedId(Number(storedsystemTaskId));
                    localStorage.removeItem("setSelectedId");
                }else{
                setSelectedId(allGetSystemTaskListData[0]?.systemTaskId);
            }
                setCustomerId(allGetSystemTaskListData[0]?.customerId);
                if(storedOrderId){
                    setOrderId(Number(storedOrderId));
                    localStorage.removeItem("orderId");
                }else{
                    setOrderId(allGetSystemTaskListData[0]?.orderId);
                }
            }
        }
    }, [isGetSystemTaskListFetching,isGetSystemTaskListSucess, allGetSystemTaskListData]);


    const handleSetTab = (data) => {

        setTabId(data)
        if (data === 1) {
            setSelectedId(null)  
        }
      
    }

    const onBadgeRefresh=()=>{
        if (badgeRef.current) {
            badgeRef.current.callChildFunction();
          }
    }
    const mainTabs = [
        {
            sMenuItemCaption: "Open",
            component: (
                <SystemOpenTask isLoading={isLoading} orderList={orderList} badgeRef={badgeRef} setCustomerId={setCustomerId} setOrderId={setOrderId} 
                // selectedId={selectedId} 
                selectedId={storedsystemTaskId || selectedId} 
                selectedBadgeId={selectedBadgeId} setSelectedId={setSelectedId} setSelectedBadgeId={setSelectedBadgeId} isGetSystemTaskListFetching={isGetSystemTaskListFetching} />
            ),
        },
        {
            sMenuItemCaption: "Archive",
            component: (
                <SystemArchiveTask isLoading={isLoading} orderList={orderList} badgeRef={badgeRef} setCustomerId={setCustomerId} setOrderId={setOrderId} selectedBadgeId={selectedBadgeId} selectedId={selectedId} setSelectedId={setSelectedId} setSelectedBadgeId={setSelectedBadgeId} isGetSystemTaskListFetching={isGetSystemTaskListFetching} />
            ),
        },
    ];
    return (<div className="mytask-section">
        <div className="row">
            {/* {!isGetSystemTaskListFetching ? (
                <> */}
                    {/* {hasAccess ? ( */}
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-12 task-tab">
                        <div className="task-title tab-section-desc">
                            <div className="filter-model-sec main-customer-grid">
                                <CardSection>
                                    <RenderTabs tabs={mainTabs} onTabClick={handleSetTab} />
                                </CardSection>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                        <div className="right-desc">
                            <CardSection>
                                <SystemTasksDetails
                                    selectedBadgeId={selectedBadgeId}
                                    orderId={orderId}
                                    customerId={customerId}
                                    tabId={tabId}
                                    onBadgeRefresh={onBadgeRefresh}
                                    orderList={orderList}
                                />
                            </CardSection>
                        </div>
                    </div>
                    {/* ) : (<UnAuthorizeApprovalRule approvalRoleList={approvalRole} />) */}
                {/* </>
            ) : (
                <DataLoader />
            )} */}
        </div>

    </div >)

}
export default SystemTasks;