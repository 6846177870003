import { validationTypes } from "../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";

export const SystemConstraintsFormData = {
  initialState: {
    key: "",
    dataType: "",
    moduleId : "",
    isActive : false
  },
  section: [
    {
      title: "User Information Section",
      row: {},
      style: {
        sectionStyle: "row mb-3",
      },
      fields: [
        {
          id: "key",
          label: "Key ",
          Field_Name: "Key",
          fieldType: FormFieldTypes.INPUT,
          dataField: "key",
          fieldSetting: {
            placeholder: "Enter Key",
            allowSpace: true,
            maxLength: 255,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2 mt-2 ",
          },
        },
          {
            id: "dataType",
            label: "Data Type",
            Field_Name: "Data type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "dataType",  
            fieldSetting: {
              placeholder: "Select DataType",
              allowSpace: true,
            },
            validation: [{ type: validationTypes.REQUIRE }],
            style: {
              containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2 mt-2",
            },
          },
          {
            id: "moduleId",
            label: "Module ",
            Field_Name: "Module",
            fieldType: FormFieldTypes.SELECT,
            dataField: "moduleId",
            fieldSetting: {
                placeholder: "Select Module",
                isEnableOnChange: true
            },
            validation: [{ type: validationTypes.REQUIRE }],
            style: {
                containerCss: "col-xxl-6 col-xl-12 col-md-12 col-12 mb-2 mt-2",
            },
        },
        {
          id: "isActive",
          label: "Is Active",
          Field_Name: "Is Active",
          fieldType: FormFieldTypes.CHECKBOX,
          dataField: "isActive",
          fieldSetting: {
            placeholder: "",
            allowSpace: true,
          },
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-input mt-3  margin-top-checkbox",
          },
        },
           
      ]
    }
  ]
}
export const SystemConstraintsGridConfig = {
  columns: [
    {
      name: "Key",
      fieldName: "key",
      colStyle: {
        width: "25%",
      },
      allowShort: true,
    },
    {
        name: "Data Type",
        fieldName: "dataType",
        colStyle: {
          width: "25%",
        },
        allowShort: true,
      },
      {
        name: "Module Name",
        fieldName: "moduleName",
        colStyle: {
          width: "25%",
        },
        allowShort: true,
      },
      {
        name: "Is Active",
        fieldName: "isActive",
        colStyle: {
          width: "15%",
        },
        colType: GridColumnType.CHECKBOX,
        colSettings: {
        isDisabled: true,
      },
      },
    {
      name: "Action",
      colStyle: {
        width: "10%",
      },
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,
        allowDelete: true,
      },
    },
  ],

};