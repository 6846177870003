import { validationTypes } from "../../../../../../../../components/FinalForms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../../../../data/gridColumnType";

export const AddEditParameterMappingData = {
    // name: "Email From"
    initialState: { eventParameterId: "", providerParameterId: "" },
    section: [
        {
            title: "Parameter Mapping Information Section",
            row: {},
            style: {
                sectionStyle: "row mb-3",
            },
            fields: [
                {
                    id: "eventParameterId",
                    label: "Event Parameters",
                    Field_Name: "Eventparameterid",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "eventParameterId",
                    fieldSetting: {
                        placeholder: "Select Event Parameters",
                        isEnableOnChange: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-input",
                    },
                },
                {
                    id: "providerParameterId",
                    label: "Provider Parameters",
                    Field_Name: "Providerparameterid",
                    fieldType: FormFieldTypes.SELECT,
                    dataField: "providerParameterId",
                    fieldSetting: {
                        placeholder: "Select Provider Parameters",
                        isEnableOnChange: true,
                    },
                    validation: [{ type: validationTypes.REQUIRE }],
                    style: {
                        containerCss: "col-xxl-6 col-xl-6 col-md-6 col-6 mb-input",
                    },
                },
            ]
        }
    ],
    formSetting: {
        isViewOnly: false
    }
};

export const AddEditParameterMappingConfigurationData = {
    columns: [
        {
            name: "Event Parameter",
            fieldName: "eventParameterName",
            colStyle: {
                width: "35%",
            },
            allowShort: true,
            colType: GridColumnType.CUSTOM,
            renderCustomCol: (rowData) => {
                return (
                    <div className={!rowData.isDataTypeSame && 'chip-red '}>
                        {rowData?.eventParameterName ? `${rowData.eventParameterName}` : 'N/A'}
                    </div>
                );
            },
        },
        {
            name: "Event DataType",
            fieldName: "eventDataType",
            colStyle: {
                width: "20%",
            },
            allowShort: true,
            colType: GridColumnType.CUSTOM,
            renderCustomCol: (rowData) => {
                return (
                    <div className={!rowData.isDataTypeSame ? 'chip-red' : ''}>
                        {rowData?.eventDataType}
                        {rowData?.eventJsonType ? `(${rowData.eventJsonType})` : ''}
                    </div>
                );
            },
        },
        {
            name: "Provider Parameter",
            fieldName: "providerParameterName",
            colStyle: {
                width: "35%",
            },
            allowShort: true,
            colType: GridColumnType.CUSTOM,
            renderCustomCol: (rowData) => {
                return (
                    <div className={!rowData.isDataTypeSame && 'chip-red '}>
                        {rowData?.providerParameterName ? `${rowData.providerParameterName}` : 'N/A'}
                    </div>
                );
            },
        },
        {
            name: "Provider DataType",
            fieldName: "providerDataType",
            colStyle: {
                width: "20%",
            },
            allowShort: true,
            colType: GridColumnType.CUSTOM,
            renderCustomCol: (rowData) => {
                return (
                    <div className={!rowData.isDataTypeSame ? 'chip-red' : ''}>
                        {rowData?.providerDataType}
                        {rowData?.providerJsonType ? `(${rowData.providerJsonType})` : ''}
                    </div>
                );
            },
        },
        {
            name: "Action",
            colStyle: {
                width: "20%",
            },
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowDelete: true,
            },
        },
    ],

};