/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import FormCreator from "../../../components/FinalForms/FormCreator"
import Buttons from "../../../components/ui/button/Buttons"
import { SystemTaskConfigurationFormData } from "../config/SystemTaskConfiguration.data";
import { useLazyGetSystemTaskConfigurationByIdQuery, useLazyGetWorkflowActionsAndTypeQuery, useUpdateSystemTaskConfigurationMutation } from "../../../app/services/systemTaskConfigurationAPI";
import { useLazyGetAllUserListQuery } from "../../../app/services/commonAPI";
import { setDropDownOptionField } from "../../../utils/FormFields/FieldsSetting/SetFieldSetting";
import { useLazyGetAllModulesQuery } from "../../../app/services/configurationAPI";
import ToastService from "../../../services/toastService/ToastService";

const UpdateSystemTaskConfiguration = ({ isModelOpen, onClose, systemTaskId, onSuccess }) => {
  const ref = useRef();
  const [formData, setFormData] = useState(SystemTaskConfigurationFormData);
  const [
    getAllUser,
    {
      isFetching: isGetAllUserFetching,
      isSuccess: isGetAllUserSucess,
      data: allGetAllUserData,
    },
  ] = useLazyGetAllUserListQuery();

  const [
    getAllModules,
    { isSuccess: isgetAllModulesSucess, data: allGetAllModulesData },
  ] = useLazyGetAllModulesQuery();

  const [getWorkflowActionsAndType ] = useLazyGetWorkflowActionsAndTypeQuery();

  const [
    updateSystemTaskConfiguration,
    {
      isLoading: isUpdateSystemTaskConfigurationLoading,
      isSuccess: isUpdateSystemTaskConfigurationSuccess,
      data: UpdateSystemTaskConfigurationData,
    },
  ] = useUpdateSystemTaskConfigurationMutation();
  const [
    getSystemTaskConfigurationById,
    {
      isFetching: isGetSystemTaskConfigurationByIdFetching,
      isSuccess: isGetSystemTaskConfigurationByIdSuccess,
      data: GetSystemTaskConfigurationByIdData,
    },
  ] = useLazyGetSystemTaskConfigurationByIdQuery();

  useEffect(() => {
    getAllUser();
    getAllModules();
    getWorkflowActionsAndType();
  }, [isModelOpen]);


  useEffect(() => {
    if (isgetAllModulesSucess && allGetAllModulesData) {
      setDropDownOptionField(
        allGetAllModulesData,
        'moduleId',
        "moduleName",
        SystemTaskConfigurationFormData,
        'moduleId'
      );
    }
  }, [isgetAllModulesSucess, allGetAllModulesData]);


  useEffect(() => {
    if (!isGetAllUserFetching && isGetAllUserSucess && allGetAllUserData) {

      setDropDownOptionField(allGetAllUserData, 'userId', 'fullName', SystemTaskConfigurationFormData, 'primaryUserId');
      setDropDownOptionField(allGetAllUserData, 'userId', 'fullName', SystemTaskConfigurationFormData, 'secondaryUserId');
    }
  }, [isGetAllUserFetching, isGetAllUserSucess, allGetAllUserData]);

  useEffect(() => {
    if (isModelOpen && systemTaskId) {
      getSystemTaskConfigurationById(systemTaskId);
    }
  }, [isModelOpen, systemTaskId]);

  useEffect(() => {
    if (
      !isGetSystemTaskConfigurationByIdFetching &&
      isGetSystemTaskConfigurationByIdSuccess &&
      GetSystemTaskConfigurationByIdData
    ) {

      let newFrom = { ...formData };
      newFrom.initialState = {
        primaryUserId: GetSystemTaskConfigurationByIdData.primaryUserId,
        secondaryUserId: GetSystemTaskConfigurationByIdData.secondaryUserId,
        moduleId: GetSystemTaskConfigurationByIdData.moduleId,
        taskAction: GetSystemTaskConfigurationByIdData.taskAction,
      //  actionId: GetSystemTaskConfigurationByIdData.actionId,
        systemTaskConfigurationId: GetSystemTaskConfigurationByIdData.systemTaskConfigurationId,
      };
      setFormData(newFrom);

    }
  }, [isGetSystemTaskConfigurationByIdFetching, isGetSystemTaskConfigurationByIdSuccess, GetSystemTaskConfigurationByIdData]);


  useEffect(() => {
    if (isUpdateSystemTaskConfigurationSuccess && UpdateSystemTaskConfigurationData) {

      ToastService.success(UpdateSystemTaskConfigurationData.errorMessage);
      onClose();
      onSuccess && getSystemTaskConfigurationById(systemTaskId)
      // onSuccess();
    }
  }, [isUpdateSystemTaskConfigurationSuccess, UpdateSystemTaskConfigurationData]);


  const handleUpdate = () => {
    const formData = ref.current.getFormData();
    if (formData) {
      const primaryUserId = formData.primaryUserId && typeof formData.primaryUserId === "object"
      ? formData.primaryUserId.value
      : formData.primaryUserId;

      const secondaryUserId = formData.secondaryUserId && typeof formData.secondaryUserId === "object"
        ? formData.secondaryUserId.value
        : formData.secondaryUserId;

      if (primaryUserId && secondaryUserId && primaryUserId === secondaryUserId) {
        ToastService.warning("Primary User and Secondary User cannot be the same.");
        return;
    }
      let request = {
        ...formData,
        systemTaskConfigurationId: systemTaskId,
        // primaryUserId: formData.primaryUserId && typeof formData.primaryUserId === "object"
        //   ? formData.primaryUserId.value
        //   : formData.primaryUserId,
        // secondaryUserId: formData.secondaryUserId && typeof formData.secondaryUserId === "object"
        //   ? formData.secondaryUserId.value
        //   : formData.secondaryUserId,
        primaryUserId,
        secondaryUserId,
        moduleId: formData.moduleId && typeof formData.moduleId === "object"
          ? formData.moduleId.value
          : formData.moduleId,
        taskAction: formData.taskAction,
        // actionId: formData.actionId && typeof formData.actionId === "object"
        //   ? formData.actionId.value
        //   : formData.actionId,

      };
      updateSystemTaskConfiguration(request);
    }
  }
  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="vertical-form">
            <FormCreator
              ref={ref}
              config={formData}
              {...formData}
            />
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="d-flex align-item-center justify-content-end">
            <Buttons
              buttonTypeClassName="theme-button"
              buttonText={"Update"}
              onClick={handleUpdate}
              isLoading={isUpdateSystemTaskConfigurationLoading}
            />
            <Buttons
              buttonTypeClassName="dark-btn ml-5"
              buttonText="Cancel"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default UpdateSystemTaskConfiguration