/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import { customFormsButtonsGridConfig } from "../config/FormButtons.data";
import FinalMolGrid from "../../../../../components/FinalMolGrid/FinalMolGrid";
import { useGetFormButtonsMutation } from "../../../../../app/services/customFormsAPI";


const CustomFormsList = ({ handleEditClick, childRef, customFormId ,handleChange, search,handleClear }) => {
    const molGridRef = useRef();
    const [customFormList, setCustomFormList] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const [getCustomForms, { isLoading: isGetCustomFormButtonLoading, isSuccess: isGetCustomFormButtonSuccess, data: isGetCustomFormButtonData },] = useGetFormButtonsMutation();

    useEffect(() => {
        onGetData()
    }, [])

    useEffect(() => {
        if (search === "" ) {
          onGetData();
        }
        }, [search]);

    const getLists = (pageObject, sortingString) => {
        const request = {
            pagination: {
                pageNumber: pageObject.pageNumber,
                pageSize: pageObject.pageSize,
            },
            filters: { searchText: search },
            sortString: sortingString,
            customFormId: customFormId
        };
        getCustomForms(request);
    };

    const handlePageChange = (page) => {
        getLists(page, molGridRef.current.generateSortingString());
    };
    const handleSorting = (shortString) => {
        getLists(molGridRef.current.getCurrentPageObject(), shortString);
    };
    const onGetData = () => {
        if (molGridRef.current) {
            const defaultPageObject = molGridRef.current.getCurrentPageObject();
            getLists(defaultPageObject, molGridRef.current.generateSortingString());
        }
    };

    useEffect(() => {
        if (isGetCustomFormButtonSuccess && isGetCustomFormButtonData) {
            if (isGetCustomFormButtonData) {
                setCustomFormList(isGetCustomFormButtonData.dataSource);
            }
            if (isGetCustomFormButtonData.totalRecord) {
                setTotalRowCount(isGetCustomFormButtonData.totalRecord);
            }
        }
    }, [isGetCustomFormButtonSuccess, isGetCustomFormButtonData]);

    useImperativeHandle(childRef, () => ({
        callChildFunction: onGetData,
    }));


    const actionHandler = {
        EDIT: handleEditClick,
    };

    return (
        <div className="row">
            <div className="col-md-12 table-striped api-provider">
                <FinalMolGrid
                    ref={molGridRef}
                    configuration={customFormsButtonsGridConfig}
                    dataSource={customFormList}
                    allowPagination={true}
                    pagination={{
                        totalCount: totalRowCount,
                        pageSize: 25,
                        currentPage: 1,
                    }}
                    onPageChange={handlePageChange}
                    onSorting={handleSorting}
                    isLoading={isGetCustomFormButtonLoading}
                    onActionChange={actionHandler}
                    handleChange={handleChange}
                    handleClear={handleClear}
                />
            </div>
        </div>
    )
}

CustomFormsList.propTypes = {
    handleEditClick: PropTypes.func,
    childRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]),
    customFormId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
    search: PropTypes.string,
    handleClear: PropTypes.func,
};


export default CustomFormsList;