/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'; 
import { AddEditThirdPartyApiData } from './config/AddEditThirdPartyApiConfiguration.data';
import Buttons from '../../../../../components/ui/button/Buttons';
import FormCreator from '../../../../../components/FinalForms/FormCreator';
import { useAddEditApiEventMutation } from '../../../../../app/services/thirdPartyAPI';
import ToastService from '../../../../../services/toastService/ToastService';
import { onResetForm } from '../../../../../utils/FormFields/ResetForm/handleResetForm';
import { getFieldData } from '../../../../../utils/FormFields/FieldsSetting/SetFieldSetting';
import { ApiEndPointMethods } from '../../../../../utils/Enums/commonEnums';

const AddEditThirdPartyApiConfiguration = (props) => {
  const addEditThirdRef = useRef();
  const [addEditeThirdData, setAddEditeThirdData] = useState(AddEditThirdPartyApiData);

  const [addEditApiEvent, { isLoading: isAddEditApiEventLoading, isSuccess: isAddEditApiEventSuccess, data: allAddEditApiEventData, },] = useAddEditApiEventMutation();

  useEffect(() => {
    const dropdownField = getFieldData(AddEditThirdPartyApiData, "eventType");
    dropdownField.fieldSetting.options = Object.entries(ApiEndPointMethods).map(
      ([key, value]) => ({
        label: key,
        value: value,
      })
    );
  }, []);
  useEffect(() => {
    if (isAddEditApiEventSuccess && allAddEditApiEventData) {
      if (allAddEditApiEventData.errorMessage.includes("exists")) {
        ToastService.warning(allAddEditApiEventData.errorMessage);
        return;
      }
      if (!props.keyId) {
        ToastService.success(allAddEditApiEventData.errorMessage);
        handleResetAndClose();
        props.onGetData()
      } else {
        handleResetAndClose();
        ToastService.success(allAddEditApiEventData.errorMessage);
        props.onRepetGetData(props.keyId)
      }
    }
  }, [isAddEditApiEventSuccess, allAddEditApiEventData]);

  const handleAddEditAPIPRovider = () => {
    
    const formData = addEditThirdRef.current.getFormData();
    if (formData) {
      let request = {
        ...formData,
        apiEventId: props.isUpdate ? props.viewCardDetails.apiEventId : formData.apiEventId,
        eventType: formData.eventType && typeof formData.eventType === "object"
        ? formData.eventType.value
        : formData.eventType,
      };
      addEditApiEvent(request);
    }
  };

  const handleResetAndClose = () => {
    onResetForm(addEditeThirdData, setAddEditeThirdData, null);
    props.onClose();
  };

  const handleColumnChange=(dataField,updatedData)=>{
    let updatedFormData = { ...addEditeThirdData };
    if (dataField === "eventName") {
        const newValue = updatedData.eventName?.replace(/\s/g, ""); 
        updatedFormData.initialState = {
            ...updatedData,
            eventName: newValue,
          };
        setAddEditeThirdData(updatedFormData);
    }
  }
  
  useEffect(() => {
    onResetForm(addEditeThirdData, setAddEditeThirdData, null);
    if (props.viewCardDetails) {
      let setData = { ...addEditeThirdData }
      setData.initialState = {
        eventName: props.viewCardDetails.eventName,
        description: props.viewCardDetails.description,
        eventType:props.viewCardDetails.eventType,
        eventTitle : props.viewCardDetails.eventTitle
      }
      setAddEditeThirdData(setData)
    }
  }, [props.isOpen])

  
  return (
    <div className="row mt-2 add-address-form">
      <div className='col-12'>
      <FormCreator
        config={addEditeThirdData}
        ref={addEditThirdRef}
        onColumnChange={handleColumnChange}
      />
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex align-item-end justify-content-end">
          <Buttons
            buttonTypeClassName="theme-button"
            buttonText="Save"
            onClick={handleAddEditAPIPRovider}
            isLoading={isAddEditApiEventLoading}
          />
          <Buttons
            buttonTypeClassName="dark-btn ml-5"
            buttonText="Cancel"
            onClick={handleResetAndClose}
          />
        </div>
      </div>
    </div>
  )
}

AddEditThirdPartyApiConfiguration.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGetData: PropTypes.func,
  onRepetGetData: PropTypes.func,
  viewCardDetails: PropTypes.shape({
    apiEventId: PropTypes.number,
    eventName: PropTypes.string,
    description: PropTypes.string,
  }),
  isUpdate: PropTypes.bool,
  keyId: PropTypes.number,
};
export default AddEditThirdPartyApiConfiguration