/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { DropdownWrapper } from "../../../components/ui/dropdownwrapper/DropdownWrapper";
import "./Header.scss";
import Image from "../../../components/image/Image";
import { AppIcons } from "../../../data/appIcons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../app/slice/authSlice";
import Iconify from "../../../components/ui/iconify/Iconify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logUserLoginLogoutHistory } from "../../../utils/Thunk/UserHistory";
import { encryptUrlData } from "../../../services/CryptoService";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import AddOrderTabs from "../../../pages/order/AddOrderTabs/addOrderTabs";
import CommandPalette from "../commandPalette/CommandPalette";
import Badge from "../../../components/ui/badge/Badge";
import { securityKey } from "../../../data/SecurityKey";
import {
  hasFunctionalPermission,
  hasPermission,
} from "../../../utils/AuthorizeNavigation/authorizeNavigation";
import AdvancedEmailSettings from "./features/advancedemailsetting/AdvancedEmailSettings";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const shortcutSecRef = useRef(null);
  const [, setIsActive] = useState(false);
  const authState = useSelector((state) => state.auth);
  const [showOrderEntryPopup, setshowOrderEntryPopup] = useState(false);

  const [buttonVisible, setButtonVisible] = useState(false);

  const hasAddPermission = hasFunctionalPermission(securityKey.ADDORDER);

  const [dataList, setDataList] = useState([
    {
      rowId: 1,
      items: [
        {
          id: 1,
          iconPath: "ic:outline-email",
          title: "Email Inbox",
          description: "",
          navigationLink: "/emailIntent/Quotation",
        },
        {
          id: 2,
          iconPath: "mdi:cart-plus",
          title: "Add Order",
          description: "",
          navigationLink: "#",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (hasAddPermission?.hasAccess) {
      setButtonVisible(true);
    } else if (buttonVisible === false) {
      setButtonVisible(false);
      setDataList((prevList) =>
        prevList.map((row) => ({
          ...row,
          items: row.items.filter((item) => item.title !== "Add Order"),
        }))
      );
    }
  }, [hasAddPermission]);

  // ** Check Form fields Permission */
  useEffect(() => {
    if (hasAddPermission?.hasAccess === true) {
      setButtonVisible(true);
    }
  }, [hasAddPermission]);

  const LogoutButton = () => {
    dispatch(logout());
    navigate("/login");
    dispatch(
      logUserLoginLogoutHistory({
        userId: authState.user.userID,
        isLogin: authState.isAuthenticated,
      })
    );
  };

  const handleClickOutside = (event) => {
    if (
      shortcutSecRef.current &&
      !shortcutSecRef.current.contains(event.target)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const openShortCut = (navigationLink) => {
    if (navigationLink === "#") {
      setshowOrderEntryPopup(true);
    } else {
      navigate(navigationLink);
    }
  };

  const openAdmin = () => {
    navigate("/configuration/Organization");
  };
  const isConfigPage = location.pathname.includes("/configuration");
  // const isIntentEmail=location.pathname.includes("/emailIntent");

  const closeOrderEntryPopup = () => {
    setshowOrderEntryPopup(false);
  };

  //Command palette
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);

  const toggleCommandPalette = () => {
    setIsCommandPaletteOpen((prev) => !prev);
  };

  const handleCommandSelect = (command) => {
    setIsCommandPaletteOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault(); // Prevent the browser's default behavior
        setIsCommandPaletteOpen(true); // Open Command Palette
      } else if (event.key === "Escape") {
        event.preventDefault(); // Prevent the browser's default behavior
        setIsCommandPaletteOpen(false); // Close Command Palette
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleBodyClick = (e) => {
    if (!e.target.closest(".command-palette-trigger")) {
      setIsCommandPaletteOpen(false);
    }
  };

  useEffect(() => {
    if (isCommandPaletteOpen) {
      document.body.addEventListener("click", handleBodyClick);
    } else {
      document.body.removeEventListener("click", handleBodyClick);
    }
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [isCommandPaletteOpen]);

  return (
    <div className="top-header-main">
      <div className="header-section">
        <div className="left-section">
          <DropdownWrapper
            toggleElement={
              <button
                className="email-advan-setting-btn"
                onClick={() => {
                  navigate("/emailIntent/Quotation");
                }}
              >
                <Iconify icon="hugeicons:mail-setting-01" />
              </button>
            }
            gap={8}
            className={"advance-settings-dropdown-container"}
            closeOnChildClick={false}
          >
            {({ closeDropdown }) => (
              <div>
                <AdvancedEmailSettings closeDropdown={closeDropdown} />
              </div>
            )}
          </DropdownWrapper>
          <div className="search-bar">
            <div
              className="command-palette-trigger"
              onClick={toggleCommandPalette}
            >
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <Iconify icon="mdi:magnify" width={18} />
                <span>Search...</span>
              </div>
              <span className="shortcut-key">Ctrl + K</span>
            </div>
          </div>
          {isCommandPaletteOpen && (
            <CommandPalette
              isOpen={isCommandPaletteOpen}
              onClose={() => setIsCommandPaletteOpen(false)}
              onCommandSelect={handleCommandSelect}
            />
          )}
        </div>
        <div className="right-section">
          <div className="profile-section">
            {hasPermission(securityKey.CONFIGURATIONMANAGEMENT) && (
              <div className={`shortcut-sec`}>
                {isConfigPage ? (
                  <div
                    className="header-icon-part"
                    onClick={() => navigate("/")}
                  >
                    <Iconify icon="mdi:home" />
                  </div>
                ) : (
                  <div className="header-icon-part" onClick={openAdmin}>
                    <Iconify icon="line-md:cog-loop" />
                  </div>
                )}
              </div>
            )}
            <div className="shortcut-list">
              <DropdownWrapper
                toggleElement={<Iconify icon="ic:round-dashboard" width={23} />}
                className="shortcut-list-dropdown"
                direction="left"
              >
                <div className="shortcuts-list">
                  <div className="top-header-card-title">
                    <span className="title">Shortcuts</span>
                    <span className="add-short-cut" title="Create New Shortcut">
                      {/* <i className="bi bi-plus-circle-fill"></i> */}
                    </span>
                  </div>
                  <div className="short-cuts-list">
                    {dataList.map((row, index) => (
                      <div key={index} className="row m-0 manus-items">
                        {row.items.map((item, rowIndex) => (
                          <div
                            key={rowIndex}
                            className="col-6 p-0 shortcut-menus"
                          >
                            <div
                              className="shortcuts"
                              onClick={() => openShortCut(item.navigationLink)}
                            >
                              <div className="shortcut-icon icon-color">
                                {/* <Image imagePath={item.iconPath} altText="Icon" /> */}
                                <Iconify icon={item.iconPath} />
                              </div>
                              <div className="shortcut-desc">
                                <h6>{item.title}</h6>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </DropdownWrapper>
            </div>
            <div className="notification">
              <div className="bell-icon">
                {/* <Image
                imagePath={AppIcons.notificationIcon}
                imgCustomClassName="open-bar"
                altText="Icon"
              ></Image>
              <div className="dot-round">4</div> */}
                <Badge
                  badgeContent={4}
                  color="#07cdae"
                  position="top-right"
                  isShowBadge="true"
                >
                  <Iconify icon={"iconamoon:notification-fill"} width={21} />
                </Badge>
              </div>
              <div className="notification-list">
                <div className="title-clearall">
                  <span className="title">Notifications</span>
                  <span className="clear-all">
                    <button onClick={() => {}}>Clear All</button>
                  </span>
                </div>
                <div className="notification-items">
                  <ul>
                    <li>
                      <div className="noti-img">
                        <i className="bi bi-bell"></i>
                      </div>
                      <div className="notification-time">
                        <button onClick={() => {}}>
                          Lorem Ipsum is simply dummy text
                        </button>
                        <div className="time-sec">15 mins ago</div>
                      </div>
                    </li>
                    <li>
                      <div className="noti-img">
                        <i className="bi bi-bell"></i>
                      </div>
                      <div className="notification-time">
                        <button onClick={() => {}}>
                          Lorem Ipsum is simply dummy text
                        </button>
                        <div className="time-sec">15 mins ago</div>
                      </div>
                    </li>
                    <li>
                      <div className="noti-img">
                        <i className="bi bi-bell"></i>
                      </div>
                      <div className="notification-time">
                        <button onClick={() => {}}>
                          Lorem Ipsum is simply dummy text
                        </button>
                        <div className="time-sec">15 mins ago</div>
                      </div>
                    </li>
                    <li>
                      <div className="noti-img">
                        <i className="bi bi-bell"></i>
                      </div>
                      <div className="notification-time">
                        <button onClick={() => {}}>
                          Lorem Ipsum is simply dummy text
                        </button>
                        <div className="time-sec">15 mins ago</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="notification-footer">
                  <button onClick={() => {}}>View All</button>
                </div>
              </div>
            </div>
            <div className="profiles">
              <div className="profile-img">
                <Image
                  imagePath={AppIcons.userIcon}
                  imgCustomClassName="open-bar"
                  altText="Icon"
                ></Image>
              </div>
              <div className="user-name-desc">
                <span>
                  {/* <Image
                  imagePath={AppIcons.arrowIcon}
                  imgCustomClassName="open-bar"
                  altText="Icon"
                ></Image> */}
                  <Iconify
                    imgCustomClassName="open-bar"
                    icon="solar:alt-arrow-down-outline"
                  />
                </span>
              </div>
              <div className="profile-dropdown-menu">
                <div className="title-list ">
                  <span className="name-title ">Signed in as</span>
                  <span className="user-name">{authState?.user?.fullName}</span>
                </div>
                <div className="title-list drop-down-icon-menu">
                  <Link
                    to={`/configuration/usermanagement/edit/${encryptUrlData(
                      authState?.user?.userID
                    )}`}
                  >
                    <span className="bi bi-pencil">
                      <span>Edit Profile</span>
                    </span>
                  </Link>
                </div>
                <div className="title-list drop-down-icon-menu logout">
                  <button onClick={LogoutButton}>
                    <span className="bi bi-box-arrow-left">Log out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showOrderEntryPopup && (
          <CenterModel
            showModal={showOrderEntryPopup}
            className="center-model po-model"
            headerClassName="po-model-title"
            handleToggleModal={closeOrderEntryPopup}
            modalTitle="Order Entry Details"
            modelSizeClass="width-98"
            higherCenterModalClass="product-details-modal"
          >
            <AddOrderTabs closeOrderEntryPopup={closeOrderEntryPopup} />
          </CenterModel>
        )}
      </div>
    </div>
  );
}

export default Header;
