/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetApiEventLogByEventIdMutation, useLazyGetApiEventLogsByGuidandEventIdQuery } from "../../../../../../../app/services/thirdPartyAPI";
import DataLoader from "../../../../../../../components/ui/dataLoader/DataLoader";
import formatDate from "../../../../../../../lib/formatDate";
import NoRecordFound from "../../../../../../../components/ui/noRecordFound/NoRecordFound";
// import '../../../../../../../pages/organization/feature/organizationHistory/OrganizationHistory.scss';
import "./EventHistory.scss";
import CardSection from "../../../../../../../components/ui/card/CardSection";
import PropTypes from "prop-types";
import DropDown from "../../../../../../../components/FinalForms/ui/inputs/dropdown/DropDown";
import Buttons from "../../../../../../../components/ui/button/Buttons";
import { AppIcons } from "../../../../../../../data/appIcons";
import ToastService from "../../../../../../../services/toastService/ToastService";
import Iconify from "../../../../../../../components/ui/iconify/Iconify";
import CenterModel from "../../../../../../../components/ui/centerModel/CenterModel";
import Tooltip from "../../../../../../../components/ui/tooltip2/Tooltip";
import ReactJson from "react-json-view";
import { parseDynamicRequestData } from "../../../../../../../utils/CommonUtils/CommonUtilsMethods";

const EventHistory = ({ keyId }) => {
  const [historyLogList, setHistoryLogList] = useState([]);
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const [selectedEventName, setSelectedEventName] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [selectedRequestData, setSelectedRequestData] = useState(null);

  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [selectedResponseData, setSelectedResponseData] = useState(null);

  const [
    getApiEventLogByEventId,
    {
      isLoading: isGetApiEventLogByEventIdLoading,
      isSuccess: isGetApiEventLogByEventIdSuccess,
      data: isGetApiEventLogByEventIdData,
    },
  ] = useGetApiEventLogByEventIdMutation();

  const [getApiEventLogsByGuidandEventId, { isFetching: isEventFetching, isSuccess: isEventSuccess, data: eventNamesData, }] = useLazyGetApiEventLogsByGuidandEventIdQuery();


  useEffect(() => {
    if (!isEventFetching && isEventSuccess && eventNamesData) {

      const EventListData = eventNamesData.map((item) => ({
        value: item.apiEventGuid,
        label: item.provider_Endpoint,
      }));
      setEventNameOptions(EventListData);
    }
  }, [isEventFetching, isEventSuccess, eventNamesData]);

  useEffect(() => {
    getListApi(1);
  }, []);

  useEffect(() => {
    if (keyId) {
      getApiEventLogsByGuidandEventId(keyId);
    }
  }, []);

  const getListApi = (page) => {

    const request = {
      pagination: {
        pageNumber: page,
        pageSize: 25,
      },
      filters: {
        searchText: selectedEventName.value || "",
      },

      sortString: "",
      eventId: keyId,
    };
    getApiEventLogByEventId(request);
  };

  useEffect(() => {
    if (isGetApiEventLogByEventIdSuccess && isGetApiEventLogByEventIdData) {
      setHistoryLogList(isGetApiEventLogByEventIdData.dataSource);
    }
  }, [isGetApiEventLogByEventIdSuccess, isGetApiEventLogByEventIdData]);

  const handleEventNameChange = (selectedOption) => {
    setSelectedEventName(selectedOption);

  };

  const handleSearch = () => {
    if (selectedEventName) {
      setSelectedEventName(selectedEventName.value)
      getListApi();
    } else {
      ToastService.warning("Please select value");
    }
  };

  const handleClear = () => {
    setSelectedEventName("");
    getListApi();
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedRequestData(null);
  };


  const handleOpenPopup = (requestData) => {
    const parsedRequestData = parseDynamicRequestData(requestData);
    setSelectedRequestData(parsedRequestData);
    setShowPopup(true);
  };
  const handleOpenResponsePopup = (requestData) => {
    const parsedRequestData = parseDynamicRequestData(requestData);
    setSelectedResponseData(parsedRequestData);
    setShowResponsePopup(true);
  };

  const closeResponsePopup = () => {
    setShowResponsePopup(false);
    setSelectedResponseData(null);
  };

  const renderContent = () => {
    if (isGetApiEventLogByEventIdLoading) {
      return (
        <div>
          <DataLoader />
        </div>
      );
    }

    if (historyLogList.length === 0) {
      return <NoRecordFound />;
    }


    return (
      <>
        <div className="event-history-card">
          <h4 className="organization-tab-title d-flex justify-content" style={{ marginBottom: '0px', paddingBottom: '0px', borderBottom: 'unset' }}>History Logs</h4>
          <div className="serach-bar-history" style={{ width: 'auto', marginTop: '0px' }}>
            <div className="card w-100">
              <div className="d-flex align-items-start">
                <div className="pr-0  name-field">
                  <DropDown
                    placeholder="Search By ApiProvider and Endpoint"
                    options={eventNameOptions}
                    value={selectedEventName}
                    onChange={handleEventNameChange}
                  />
                </div>

                <div className="refresh-btn-history pl-0 ml-2">
                  <Buttons
                    buttonTypeClassName="theme-button"
                    buttonText="Search"
                    onClick={handleSearch}
                    imagePath={AppIcons.SearchIcone}
                    textWithIcon={true}
                  ></Buttons>
                  <Buttons
                    buttonTypeClassName="dark-btn ml-2"
                    buttonText="Clear"
                    onClick={handleClear}
                    imagePath={AppIcons.ClearIcone}
                    textWithIcon={true}
                  ></Buttons>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column ">
          {historyLogList.map((event, index) => {
            const formattedDate = event.eventRunDate ? formatDate(event.eventRunDate, "MM/DD/YYYY hh:mm A") : ""
            return (
              <div
                className="vertical-timeline-item vertical-timeline-element"
                key={event.eventLogLogId || index}
              >
                <div>
                  <span className="vertical-timeline-element-icon bounce-in">
                    <i className="badge badge-dot badge-dot-xl badge-primary">
                      {" "}
                    </i>
                  </span>
                  <div className="vertical-timeline-element-content bounce-in">
                    <h4 className={`timeline-title d-flex ${event.statusCode === '400' ? "event-error" : event.responseData && 'event-success'}`}>
                      <span className="mr-1">{event.eventName}</span>
                      {event.requestData || event.responseData ?
                        <span className="mr-1 font-bold">
                          Status Code :  {event.statusCode}
                        </span>
                        : ""}
                      <span>
                        {event?.requestData ? (
                          <>
                            <Tooltip label={"Request Json"} position="right">
                              <Iconify
                                icon="lucide:file-json-2"
                                onClick={() => handleOpenPopup(event.requestData)}
                                style={{ cursor: "pointer" }}
                                className={`${event.statusCode === '400' ? "event-error-icon" : 'event-success-icon'}`}
                              />
                            </Tooltip>
                          </>
                        ) : ""}

                        {event?.responseData ? (
                          <>
                            <Tooltip label={"Response Json"} position="right">
                              <Iconify
                                icon="lucide:file-json-2"
                                onClick={() => handleOpenResponsePopup(event.responseData)}
                                style={{ cursor: "pointer" }}
                                className={`${event.statusCode === '400' ? "event-error-icon" : 'event-success-icon'}`}
                              />
                            </Tooltip>
                          </>
                        ) : ""}
                      </span>
                    </h4>
                    <p className={`fw-bold ${event.statusCode === '400' ? "chip-red" : event.responseData && 'chip-green'}`}>
                      {event.errorMessage}
                    </p>
                    <span className="vertical-timeline-element-date">
                      {formattedDate}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

          {showPopup && (
            <CenterModel
              showModal={showPopup}
              handleToggleModal={closePopup}
              modalTitle="Request Data"
              modelSizeClass="w-60">
              <div className="event-history-data">
                <ReactJson
                  src={typeof selectedRequestData === 'string' ? JSON.parse(selectedRequestData) : selectedRequestData}
                  collapsed={false} theme="monokai" enableClipboard={false}
                  displayDataTypes={false} displayObjectSize={false} />
              </div>
              {/* {selectedRequestData || "No Content Available"} */}
            </CenterModel>
          )}
          {showResponsePopup && (
            <CenterModel
              showModal={showResponsePopup}
              handleToggleModal={closeResponsePopup}
              modalTitle="Response Data"
              modelSizeClass="w-60">
              <div className="event-history-data">
                <ReactJson
                  src={typeof selectedResponseData === 'string' ? JSON.parse(selectedResponseData) : selectedResponseData}
                  collapsed={false} theme="monokai" enableClipboard={false}
                  displayDataTypes={false} displayObjectSize={false} />
              </div>
            </CenterModel>
          )}
        </div>

      </>
    );


    // return <NoRecordFound />;
  };
  return (
    <CardSection>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card">{renderContent()}</div>
        </div>
      </div>
    </CardSection>
  );
};

EventHistory.propTypes = {
  keyId: PropTypes.number,
};
export default EventHistory;
