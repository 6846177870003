import { useRef } from "react";
import FinalMolGrid from "../../../../components/FinalMolGrid/FinalMolGrid";
import { SystemTaskOrderItems } from "../config/SystemTaskOrderItem.data";

const SystemTaskOrderItemList = ({ orderItemDetail }) => {
    const molGridRef = useRef();
    return (<>
            <div className="mb-1 fw-bold">
                <span>Order Item List :</span>
            </div>
            <FinalMolGrid 
            ref={molGridRef}
            configuration={SystemTaskOrderItems}
            dataSource={orderItemDetail}
            allowPagination={false}/>
            </>

    )
}
export default SystemTaskOrderItemList