/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FinalMolGrid from "../../../components/FinalMolGrid/FinalMolGrid";
import { customFormsGridConfig } from "../config/CustomForms.data";
import { useGetCustomFormsMutation } from "../../../app/services/customFormsAPI";
import { useNavigate } from "react-router-dom";
import { encryptUrlData } from "../../../services/CryptoService";

const CustomFormsList = ({handleEditClick,childRef}) => {
    const molGridRef = useRef();
    const [customFormList, setCustomFormList] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const navigate=useNavigate();

    const [getCustomForms, { isLoading: isGetCustomFormsLoading, isSuccess: isGetCustomFormsSuccess, data: isGetCustomFormsData },] =  useGetCustomFormsMutation();

    useEffect(() => {
        onGetData()
      }, [])
    
      const getLists = (pageObject, sortingString) => {
        const request = {
          pagination: {
            pageNumber: pageObject.pageNumber,
            pageSize: pageObject.pageSize,
          },
          filters: { searchText: "" },
          sortString: sortingString,
        };
        getCustomForms(request);
      };
    
      const handlePageChange = (page) => {
        getLists(page, molGridRef.current.generateSortingString());
      };
      const handleSorting = (shortString) => {
        getLists(molGridRef.current.getCurrentPageObject(), shortString);
      };
      const onGetData = () => {
        if (molGridRef.current) {
          const defaultPageObject = molGridRef.current.getCurrentPageObject();
          getLists(defaultPageObject, molGridRef.current.generateSortingString());
        }
      };
    
      useEffect(() => {
        if (isGetCustomFormsSuccess && isGetCustomFormsData) {
          if (isGetCustomFormsData) {
            setCustomFormList(isGetCustomFormsData.dataSource);
          }
          if (isGetCustomFormsData.totalRecord) {
            setTotalRowCount(isGetCustomFormsData.totalRecord);
          }
        }
      }, [isGetCustomFormsSuccess, isGetCustomFormsData]);

      useImperativeHandle(childRef, () => ({
        callChildFunction: onGetData,
      }));

      const handleViewClick=(data)=>{
        
        navigate(`/configuration/customFormsdetail/${encryptUrlData(data.customFormId)}`);
      }
      const actionHandler = {
       EDIT: handleEditClick,
       VIEWFORMFIELDS : handleViewClick,
      };
    return (<div className="row">
        <div className="col-md-12 table-striped api-provider">
            <FinalMolGrid
                ref={molGridRef}
                configuration={customFormsGridConfig}
                dataSource={customFormList}
                allowPagination={true}
                pagination={{
                    totalCount: totalRowCount,
                    pageSize: 25,
                    currentPage: 1,
                }}
                onPageChange={handlePageChange}
                onSorting={handleSorting}
                isLoading={isGetCustomFormsLoading}
                onActionChange={actionHandler}
            />
        </div>
    </div>)
}
export default CustomFormsList;