
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const customFormsAPI = createApi({
    reducerPath: "customFormsAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addEditCustomForm: builder.mutation({
            query: (requestData) => ({
                url: '/CustomForm/AddEditCustomForm',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
     
        getCustomForms: builder.mutation({
            query: (requestData) => ({
                url: '/CustomForm/GetCustomForms',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
  
        getCustomFormsByCustomFormId: builder.query({
            query: (customFormId) => ({
                url: encryptQueryString(`/CustomForm/GetCustomFormsByCustomFormId/?customFormId=${Number(customFormId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getFormButtons: builder.mutation({
            query: (requestData) => ({
                url: '/FormField/GetFormButtons',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addEditFormButton: builder.mutation({
            query: (requestData) => ({
                url: '/FormField/AddEditFormButton',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getFormButtonByFormButtonId: builder.query({
            query: (formButtonId) => ({
                url: encryptQueryString(`/FormField/GetFormButtonByFormButtonId/?formButtonId=${Number(formButtonId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    })
})

export const {
     useAddEditCustomFormMutation, useGetCustomFormsMutation,useLazyGetCustomFormsByCustomFormIdQuery,
     useGetFormButtonsMutation,useAddEditFormButtonMutation,useLazyGetFormButtonByFormButtonIdQuery
    
} = customFormsAPI

export default customFormsAPI;