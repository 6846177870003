import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";
import PropTypes from "prop-types";

const OrderFolloeUpEmails=()=>{
    return(  <EmailManagement emailIntentId={EmailInboxTypesEnum.ORDERFOLLOWUP} intentName="Order Follow Up Emails" />)
}
EmailManagement.propTypes = {
    emailIntentId: PropTypes.oneOf(Object.values(EmailInboxTypesEnum)).isRequired, 
    intentName: PropTypes.string.isRequired,
  };
export default OrderFolloeUpEmails;