/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import FormCreator from "../../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../../components/ui/button/Buttons";
import { AddEditCustomFormFieldsFormData } from "../config/FormFields.data";
import { useAddEditFormFieldMutation, useLazyGeFormFieldsByFormFieldIdQuery } from "../../../../../app/services/formFieldsAPI";
import { onResetForm } from "../../../../../utils/FormFields/ResetForm/handleResetForm";
import ToastService from "../../../../../services/toastService/ToastService";
import { getValue } from "../../../../../utils/CommonUtils/CommonUtilsMethods";
import { getFieldData } from "../../../../../utils/FormFields/FieldsSetting/SetFieldSetting";
import { ControlTypes, ValidationListTypes } from "../../../../../utils/Enums/commonEnums";

const AddEditFormFields=({customFormId,onClose,formFieldId,isEdit,onGetData})=>{

   const formFieldRef=useRef();

    const [formData, setFormData] = useState(AddEditCustomFormFieldsFormData);
   const [addEditFormField, { isLoading: isAddEditFormFieldLoading, isSuccess: isAddEditFormFieldSuccess, data: allAddEditFormFieldData, },] = useAddEditFormFieldMutation();
   const [geFormFieldsByFormFieldId, { isFetching: isGetFormFieldsByFormFieldIdFetching, isSuccess: isGetFormFieldsByFormFieldIdSuccess, data: GetFormFieldsByFormFieldIdData, },] = useLazyGeFormFieldsByFormFieldIdQuery();

useEffect(()=>{
    const dropdownField = getFieldData(AddEditCustomFormFieldsFormData, "validationList");
    dropdownField.fieldSetting.options = Object.entries(ValidationListTypes).map(([key, value]) => ({
      label: value,
      value: value,
    }));
},[])

useEffect(() => {
    const dropdownField = getFieldData(AddEditCustomFormFieldsFormData, "controlType");
    dropdownField.fieldSetting.options = Object.entries(ControlTypes).map(([key, value]) => ({
      label: key,
      value: key,
    }));
  }, []);

  useEffect(() => {
    if (formFieldId && isEdit) {
        geFormFieldsByFormFieldId(formFieldId);
    }
}, [formFieldId,isEdit]);

useEffect(() => {
    if (isGetFormFieldsByFormFieldIdSuccess && GetFormFieldsByFormFieldIdData && !isGetFormFieldsByFormFieldIdFetching) {
        
        const newFrom = { ...formData };
            const validationListType = GetFormFieldsByFormFieldIdData.validationList
            ? GetFormFieldsByFormFieldIdData.validationList.split(",")
            : [];
        newFrom.initialState = {
            fieldName: GetFormFieldsByFormFieldIdData?.fieldName,
            description: GetFormFieldsByFormFieldIdData?.description,
            dataField:GetFormFieldsByFormFieldIdData?.dataField,
            maxLength:GetFormFieldsByFormFieldIdData?.maxLength,
            minLength:GetFormFieldsByFormFieldIdData?.minLength,
            isActive: GetFormFieldsByFormFieldIdData?.isActive,
            controlType :GetFormFieldsByFormFieldIdData.controlType,
            validationList: validationListType,
            formFieldId:GetFormFieldsByFormFieldIdData.formFieldId,
            customFormId:GetFormFieldsByFormFieldIdData.customFormId
        };
        setFormData(newFrom);
    }
}, [isGetFormFieldsByFormFieldIdSuccess, GetFormFieldsByFormFieldIdData, isGetFormFieldsByFormFieldIdFetching]);

   useEffect(() => {
    if (isAddEditFormFieldSuccess && allAddEditFormFieldData) {

        if (allAddEditFormFieldData.errorMessage.includes('exists')) {

            ToastService.warning(allAddEditFormFieldData.errorMessage);
            return;
          }
        ToastService.success(allAddEditFormFieldData.errorMessage);
        onGetData();
        onClose();
        onResetForm(AddEditCustomFormFieldsFormData, setFormData, null);
    }
}, [isAddEditFormFieldSuccess, allAddEditFormFieldData]);

const handleSaveClick = () => {

    const formFieldData = formFieldRef.current.getFormData();
    if (formFieldData ) {
        const request = {
            customFormId:customFormId,
            formFieldId: formFieldId || 0,
            fieldName: formFieldData?.fieldName,
            description: formFieldData?.description,
            dataField:formFieldData?.dataField,
            controlType :getValue(formFieldData?.controlType),
            validationList: getValue(formFieldData?.validationList.join(',')),
            maxLength:Number(formFieldData?.maxLength),
            minLength:Number(formFieldData?.minLength)  ,
            isActive: formFieldData?.isActive,
        }
        addEditFormField(request)
    }
}

const handleResetAndClose = () => {
    onResetForm(AddEditCustomFormFieldsFormData, setFormData, null);
    onClose();
};

return( <div className="row">
    <div className="col-md-12">
        <div className="vertical-form">
            <FormCreator ref={formFieldRef} 
             config={formData} 
            />
        </div>
    </div>
    <div className="col-md-12 mt-2">
        <div className="d-flex align-item-center justify-content-end">
            <Buttons
                buttonTypeClassName="theme-button"
                 buttonText={isEdit ? "Update" : "Save"}
             onClick={handleSaveClick}
                 isLoading={isAddEditFormFieldLoading}
            />
            <Buttons
                buttonTypeClassName="dark-btn ml-5"
                buttonText="Cancel"
                 onClick={handleResetAndClose}
            />
        </div>
    </div>
</div>)
}
export default AddEditFormFields;