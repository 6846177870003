/* eslint-disable react-hooks/exhaustive-deps */
import CardSection from "../../../components/ui/card/CardSection";
import formatDate from "../../../lib/formatDate";
import { useLazyGetSystemTaskBadgeResponseBySystemTaskBadgeIdQuery } from "../../../app/services/systemTaskAPI";
import { useEffect, useRef, useState } from "react";
import { getLabelClass } from "../../../utils/StatusColors/StatusColors";
import SystemBadges from "./feature/SystemBadges";
import { useLazyGetCustomersBasicInformationByIdQuery } from "../../../app/services/basicdetailAPI";
import {
  useLazyGetOrderDetailByOrderIdQuery,
  useLazyGetOrderItemsByOrderIdQuery,
} from "../../../app/services/orderAPI";
import { useLazyGetDetailsbyCustomerIDQuery } from "../../../app/services/customerSettingsAPI";
import { Button } from "react-bootstrap";
import NoRecordFound from "../../../components/ui/noRecordFound/NoRecordFound";
import DataLoader from "../../../components/FinalMolGrid/ui/dataLoader/DataLoader";
import AddEditSystemTaskComments from "./feature/AddEditSystemTaskComments";
import SystemComments from "../../systemTask/feature/systemComments/SystemComments";
import { encryptUrlData } from "../../../services/CryptoService";
import "./SystemTasksDetails.scss";
import { getStatusColor } from "../../../utils/CommonUtils/CommonUtilsMethods";
import { OrderBadges } from "../../../utils/Enums/commonEnums";
import SidebarModel from "../../../components/ui/sidebarModel/SidebarModel";
import IconButton from "../../../components/ui/iconbutton/IconButton";

const SystemTasksDetails = ({
  selectedBadgeId,
  orderId,
  customerId,
  onBadgeRefresh,
  orderList
}) => {

  const childRef = useRef();
  const isRefreshRef = useRef();
  const [systemTaskDetails, setSystemTaskDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [paymentTermDetail, setPaymentTermDetail] = useState(null);
  const [orderItemDetail, setOrderItemDetail] = useState(null);
  const [
    getSystemTaskBadgeResponseBySystemTaskBadgeId,
    {
      isFetching: isGetSystemTaskBadgeResponseBySystemTaskBadgeIdFetching,
      isSuccess: isGetSystemTaskBadgeResponseBySystemTaskBadgeIdSucess,
      data: allGetSystemTaskBadgeResponseBySystemTaskBadgeIdData,
    },
  ] = useLazyGetSystemTaskBadgeResponseBySystemTaskBadgeIdQuery();
  const [
    getCustomersInfoById,
    {
      isFetching: isGetCustomerFetching,
      isSuccess: isGetCustomerSuccess,
      data: isGetCustomerData,
    },
  ] = useLazyGetCustomersBasicInformationByIdQuery();
  const [
    getOrderDetailByOrderId,
    {
      isFetching: isOrderDetailsFetching,
      isSuccess: isOrderDetailsFetched,
      data: orderByOrderIdDetails,
    },
  ] = useLazyGetOrderDetailByOrderIdQuery();
  const [
    GetDetailsbyCustomerID,
    {
      isFetching: isGetDetailByCustomerIdFetching,
      isSuccess: isGetDetailByCustomerIdSuccess,
      data: isGetDetailByCustomerIdData,
    },
  ] = useLazyGetDetailsbyCustomerIDQuery();
  const [
    getOrderItemsByOrderId,
    {
      isFetching: isGetOrderItemsByOrderIdFetching,
      isSuccess: isGetOrderItemsByOrderIdSuccess,
      data: isGetOrderItemsByOrderIdData,
    },
  ] = useLazyGetOrderItemsByOrderIdQuery();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [btnType, setBtnType] = useState(null);

  useEffect(() => {
    if (selectedBadgeId === null) {
      setSystemTaskDetails(null);
      setCustomerDetails(null);
      setPaymentTermDetail(null);
      setOrderDetails(null);
      setOrderItemDetail(null);
    }
  }, [selectedBadgeId]);

  useEffect(() => {
    if (selectedBadgeId) {
      getSystemTaskBadgeResponseBySystemTaskBadgeId(selectedBadgeId);
    }
  }, [selectedBadgeId]);

  const onSystemTaskDetailRefresh = () => {
    selectedBadgeId &&
      getSystemTaskBadgeResponseBySystemTaskBadgeId(selectedBadgeId);
  };

  useEffect(() => {
    if (customerId && orderId) {
      GetDetailsbyCustomerID(customerId);
      getCustomersInfoById(customerId);
      getOrderDetailByOrderId(orderId);
      getOrderItemsByOrderId(orderId);
    }
  }, [customerId, orderId]);

  useEffect(() => {
    if (
      !isGetDetailByCustomerIdFetching &&
      isGetDetailByCustomerIdSuccess &&
      isGetDetailByCustomerIdData
    ) {
      setPaymentTermDetail(isGetDetailByCustomerIdData);
    }
  }, [
    isGetDetailByCustomerIdFetching,
    isGetDetailByCustomerIdSuccess,
    isGetDetailByCustomerIdData,
  ]);

  useEffect(() => {
    if (!isGetCustomerFetching && isGetCustomerSuccess && isGetCustomerData) {
      setCustomerDetails(isGetCustomerData);
    }
  }, [isGetCustomerFetching, isGetCustomerSuccess, isGetCustomerData]);

  useEffect(() => {
    if (
      !isOrderDetailsFetching &&
      isOrderDetailsFetched &&
      orderByOrderIdDetails
    ) {
      if (orderByOrderIdDetails) {
        setOrderDetails(orderByOrderIdDetails);
      }
    }
  }, [isOrderDetailsFetching, isOrderDetailsFetched, orderByOrderIdDetails]);

  useEffect(() => {
    if (
      !isGetOrderItemsByOrderIdFetching &&
      isGetOrderItemsByOrderIdSuccess &&
      isGetOrderItemsByOrderIdData
    ) {
      setOrderItemDetail(isGetOrderItemsByOrderIdData.orderItems);
    }
  }, [
    isGetOrderItemsByOrderIdFetching,
    isGetOrderItemsByOrderIdSuccess,
    isGetOrderItemsByOrderIdData,
  ]);

  useEffect(() => {
    if (
      !isGetSystemTaskBadgeResponseBySystemTaskBadgeIdFetching &&
      isGetSystemTaskBadgeResponseBySystemTaskBadgeIdSucess &&
      allGetSystemTaskBadgeResponseBySystemTaskBadgeIdData
    ) {
      if (allGetSystemTaskBadgeResponseBySystemTaskBadgeIdData) {
        setSystemTaskDetails(
          allGetSystemTaskBadgeResponseBySystemTaskBadgeIdData
        );
      }
    }
  }, [
    isGetSystemTaskBadgeResponseBySystemTaskBadgeIdFetching,
    isGetSystemTaskBadgeResponseBySystemTaskBadgeIdSucess,
    allGetSystemTaskBadgeResponseBySystemTaskBadgeIdData,
  ]);

  const handleSystemTaskStatus = (type) => {
    setBtnType(type);
    setIsModelOpen(true);
  };
  const closeModelClick = () => {
    setBtnType(null);
    setIsModelOpen(false);
  };
  const onCommentRefresh = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
  };

  const onOrderClick = () => {
    const url = `/OrderDetails/${encryptUrlData(orderId)}`;
    window.open(url, "_blank");
  };

  const handleRedirect = () => {
    if (customerId) {
      window.open(
        `/customer/customerdetails/${encryptUrlData(customerId)}`,
        "_blank"
      );
    }
  };

  const handleRefreshBadge = (systemTaskDetails) => {
    if (isRefreshRef.current) {
      let request = {
        orderId: orderId,
        systemTaskDetails: systemTaskDetails
      }
      isRefreshRef.current.handleSave(request);
    }
  }

  return (
    <div className="system-task-desc">
      <CardSection>
        {isGetSystemTaskBadgeResponseBySystemTaskBadgeIdFetching ||
          isOrderDetailsFetching ||
          isGetDetailByCustomerIdFetching ? (
          <DataLoader />
        ) : (
          <div className="task-detail task-detail-sidebar">
            {systemTaskDetails && orderList.length > 0  && selectedBadgeId ? (<>
              <div className="task-header system-taskhead">
                <div className="task-profile">
                  <div className="profile-info">
                    <h2 className="task-title task-title-system-task">{systemTaskDetails?.subStatus}</h2>
                    <div className="task-meta">
                      <span className="generated-by">
                        Generated by <strong>{systemTaskDetails?.createdBy}</strong>
                      </span>
                      <span className="generated-date">
                        on {formatDate(systemTaskDetails?.createdAt, "MM/DD/YYYY hh:mm A")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="task-badge-status">
                  <div className="info-item">
                    <span className="info-label">Badge Status</span>
                    <span className={`status-badge ${getStatusColor(systemTaskDetails?.badgeStatus)}`}>{systemTaskDetails?.badgeStatus}</span>
                  </div>
                </div>
                  {systemTaskDetails.badgeStatus === 'Fail' &&
                      (systemTaskDetails?.orderSubStatusId === OrderBadges.INCOTERMS ||
                        systemTaskDetails?.orderSubStatusId === OrderBadges.PAYMENTTERMSNOTMATCHING ||
                        systemTaskDetails?.orderSubStatusId === OrderBadges.PENDINGCUSTOMER) ?
                        <div className="task-actions" >
                          <IconButton icon="material-symbols:refresh-rounded" size="small" shape="square" onClick={() => handleRefreshBadge(systemTaskDetails)}>
                          </IconButton>
                        </div>
                      : ""}
              </div>

              <div className="task-content">
                <div className="systemtask-main-sec">
                  
                  <div className="info-section">
                    <div className="info-grid">
                      <div className="info-item">
                        <span className="info-label">Customer Name</span>
                        <button className="btn info-value order-link" style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}
                          onClick={handleRedirect}>
                          {systemTaskDetails?.name || "N/A"}
                        </button>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Email</span>
                        <span className="info-value">{systemTaskDetails?.emailAddress}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Country</span>
                        <span className="info-value">{systemTaskDetails?.countryName}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Customer Status</span>
                        <span className={`status-badge ${getLabelClass(systemTaskDetails?.status)}`}>
                          {systemTaskDetails?.status}
                        </span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Order ID</span>
                        <button className="btn info-value order-link justify-content-left" style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}
                          onClick={onOrderClick}>
                          {orderId || "N/A"}
                        </button>
                      </div>
                      <div className="info-item">
                        <span className="info-label">PO Number</span>
                        <span className="info-value">{systemTaskDetails?.poNumber}</span>
                      </div>
                    </div>
                  </div>

                  <div className="badges-section">
                    <SystemBadges
                      systemTaskDetails={systemTaskDetails}
                      customerDetails={customerDetails}
                      orderDetails={orderDetails}
                      paymentTermDetail={paymentTermDetail}
                      customerId={customerId}
                      orderItemDetail={orderItemDetail}
                    />
                  </div>
                </div>
                <div className="task-actions">
                  {!(systemTaskDetails?.orderSubStatusId === OrderBadges.INCOTERMS ||
                    systemTaskDetails?.orderSubStatusId === OrderBadges.PAYMENTTERMSNOTMATCHING ||
                    systemTaskDetails?.orderSubStatusId === OrderBadges.PENDINGCUSTOMER) && (
                    <>
                      {(systemTaskDetails.badgeStatus === "Open" || systemTaskDetails.badgeStatus === "Pass") && (
                        <Button className="action-btn reject-btn" onClick={() => handleSystemTaskStatus('Fail')}>
                          Fail
                        </Button>
                      )}
                      {(systemTaskDetails.badgeStatus === "Open" || systemTaskDetails.badgeStatus === "Fail") && (
                        <Button className="action-btn accept-btn" onClick={() => handleSystemTaskStatus('Pass')}>
                          Pass
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <div className="comments-section">
                  <SystemComments systemTaskBadgeId={systemTaskDetails?.systemTaskBadgeId}
                    childRef={childRef} />
                </div>
              </div>
            </>
            ) : (
              <NoRecordFound />
            )}
          </div>
        )
        }
      </CardSection>

      <SidebarModel
        modalTitle="Comments"
        contentClass="content-35"
        onClose={closeModelClick}
        className="comments-sidebar"
        isOpen={isModelOpen}
        defaultPosition="left">
        <AddEditSystemTaskComments
          ref={isRefreshRef}
          btnType={btnType}
          isOpen={isModelOpen}
          onClose={closeModelClick}
          onSystemTaskDetailRefresh={onSystemTaskDetailRefresh}
          onBadgeRefresh={onBadgeRefresh}
          systemTaskBadgeId={selectedBadgeId}
          badgeStatus={systemTaskDetails?.badgeStatus}
          systemTaskId={systemTaskDetails?.systemTaskId}
          orderId={orderId}
          orderSubStatusId={systemTaskDetails?.orderSubStatusId}
          onCommentRefresh={onCommentRefresh}
        />
      </SidebarModel>

      {/* <CenterModel
        showModal={isModelOpen}
        className="center-model po-model"
        headerClassName="po-model-title"
        handleToggleModal={closeModelClick}
        modalTitle="System Task Comments"
        modelSizeClass="width-40"
      >

      </CenterModel> */}
    </div >
  );
};
export default SystemTasksDetails;
