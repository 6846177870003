
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const PoUploadAPI = createApi({
    reducerPath: "PoUploadAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addEditOrderByPoDocument: builder.mutation({
            query: (requestData) => ({
                url: '/Order/AddEditOrderByPoDocument',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getOrderDetailByPoDocument: builder.mutation({
            query: (userQuery) => ({
                url: '/Order/GetOrderDetailByPoDocument',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addPoOrderItemByOrderId: builder.mutation({
            query: (requestData) => ({
                url: '/Order/AddPoOrderItemByOrderId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addOrderItemByOrderId: builder.mutation({
            query: (requestData) => ({
                url: '/Order/AddOrderItemByOrderId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addAddressForOrder: builder.mutation({
            query: (requestData) => ({
                url: '/Address/AddAddressForOrder',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addContactForOrder: builder.mutation({
            query: (requestData) => ({
                url: '/Contact/AddContactForOrder',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        verifyBillingAddressByCustomerId: builder.mutation({
            query: (requestData) => ({
                url: '/Common/VerifyBillingAddressByCustomerId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        verifyShippingAddressByCustomerId: builder.mutation({
            query: (requestData) => ({
                url: '/Common/VerifyShippingAddressByCustomerId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        verifyInvoiceSubmissionContactByCustomerId: builder.mutation({
            query: (requestData) => ({
                url: '/Common/VerifyInvoiceSubmissionContactByCustomerId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        verifyEndUserByContactByCustomerId: builder.mutation({
            query: (requestData) => ({
                url: '/Common/VerifyEndUserByContactByCustomerId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        verifyPurchaseOrderContactByCustomerId: builder.mutation({
            query: (requestData) => ({
                url: '/Common/VerifyPurchaseOrderContactByCustomerId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updatePoOrderBasicInfoByOrderId: builder.mutation({
            query: (requestData) => ({
                url: '/Order/UpdatePoOrderBasicInfoByOrderId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addEditPoOrderAddressAndContactByOrderId: builder.mutation({
            query: (requestData) => ({
                url: '/Order/AddEditPoOrderAddressAndContactByOrderId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        })
    })
})

export const {
    useAddEditOrderByPoDocumentMutation,
    useGetOrderDetailByPoDocumentMutation,
    useAddPoOrderItemByOrderIdMutation,
    useAddAddressForOrderMutation,
    useAddContactForOrderMutation,
    useUpdatePoOrderBasicInfoByOrderIdMutation,
    useAddOrderItemByOrderIdMutation,

    useVerifyBillingAddressByCustomerIdMutation,
    useVerifyShippingAddressByCustomerIdMutation,
    useVerifyInvoiceSubmissionContactByCustomerIdMutation,
    useVerifyEndUserByContactByCustomerIdMutation,
    useVerifyPurchaseOrderContactByCustomerIdMutation,

    useAddEditPoOrderAddressAndContactByOrderIdMutation

} = PoUploadAPI

export default PoUploadAPI;