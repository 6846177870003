/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";
import FormCreator from "../../../components/FinalForms/FormCreator";
import Buttons from "../../../components/ui/button/Buttons";
import { AddEditCustomFormsFormData } from "../config/CustomForms.data";
import { onResetForm } from "../../../utils/FormFields/ResetForm/handleResetForm";
import { getValue } from "../../../utils/CommonUtils/CommonUtilsMethods";
import { useAddEditCustomFormMutation, useLazyGetCustomFormsByCustomFormIdQuery } from "../../../app/services/customFormsAPI";
import ToastService from "../../../services/toastService/ToastService";
import { useLazyGetAllModulesQuery } from "../../../app/services/configurationAPI";
import { setDropDownOptionField } from "../../../utils/FormFields/FieldsSetting/SetFieldSetting";


const AddEditCustomForms = ({ onClose, isOpen, isEdit, customFormId,onGetData ,isGetdetails}) => {

    const formRef = useRef();
    const [formData, setFormData] = useState(AddEditCustomFormsFormData);
    const [addEditCustomForm, { isLoading: isAddEditCustomFormLoading, isSuccess: isAddEditCustomFormSuccess, data: allAddEditCustomFormData, },] = useAddEditCustomFormMutation();

    const [getAllModules, { isSuccess: isgetAllModulesSucess, data: allGetAllModulesData },] = useLazyGetAllModulesQuery();

    const [getCustomFormsByCustomFormId, { isFetching: isGetCustomFormsByCustomFormIdFetching, isSuccess: isGetCustomFormsByCustomFormIdSuccess, data: GetCustomFormsByCustomFormIdData, },] = useLazyGetCustomFormsByCustomFormIdQuery();

    useEffect(() => {
        getAllModules()
    }, [isOpen])

    useEffect(() => {
        if (isgetAllModulesSucess && allGetAllModulesData) {
            setDropDownOptionField(allGetAllModulesData, 'moduleId', "moduleName", AddEditCustomFormsFormData, 'moduleId');
        }
    }, [isgetAllModulesSucess, allGetAllModulesData]);

    useEffect(() => {
        if (customFormId) {
            getCustomFormsByCustomFormId(customFormId);
        }
    }, [customFormId]);

    useEffect(() => {
        if (isGetCustomFormsByCustomFormIdSuccess && GetCustomFormsByCustomFormIdData && !isGetCustomFormsByCustomFormIdFetching) {
            const newFrom = { ...formData };
            const splitModuleIds = GetCustomFormsByCustomFormIdData.moduleId
                ? GetCustomFormsByCustomFormIdData.moduleId.split(",").map(Number)
                : [];
            newFrom.initialState = {
                formName: GetCustomFormsByCustomFormIdData.formName,
                description: GetCustomFormsByCustomFormIdData.formDescription,
                moduleId: splitModuleIds,
                isActive: GetCustomFormsByCustomFormIdData.isActive,
                customFormId: GetCustomFormsByCustomFormIdData.customFormId,
            };
            setFormData(newFrom);
        }
    }, [isGetCustomFormsByCustomFormIdSuccess, GetCustomFormsByCustomFormIdData, isGetCustomFormsByCustomFormIdFetching]);

    useEffect(() => {
        if (isAddEditCustomFormSuccess && allAddEditCustomFormData) {
            if (allAddEditCustomFormData.errorMessage.includes('exists')) {

                ToastService.warning(allAddEditCustomFormData.errorMessage);
                return;
              }
            ToastService.success(allAddEditCustomFormData.errorMessage);
            onResetForm(AddEditCustomFormsFormData, setFormData, null);
       
            !isGetdetails && onGetData();
            isGetdetails && getCustomFormsByCustomFormId(customFormId)
            onClose();
        }
    }, [isAddEditCustomFormSuccess, allAddEditCustomFormData]);

    const handleSaveClick = () => {

        const customData = formRef.current.getFormData();
        if (formData) {
            const request = {
                customFormId:customFormId || 0,
                formName: customData?.formName,
                formDescription: customData?.description,
                moduleIds: getValue(customData?.moduleId.join(',')),
                isActive: customData?.isActive,
            }
            addEditCustomForm(request)
        }
    }

    const handleResetAndClose = () => {
        onResetForm(AddEditCustomFormsFormData, setFormData, null);
        onClose();
    };

    return (<div className="row">
        <div className="col-md-12">
            <div className="vertical-form">
                <FormCreator ref={formRef} config={formData} />
            </div>
        </div>
        <div className="col-md-12 mt-2">
            <div className="d-flex align-item-center justify-content-end">
                <Buttons
                    buttonTypeClassName="theme-button"
                    buttonText={isEdit ? "Update" : "Save"}
                    onClick={handleSaveClick}
                    isLoading={isAddEditCustomFormLoading}
                />
                <Buttons
                    buttonTypeClassName="dark-btn ml-5"
                    buttonText="Cancel"
                    onClick={handleResetAndClose}
                />
            </div>
        </div>
    </div>)
}
export default AddEditCustomForms;