/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { AddEditParameterMappingConfigurationData, AddEditParameterMappingData } from "../config/AddEditParameterMapping.data";
import { onResetForm } from "../../../../../../../../utils/FormFields/ResetForm/handleResetForm";
import { setDropDownOptionField } from "../../../../../../../../utils/FormFields/FieldsSetting/SetFieldSetting";
import Buttons from "../../../../../../../../components/ui/button/Buttons";
import FormCreator from "../../../../../../../../components/FinalForms/FormCreator";
import ToastService from "../../../../../../../../services/toastService/ToastService";
import {
  useAddApiParameterMappingMutation,
  useDeleteApiParameterMappingMutation,
  useGetApiParameterMappingsMutation,
  useLazyGetAllAPIParametersByEndpointIdQuery,
  useLazyGetAllEventParameterByEventIdQuery,
} from "../../../../../../../../app/services/thirdPartyAPI";
import PropTypes from "prop-types";
import FinalMolGrid from "../../../../../../../../components/FinalMolGrid/FinalMolGrid";
import SwalAlert from "../../../../../../../../services/swalService/SwalService";

const AddEditParameterMapping = (props) => {
   
  const addEditParameterMappingRef = useRef();
  const molGridRef = useRef();
  const [listData, setListData] = useState();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const { confirm } = SwalAlert();


  const [addEditParameterMappingData, setAddEditParameterMappingData] =useState(AddEditParameterMappingData);
  const [getParametersByEndPointId,{ isSuccess: isGetParametersSucess, data: isGetParametersData },] = useLazyGetAllAPIParametersByEndpointIdQuery();
  const [getEventParameterByEventId,{ isSuccess: isGetEventParametersSucess, data: isGetEventParametersData },] = useLazyGetAllEventParameterByEventIdQuery();
  const [getApiParameterMappings, { isLoading: isGetApiParameterMappingsLoading, isSuccess: isGetApiParameterMappingsSuccess, data: isGetApiParameterMappingsData }] = useGetApiParameterMappingsMutation();

  const [addApiParameterMapping,{isLoading: isAddApiParameterMappingLoading,isSuccess: isAddApiParameterMappingSuccess,data: allAddApiParameterMappingData,},] = useAddApiParameterMappingMutation();
  const [deleteApiParameterMapping, { isSuccess: isDeleteApiParameterMappingSuccess, data: isDeleteApiParameterMappingData },] = useDeleteApiParameterMappingMutation();

  useEffect(() => {
    props.keyId && getEventParameterByEventId(props.keyId);
    props.initData.endpointId && getParametersByEndPointId(props.initData.endpointId);
  }, [props.initData.endpointId,props.keyId]);

  useEffect(() => {
    if (isGetEventParametersSucess && isGetEventParametersData) {
      setDropDownOptionField(
        isGetEventParametersData,
        "parameterId",
        "name",
        AddEditParameterMappingData,
        "eventParameterId"
      );
    }
  }, [isGetEventParametersSucess, isGetEventParametersData]);

  useEffect(() => {
    if (isGetParametersSucess && isGetParametersData) {
        setDropDownOptionField(
        isGetParametersData,
        "parameterId",
        "name",
        AddEditParameterMappingData,
        "providerParameterId"
      );
    }
  }, [isGetParametersSucess, isGetParametersData]);

  useEffect(() => {
    if (isGetApiParameterMappingsSuccess && isGetApiParameterMappingsData) {
      setListData(isGetApiParameterMappingsData.dataSource);
      if (isGetApiParameterMappingsData.totalRecord) {
        setTotalRowCount(isGetApiParameterMappingsData.totalRecord);
      }
    }
  }, [isGetApiParameterMappingsSuccess, isGetApiParameterMappingsData]);

  useEffect(() => {
    if (isDeleteApiParameterMappingSuccess && isDeleteApiParameterMappingData) {
        ToastService.success(isDeleteApiParameterMappingData.errorMessage);
        const currentPageObject = molGridRef.current.getCurrentPageObject();
        getLists(currentPageObject, molGridRef.current.generateSortingString());
    }
}, [isDeleteApiParameterMappingSuccess, isDeleteApiParameterMappingData]);

const getLists = (pageObject, sortingString) => {
  const request = {
    pagination: {
      pageNumber: pageObject.pageNumber,
      pageSize: pageObject.pageSize,
    },
    filters: { searchText: "" },
    sortString: sortingString,
    apiEventId: props.keyId,
    apiEndpointId: props.initData.endpointId,
  
  };
  getApiParameterMappings(request);
};

  useEffect(() => {
    onGetData();
  }, [props.initData.endpointId,props.keyId]);

  const handlePageChange = (page) => {
    getLists(page);
  };

  const handleSorting = (shortString) => {
    getLists(molGridRef.current.getCurrentPageObject(), shortString);
  };
  const onGetData = () => {
    if (molGridRef.current) {
      const defaultPageObject = molGridRef.current.getCurrentPageObject();
      getLists(defaultPageObject, molGridRef.current.generateSortingString());
    }
  };

  useEffect(() => {
    if (isAddApiParameterMappingSuccess && allAddApiParameterMappingData) {
      if (allAddApiParameterMappingData.errorMessage.includes("exists")) {
        ToastService.warning(allAddApiParameterMappingData.errorMessage);
        return;
      }
      ToastService.success(allAddApiParameterMappingData.errorMessage);
      onResetForm(AddEditParameterMappingData, setAddEditParameterMappingData, null);
      onGetData();
    }
  }, [isAddApiParameterMappingSuccess, allAddApiParameterMappingData]);

  const handleAddEditAPIPRovider = () => {
    const formData = addEditParameterMappingRef.current.getFormData();
    if (formData) {
      let request = {
        ...formData,
        eventParameterId: formData.eventParameterId.value,
        providerParameterId: formData.providerParameterId.value,
        apiEventId: props.keyId,
        endpointId : props.initData.endpointId
      };
      addApiParameterMapping(request);
    }
  };

  useEffect(() => {
    onResetForm(
      addEditParameterMappingData,
      setAddEditParameterMappingData,
      null
    );
  }, [props.isOpen]);

  const handleResetAndClose = () => {
    onResetForm(
      addEditParameterMappingData,
      setAddEditParameterMappingData,
      null
    );
    props.onClose();
  };

  const handleDeleteClick = (data) => {
    confirm("Delete?", "Are you sure you want to Delete?", "Delete", "Cancel"
    ).then((confirmed) => {
        if (confirmed) {
            deleteApiParameterMapping(data.apiParameterMappingId);
        }
    });
};
  const actionHandler = {  
    DELETE: handleDeleteClick,
  };

  return (
    <div className="row mt-2 add-address-form">
      <div className="col-12">
        <FormCreator
          config={addEditParameterMappingData}
          ref={addEditParameterMappingRef}
        />
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex align-item-end justify-content-end">
          <Buttons
            buttonTypeClassName="theme-button"
            buttonText="Save"
            onClick={handleAddEditAPIPRovider}
            isLoading={isAddApiParameterMappingLoading}
          />
          <Buttons
            buttonTypeClassName="dark-btn ml-5"
            buttonText="Cancel"
            onClick={handleResetAndClose}
          />
        </div>
      </div>
          <div className="col-md-12 table-striped api-provider mt-3">
            <FinalMolGrid
              ref={molGridRef}
              configuration={AddEditParameterMappingConfigurationData}
              dataSource={listData}
              allowPagination={false}
              pagination={{
                totalCount: totalRowCount,
                pageSize: 20,
                currentPage: 1,
              }}
              onPageChange={handlePageChange}
              onSorting={handleSorting}
              isLoading={isGetApiParameterMappingsLoading}
              onActionChange={actionHandler}
            />
          </div>
    </div>
  );
};

AddEditParameterMapping.propTypes = {
  keyId: PropTypes.number,
  endpointId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onGetData: PropTypes.func,
};
export default AddEditParameterMapping;
