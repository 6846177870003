
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const workFlowAPI = createApi({
    reducerPath: "workFlowAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addEditWorkflow: builder.mutation({
            query: (requestData) => ({
                url: '/Workflow/AddEditWorkflow',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addEditWorkflowAction: builder.mutation({
            query: (requestData) => ({
                url: '/Workflow/AddEditWorkflowAction',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        addEditWorkflowStep: builder.mutation({
            query: (requestData) => ({
                url: '/Workflow/AddEditWorkflowStep',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getWorkflowStepsListByWorkflowId: builder.mutation({
            query: (requestData) => ({
                url: '/Workflow/GetWorkflowStepsListByWorkflowId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getWorkflowActionListsByStepId: builder.mutation({
            query: (requestData) => ({
                url: '/Workflow/GetWorkflowActionListsByStepId',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getWorkflowDetailsById: builder.query({
            query: (systemTaskConfigurationId) => ({
                url: encryptQueryString(`/Workflow/GetWorkflowDetailsById/?systemTaskConfigurationId=${Number(systemTaskConfigurationId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getWorkflowStepByStepId: builder.query({
            query: (stepId) => ({
                url: encryptQueryString(`/Workflow/GetWorkflowStepByStepId/?stepId=${Number(stepId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getWorkflowStepByWorkflowId: builder.query({
            query: (workflowId) => ({
                url: encryptQueryString(`/Common/GetWorkflowStepByWorkflowId/?workflowId=${Number(workflowId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getWorkflowActionByActionId: builder.query({
            query: (actionId) => ({
                url: encryptQueryString(`/Workflow/GetWorkflowActionByActionId/?actionId=${Number(actionId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAllCustomForms: builder.query({
            query: () => ({
                url:'/Common/GetAllCustomForms',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        
    })
})

export const {
     useAddEditWorkflowMutation, useAddEditWorkflowActionMutation,useAddEditWorkflowStepMutation,useGetWorkflowStepsListByWorkflowIdMutation,useGetWorkflowActionListsByStepIdMutation,useLazyGetWorkflowDetailsByIdQuery,
     useLazyGetWorkflowStepByStepIdQuery,useLazyGetWorkflowStepByWorkflowIdQuery,useLazyGetWorkflowActionByActionIdQuery,useLazyGetAllCustomFormsQuery
} = workFlowAPI

export default workFlowAPI;