/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from "react";
import CardSection from "../../components/ui/card/CardSection";
import SystemTaskConfigurationList from "./feature/SystemTaskConfigurationList";
import { encryptUrlData } from "../../services/CryptoService";
import { useNavigate } from "react-router-dom";

const SystemTaskConfiguration = () => {
  const getDataRef = useRef();
  const navigate = useNavigate();

  const handleEditClick = (data) => {
    // setSystemTaskId(data.systemTaskConfigurationId)
    // setIsModelOpen(true);
    navigate(
      `/configuration/systemTaskConfiguration/${encryptUrlData(
        data.systemTaskConfigurationId
      )}`
    );
  };

  return (
    <div className="system-task-config">
      <CardSection
        cardTitle="System Task Configuration"
        buttonClassName="btn theme-button"
      >
        <SystemTaskConfigurationList
          handleEditClick={handleEditClick}
          getDataRef={getDataRef}
        />
      </CardSection>
    </div>
  );
};

export default SystemTaskConfiguration;
