import { createApi } from "@reduxjs/toolkit/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';

const emailActionsAPI = createApi({
    reducerPath: 'emailActionsAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addEditEmailActions: builder.mutation({
            query: (data) => ({
                url: '/EmailAction/AddEditEmailActions',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getEmailAction: builder.mutation({
            query: (userQuery) => ({
                url: '/EmailAction/GetEmailAction',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getEmailActionById: builder.query({
            query: (emailActionId) => ({
                url: encryptQueryString(`/EmailAction/GetEmailActionById/?emailActionId=${Number(emailActionId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteEmailAction: builder.mutation({
            query: (emailActionId) => ({
                url: encryptQueryString(`/EmailAction/DeleteEmailAction/?emailActionId=${emailActionId}`),
                method: 'DELETE',
                body: transformRequest(emailActionId)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getCustomerByEmail: builder.query({
            query: (emailAddress) => ({
                url: encryptQueryString(`/EmailInbox/GetCustomerByEmail/?emailAddress=${emailAddress}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        replyEmail: builder.mutation({
            query: (requestData) => ({
                url: `/EmailAction/ReplyEmail`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        downloadAttachmentForEmailInbox: builder.mutation({
            query: (requestData) => ({
                url: '/EmailInbox/DownloadAttachmentForEmailInbox',
                method: 'POST',
                body: transformRequest(requestData),
                responseHandler: (response) => response.blob()
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        })
    })
})

export const { useAddEditEmailActionsMutation, useGetEmailActionMutation,
    useLazyGetEmailActionByIdQuery,
    useDeleteEmailActionMutation, useLazyGetCustomerByEmailQuery, useReplyEmailMutation,
    useDownloadAttachmentForEmailInboxMutation,
} = emailActionsAPI;

export default emailActionsAPI;