import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";
import PropTypes from "prop-types";

const PurchaseOrderEmails=()=>{
    return(  <EmailManagement emailIntentId={EmailInboxTypesEnum.PURCHASEORDER} intentName="Purchase Order Emails" />)
}
EmailManagement.propTypes = {
    emailIntentId: PropTypes.oneOf(Object.values(EmailInboxTypesEnum)).isRequired,
    intentName: PropTypes.string.isRequired,
  };
export default PurchaseOrderEmails;