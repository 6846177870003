/* eslint-disable react-hooks/exhaustive-deps */
import CardSection from "../../../components/ui/card/CardSection";
import { FirstSecondLetter } from "../../../utils/FirstSecLetter/FirstSecondLetter";
import NoRecordFound from "../../../components/ui/noRecordFound/NoRecordFound";
import DataLoader from "../../../components/ui/dataLoader/DataLoader";


const SystemTaskOrderList = ({ isLoading,orderList, selectedId, setSelectedId, setCustomerId, setOrderId }) => {

  const handleClick = (data) => {
    setSelectedId(data.systemTaskId);
    setCustomerId(data.customerId)
    setOrderId(data.orderId)
  }

  return (
    <CardSection cardTitle="Orders">
      { !isLoading? 
       <div className="customer-info">
          <div className="tabs">
            {orderList && orderList.length > 0 ? (
              orderList.map((item, index) => (
                <button
                  key={item.systemTaskId} // Added key prop for React list rendering
                  className={`tab-button ${selectedId === item.systemTaskId ? "active" : ""}`}
                  onClick={() => handleClick(item)}
                >
                  <div className="d-flex align-items-start w-100">
                    <span className="profile-icon">
                      {FirstSecondLetter(item.name)}
                    </span>
                    <div className="right-name-desc">
                      <div className="title">
                        {item.name}

                      </div>
                      <div className="badge-fix d-flex">
                      <div className="order-po">
                        <span className="title mr-5">Po : <span className="ml-2">{item?.poNumber}</span></span>
                        </div>
                       <div className="badge-order-id"><span className="title ml-3">Order Id : {item?.orderId}</span></div> 
                      </div>
                    </div>
                  </div>
                </button>
              ))
            ) : (
              <NoRecordFound />
            )}
          </div>
        </div>: <DataLoader />
      }
    </CardSection>
  )

}
export default SystemTaskOrderList;