import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";

const supplierWareHouseAPI = createApi({
    reducerPath: 'supplierWareHouseAPI',
    baseQuery: customFetchBase,
    tagTypes: ['SupplierWareHouse'],
    endpoints: (builder) => ({

        getSupplierWarehouse: builder.mutation({
            query: (requestData) => ({
                url: '/SupplierWareHouse/GetSupplierWarehouse',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addEditSupplierWarehouse: builder.mutation({
            query: (requestData) => ({
                url: '/SupplierWareHouse/AddEditSupplierWarehouse',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getSupplierWarehouseById: builder.query({
            query: (requestId) => ({
                url: encryptQueryString(`/SupplierWareHouse/GetSupplierWarehouseById/?supplierWarehouseId=${Number(requestId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllSuppliers: builder.query({
            query: (isWarehouse) => ({
                url: encryptQueryString(`/SupplierWareHouse/GetAllSuppliers/?isWarehouse=${isWarehouse}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        deleteSupplierWareHouse: builder.mutation({
            query: (supplierWarehouseId) => ({
                url: encryptQueryString(`/SupplierWareHouse/DeleteSupplierWareHouse/?supplierWarehouseId=${supplierWarehouseId}`),
                method: 'DELETE',
                body: transformRequest(supplierWarehouseId)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    })
})

export const {
    useGetSupplierWarehouseMutation, useAddEditSupplierWarehouseMutation,
    useLazyGetSupplierWarehouseByIdQuery, useLazyGetAllSuppliersQuery , useDeleteSupplierWareHouseMutation
} = supplierWareHouseAPI;

export default supplierWareHouseAPI;