import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";
import PropTypes from "prop-types";

const QuatationEmails=()=>{

    return(  <EmailManagement emailIntentId={EmailInboxTypesEnum.QUOTATION} intentName="Quotation Emails"/>)
}
EmailManagement.propTypes = {
    emailIntentId: PropTypes.oneOf(Object.values(EmailInboxTypesEnum)).isRequired, 
    intentName: PropTypes.string.isRequired,
  };
export default QuatationEmails;