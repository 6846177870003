import { useState,useRef } from "react";
import CardSection from "../../../components/ui/card/CardSection";
import SidebarModel from "../../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../../data/appIcons";
import { onResetForm } from "../../../utils/FormFields/ResetForm/handleResetForm";
import SystemConstraintsList from "./features/SystemConstraintsList";
import AddEditSystemConstraints from "./features/AddEditSystemConstraints";
import { SystemConstraintsFormData } from "./config/SystemConstraints.Data";
import { useEffect } from "react";
 
const SystemConstraints = () => {
  const getDataRef=useRef();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(SystemConstraintsFormData);
  const [search, setSearch] = useState("");

  const handleToggleModal = () => {
    onResetForm(SystemConstraintsFormData,setFormData, null);
    setIsModelOpen(true);
 
  };

  const onSidebarClose = () => {
    setIsModelOpen(false);
    setIsEdit(false);
  };

  const handleEditClick = (data) => {
    onResetForm(SystemConstraintsFormData,setFormData, null);
    setIsModelOpen(true);
    setFormData(data);
    setIsEdit(true);  
  };

  const onSuccess = () => {
    if (getDataRef.current) {
      getDataRef.current.callChildFunction();
    }
  };

  useEffect(() => {
    if (search.length > 0 ) {
      onSuccess();
    }
  }, [search]);

  const handleChange = (event) => {
    setSearch(event.target.value);
    // if(event.target.value.length > 0){
    //   onSuccess();
    // }
  };



  const handleClear = () => {
    setSearch("");   
  };

  return (
    <div className="system-constraints">
      <CardSection
        cardTitle="System Constraints"
        buttonClassName="btn theme-button"
        rightButton={true}
        textWithIcon={true}
        iconImg={AppIcons.PlusIcon}
        titleButtonClick={handleToggleModal}
        handleChange={handleChange}
        searchInputName="Search By Key"
        searchInput={true}
        clearButton={true}
        clearTitleButtonClick={handleClear}
        clearButtonText="Clear"
        clearButtonClassName="dark-btn"
        clearTextWithIcon={true}
        clearIconImg={AppIcons.ClearIcone}
        searchValue={search}
      >
        <SystemConstraintsList
          getDataRef={getDataRef}
          handleEditClick={handleEditClick}
          initData={formData}
          handleChange={handleChange}
          search={search}
          handleClear={handleClear}
        />
      </CardSection>

      <SidebarModel
        modalTitle={
          isEdit ? "Update System Constraints " : "Add System Constraints"
        }
        contentClass="content-40"
        onClose={onSidebarClose}
        modalTitleIcon={AppIcons.AddIcon}
        isOpen={isModelOpen}
      >
        <AddEditSystemConstraints
          isEdit={isEdit}
          initData={formData}
          onClose={onSidebarClose}
          onSuccess={onSuccess}
          isModelOpen={isModelOpen}     
        /> 
      </SidebarModel>
    </div>
  );
};

export default SystemConstraints;
