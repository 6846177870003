
import SystemTaskOrderItemList from "./SystemTaskOrderItemList";

const Replacement = ({orderItemDetail}) => {
    console.log(orderItemDetail)
    return (
<div className="mb-3">
     
    <div className="row mb-2">
            <SystemTaskOrderItemList orderItemDetail={orderItemDetail} />
   </div>
  
    {/* <div className="row mb-2">
        <div className="col-md-4 d-flex align-items-center">
            <span className="info-label fw-bold">
               Chemical Name :{" "}
            </span>
            <span className="info-value ml-2">
            {orderItemDetail?.chemicalName ? orderItemDetail?.chemicalName : "N/A"}
            </span>
        </div>
        <div className="col-md-4 d-flex align-items-center">
            <span className="info-label fw-bold mr-1">
                CAS No:{" "}
            </span>
            <span >
                {orderItemDetail?.casNumber ? orderItemDetail?.badgeStatus : "N/A"}
            </span>
        </div>
        
    </div>
    <div className="row mb-2">
    <div className="col-md-4 d-flex align-items-center">
            <span className="info-label fw-bold mr-1">
               Catalog Id :{" "}
            </span>
            <span >
                {orderItemDetail?.catalogId ? orderItemDetail?.catalogId : "N/A"}
            </span>
        </div>
        <div className="col-md-4 d-flex align-items-center">
            <span className="info-label fw-bold">
               Unit Price :{" "}
            </span>
            <span className="info-value ml-2">
            {orderItemDetail?.itemUnitPrice ? orderItemDetail?.itemUnitPrice : "N/A"}
            </span>
        </div>
        <div className="col-md-4 d-flex align-items-center">
            <span className="info-label fw-bold mr-1">
                Pack Size:{" "}
            </span>
            <span >
                {orderItemDetail.quantity ? `${orderItemDetail?.quantity}*${orderItemDetail?.packSize} ${orderItemDetail?.unit}` : "N/A"}
            </span>
        </div>
        
    </div> */}
    </div>
    )
}
export default Replacement;