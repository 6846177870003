/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { WorkflowActionsGridConfig, addEditWorkflowActionFormData } from "./config/WorkflowAction.data";
import FormCreator from "../../../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../../../components/ui/button/Buttons";
import { useAddEditWorkflowActionMutation, useGetWorkflowActionListsByStepIdMutation, useLazyGetAllCustomFormsQuery, useLazyGetWorkflowActionByActionIdQuery, useLazyGetWorkflowStepByWorkflowIdQuery } from "../../../../../../app/services/workFlowAPI";
import { getFieldData, setDropDownOptionField } from "../../../../../../utils/FormFields/FieldsSetting/SetFieldSetting";
import FinalMolGrid from "../../../../../../components/FinalMolGrid/FinalMolGrid";
import ToastService from "../../../../../../services/toastService/ToastService";
import { onResetForm } from "../../../../../../utils/FormFields/ResetForm/handleResetForm";
import { getValue } from "../../../../../../utils/CommonUtils/CommonUtilsMethods";
import { ActionTypes, ExecutionTypes } from "../../../../../../utils/Enums/commonEnums";
import { removeFormFields } from "../../../../../../utils/FormFields/RemoveFields/handleRemoveFields";


const AddEditWorkflowAction = (props) => {
  const ref = useRef();
  const molGridRef = useRef();
  const [actionId, setActionId] = useState(null)
  const [listData, setListData] = useState();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [formData, setFormData] = useState(addEditWorkflowActionFormData);
  const [addEditWorkflowAction, { isLoading: isAddEditWorkflowActionLoading, isSuccess: isAddEditWorkflowActionSuccess, data: allAddEditWorkflowActionData, },] = useAddEditWorkflowActionMutation();
  const [shouldRerenderFormCreator, setShouldRerenderFormCreator] = useState(false);
  const [getWorkflowStepByWorkflowId, { isFetching:isGetWorkflowStepByWorkflowIdFetching,isSuccess: isGetWorkflowStepByWorkflowIdSuccess, data: isGetWorkflowStepByWorkflowIdData, },] = useLazyGetWorkflowStepByWorkflowIdQuery();
  const [getWorkflowActionByActionId, { isFetching:isGetWorkflowActionByActionIdFetching ,isSuccess: isGetWorkflowActionByActionIdSuccess, data: isGetWorkflowActionByActionIdData, },] = useLazyGetWorkflowActionByActionIdQuery();

  const [
    getWorkflowActionListsByStepId,
    {
      isLoading: isGetWorkflowActionListsByStepIdLoading,
      isSuccess: isGetWorkflowActionListsByStepIdSuccess,
      data: isGetWorkflowActionListsByStepIdData,
    },
  ] = useGetWorkflowActionListsByStepIdMutation();
  const [
    getAllCustomForms,
    {
      isFetching: isGetAllCustomFormsFetching,
      isSuccess: isGetAllCustomFormsSuccess,
      data: isGetAllCustomFormsData,
    },
  ] = useLazyGetAllCustomFormsQuery();

  useEffect(() => {
    if (props.isModelOpen) {
      onResetForm(addEditWorkflowActionFormData, setFormData, null);
      removeFields();
    }
  }, [props.isModelOpen])


  const removeFields=()=>{
    const modifyFormFields = removeFormFields(addEditWorkflowActionFormData, ["customFormId"]);
    if (props.stepID) {
      modifyFormFields.initialState = {
        ...modifyFormFields.initialState,
        parentStepID: props.stepID
      };
    }
    setFormData(modifyFormFields);
  }
  useEffect(() => {
    onGetData(); 
    if (props.workFlowId && props.stepID) {
      getWorkflowStepByWorkflowId(props.workFlowId);
    }
  }, [props.isModelOpen, props.workFlowId, props.stepID])

  useEffect(() => {
    const dropdownField = getFieldData(addEditWorkflowActionFormData, "executionType");
    dropdownField.fieldSetting.options = Object.entries(ExecutionTypes).map(([key, value]) => ({
      label: value,
      value: value,
    }));
  }, [])

  useEffect(() => {
    const dropdownField = getFieldData(addEditWorkflowActionFormData, "actionType");
    dropdownField.fieldSetting.options = Object.entries(ActionTypes).map(([key, value]) => ({
      label: value,
      value: value,
    }));
  }, [])

  useEffect(() => {
    if (!isGetAllCustomFormsFetching && isGetAllCustomFormsSuccess && isGetAllCustomFormsData) {
      setDropDownOptionField(isGetAllCustomFormsData, "customFormId", "formName", formData, "customFormId", null, setFormData);
      
    }
  }, [isGetAllCustomFormsFetching, isGetAllCustomFormsSuccess, isGetAllCustomFormsData]);
  useEffect(() => {
    
    if (!isGetWorkflowStepByWorkflowIdFetching && isGetWorkflowStepByWorkflowIdSuccess && isGetWorkflowStepByWorkflowIdData) { 
      setDropDownOptionField(isGetWorkflowStepByWorkflowIdData, "stepId", "stepName", formData, "parentStepID", null, setFormData);
      setDropDownOptionField(isGetWorkflowStepByWorkflowIdData, "stepId", "stepName", formData, "nextStepID", null, setFormData);
    }
  }, [isGetWorkflowStepByWorkflowIdFetching,isGetWorkflowStepByWorkflowIdSuccess, isGetWorkflowStepByWorkflowIdData]);

  useEffect(() => {
    if (isGetWorkflowActionListsByStepIdSuccess && isGetWorkflowActionListsByStepIdData) {
      if (isGetWorkflowActionListsByStepIdData) {
        setListData(isGetWorkflowActionListsByStepIdData.dataSource);
      }
      if (isGetWorkflowActionListsByStepIdData.totalRecord) {
        setTotalRowCount(isGetWorkflowActionListsByStepIdData.totalRecord);
      }
    }
  }, [isGetWorkflowActionListsByStepIdSuccess, isGetWorkflowActionListsByStepIdData]);

  

  useEffect(() => {
    if (!isGetWorkflowActionByActionIdFetching && isGetWorkflowActionByActionIdSuccess && isGetWorkflowActionByActionIdData) {
      if (isGetWorkflowActionByActionIdData) {
        let newForm = { ...formData };
        newForm.initialState = {
          actionId: isGetWorkflowActionByActionIdData.actionId,
          actionName: isGetWorkflowActionByActionIdData?.actionName,
          actionType: isGetWorkflowActionByActionIdData?.actionType,
          executionType: isGetWorkflowActionByActionIdData?.executionType,
          instructions: isGetWorkflowActionByActionIdData?.instructions,
          parameters_JSON: isGetWorkflowActionByActionIdData?.parameters_JSON,
          parentStepID: isGetWorkflowActionByActionIdData?.parentStepID,
          nextStepID: isGetWorkflowActionByActionIdData?.nextStepID,
          customFormId: isGetWorkflowActionByActionIdData?.customFormId
        }
        setFormData(newForm)
        setShouldRerenderFormCreator((prevState) => !prevState);
      }
    }
  }, [isGetWorkflowActionByActionIdFetching,isGetWorkflowActionByActionIdSuccess, isGetWorkflowActionByActionIdData]);


  useEffect(() => {
    if (isAddEditWorkflowActionSuccess && allAddEditWorkflowActionData) {
      ToastService.success(allAddEditWorkflowActionData.errorMessage);
      onResetForm(addEditWorkflowActionFormData, setFormData, null);
      removeFields();
      onGetData();
      setActionId(null)

    }
  }, [isAddEditWorkflowActionSuccess, allAddEditWorkflowActionData]);

  const handleAddClick = () => {
    const FormData = ref.current.getFormData();
    if (FormData) {
      let request = {
        actionId: actionId || 0,
        actionName: FormData?.actionName,
        actionType: getValue(FormData?.actionType),
        executionType: getValue(FormData?.executionType),
        instructions: FormData?.instructions,
        parameters_JSON: FormData?.parameters_JSON,
        parentStepID: getValue(FormData?.parentStepID),
        nextStepID: FormData?.nextStepID ? getValue(FormData?.nextStepID) : null,
        customFormId: Number(getValue(FormData?.customFormId)) || 0,

      };
      addEditWorkflowAction(request);
    }
  }

  const getLists = (pageObject, sortingString) => {
    const request = {
      pagination: {
        pageNumber: pageObject.pageNumber,
        pageSize: pageObject.pageSize,
      },
      filters: { searchText: "" },
      sortString: sortingString,
      stepId: props.stepID
    };

    getWorkflowActionListsByStepId(request);
  };

  const handlePageChange = (page) => {
    getLists(page);
  };

  const handleSorting = (shortString) => {
    getLists(molGridRef.current.getCurrentPageObject(), shortString);
  };
  const onGetData = () => {
    if (molGridRef.current) {
      const defaultPageObject = molGridRef.current.getCurrentPageObject();
      getLists(defaultPageObject, molGridRef.current.generateSortingString());
    }
  };


  const handleResetAndClose = () => {
    onResetForm(addEditWorkflowActionFormData, setFormData, null);
    props.onClose();
    setActionId(null)
  };
  const handleEditClick = (data) => {
    setActionId(data.actionId)
    let formData = { ...addEditWorkflowActionFormData }
    if (data.actionType === ActionTypes.CustomForms) {
      setFormData(formData);
      getAllCustomForms();
    } else {
      formData = removeFormFields(formData, ["customFormId"]);
      setFormData(formData);
    }
    getWorkflowActionByActionId(data.actionId)
  }

  const actionHandler = {
    EDIT: handleEditClick,

  };
  const handleChange = (dataField, updatedData) => {
    
    let manageData = { ...addEditWorkflowActionFormData };
    manageData.initialState = { ...updatedData };
    if (dataField === "actionType") { 
      if (updatedData.actionType.value === ActionTypes.CustomForms) {
        setFormData(manageData);
        getAllCustomForms();

      } else {
        manageData = removeFormFields(manageData, ["customFormId"]);
        setFormData(manageData);
      }
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="vertical-form">
            <FormCreator ref={ref} config={formData} {...formData} onColumnChange={handleChange}      key={shouldRerenderFormCreator} />
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="d-flex align-item-center justify-content-end">
            <Buttons
              buttonTypeClassName="theme-button"
              buttonText={props.isEdit ? "Update" : "Save"}
              onClick={handleAddClick}
              isLoading={isAddEditWorkflowActionLoading}
            />
            <Buttons
              buttonTypeClassName="dark-btn ml-5"
              buttonText="Cancel"
              onClick={handleResetAndClose}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12 table-striped api-provider">
          <FinalMolGrid
            ref={molGridRef}
            configuration={WorkflowActionsGridConfig}
            dataSource={listData}
            allowPagination={false}
            pagination={{
              totalCount: totalRowCount,
              pageSize: 20,
              currentPage: 1,
            }}
            onPageChange={handlePageChange}
            onSorting={handleSorting}
            isLoading={isGetWorkflowActionListsByStepIdLoading}
            onActionChange={actionHandler}
          />
        </div>
      </div>
    </div>)
}
export default AddEditWorkflowAction;