/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import FormCreator from "../../../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../../../components/ui/button/Buttons";
import { addEditWorkflowFormData } from "./config/Workflow.data";
import { useAddEditWorkflowMutation, useLazyGetWorkflowDetailsByIdQuery } from "../../../../../../app/services/workFlowAPI";
import ToastService from "../../../../../../services/toastService/ToastService";
import { onResetForm } from "../../../../../../utils/FormFields/ResetForm/handleResetForm";

const AddEditWorkflow = (props) => {
  const ref = useRef();
  const [formData, setFormData] = useState(addEditWorkflowFormData);
  const [addEditWorkflow, { isLoading: isAddEditWorkflowLoading, isSuccess: isAddEditWorkflowSuccess, data: allAddEditWorkflowData, },] = useAddEditWorkflowMutation();
  const [getWorkflowDetailsById, { isFetching, isSuccess: isGetWorkflowDetailsByIdSuccess, data: isGetWorkflowDetailsByIdData, },] = useLazyGetWorkflowDetailsByIdQuery();

  useEffect(() => {
    if (props.isModelOpen) {
      onResetForm(addEditWorkflowFormData, setFormData, null);
    }
  }, [props.isModelOpen])

  useEffect(() => {

    if (props.isEdit) {
      getWorkflowDetailsById(props.systemTaskConfigurationId)
    }
  }, [props.isEdit, props.systemTaskConfigurationId])

  useEffect(() => {
    if (!isFetching && isGetWorkflowDetailsByIdSuccess && isGetWorkflowDetailsByIdData) {
      if (isGetWorkflowDetailsByIdData) {
        let newForm = { ...formData };
        newForm.initialState = {
          description: isGetWorkflowDetailsByIdData.description,
          workflowName: isGetWorkflowDetailsByIdData.workflowName,
          workflowId: isGetWorkflowDetailsByIdData.workflowId

        }
        setFormData(newForm)
      }
    }
  }, [isFetching, isGetWorkflowDetailsByIdSuccess, isGetWorkflowDetailsByIdData]);

  useEffect(() => {
    if (isAddEditWorkflowSuccess && allAddEditWorkflowData) {
      ToastService.success(allAddEditWorkflowData.errorMessage);
      onResetForm(formData, setFormData, null);
      props.onGetData();
      props.onClose();

    }
  }, [isAddEditWorkflowSuccess, allAddEditWorkflowData]);

  const handleAddClick = () => {
    const formData = ref.current.getFormData();
    if (formData) {
      let request = {
        workflowId: props?.workFlowId,
        workflowName: formData?.workflowName,
        description: formData?.description,
        systemTaskConfigurationId: props?.systemTaskConfigurationId
      };
      addEditWorkflow(request);
    }
  }

  const handleResetAndClose = () => {
    onResetForm(addEditWorkflowFormData, setFormData, null);
    props.onClose();
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="vertical-form">
            <FormCreator ref={ref} config={formData} {...formData} />
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="d-flex align-item-center justify-content-end">
            <Buttons
              buttonTypeClassName="theme-button"
              buttonText={props.isEdit ? "Update" : "Save"}
              onClick={handleAddClick}
              isLoading={isAddEditWorkflowLoading}
            />
            <Buttons
              buttonTypeClassName="dark-btn ml-5"
              buttonText="Cancel"
              onClick={handleResetAndClose}
            />
          </div>
        </div>
      </div>
    </div>)
}
export default AddEditWorkflow;