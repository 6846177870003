import { useRef, useState } from "react";
import CardSection from "../../../../components/ui/card/CardSection";
import SidebarModel from "../../../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../../../data/appIcons";
import AddEditCustomFormButton from "./feature/AddEditCustomFormButton";
import { AddEditCustomFormButtonsFormData } from "./config/FormButtons.data";
import CustomFormButtonList from "./feature/CustomFormButtonList";
import { onResetForm } from "../../../../utils/FormFields/ResetForm/handleResetForm";
import PropTypes from "prop-types";
import { useEffect } from "react";


const CustomFormButtons = ({ customFormId }) => {

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [, setCustomFormId] = useState(null)
  const [formData, setFormData] = useState(AddEditCustomFormButtonsFormData);
  const childRef = useRef();
  const [search, setSearch] = useState("");

  const handleToggleModal = () => {
    onResetForm(AddEditCustomFormButtonsFormData, setFormData, null);
    setIsModelOpen(true)
  }

  const onSidebarClose = () => {
    onResetForm(AddEditCustomFormButtonsFormData, setFormData, null);
    setIsModelOpen(false);
    setIsEdit(false);

  }
  const handleEditClick = (data) => {
    setIsModelOpen(true)
    setCustomFormId(data.customFormId);
    setFormData(data);
    setIsEdit(true)

  }

  const onSuccess = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
    setIsModelOpen(false);
  };

  const handleClear = () => {
    setSearch("");
  };

  useEffect(() => {
    if (search.length > 0 ) {
      onSuccess();
    }
  }, [search]);

  const handleChange = (event) => {
    setSearch(event.target.value);

    // if (event.target.value.length > 0) {
    //   onSuccess();
    // }
  };



  return (
    <div className="mt-3">
      <CardSection
        cardTitle="Custom Form Buttons"
        buttonClassName="btn theme-button"
        rightButton={true}
        textWithIcon={true}
        iconImg={AppIcons.PlusIcon}
        titleButtonClick={handleToggleModal}
        searchInputName="Search By Button Name"
        searchInput={true}
        clearButton={true}
        clearTitleButtonClick={handleClear}
        clearButtonText="Clear"
        clearButtonClassName="dark-btn"
        clearTextWithIcon={true}
        clearIconImg={AppIcons.ClearIcone}
        searchValue={search}
        handleChange={handleChange}
      >
        <CustomFormButtonList
          handleEditClick={handleEditClick}
          childRef={childRef}
          customFormId={customFormId}
          handleChange={handleChange}
          search={search}
          handleClear={handleClear}
        />
      </CardSection>

      <SidebarModel
        modalTitle={isEdit ? "Update Custom Form Buttons" : "Add Custom Form Buttons"}
        contentClass="content-35"
        onClose={onSidebarClose}
        modalTitleIcon={AppIcons.AddIcon}
        isOpen={isModelOpen}
      >
        <AddEditCustomFormButton
          isEdit={isEdit}
          onSuccess={onSuccess}
          customFormId={customFormId}
          isOpen={isModelOpen}
          initData={formData}
          onClose={onSidebarClose}
        />
      </SidebarModel>

    </div>)
}

CustomFormButtons.propTypes = {
  customFormId: PropTypes.number,
};

export default CustomFormButtons;