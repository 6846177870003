import SystemTaskOrderItemList from "./SystemTaskOrderItemList";

const StockVarification = ({  orderItemDetail }) => {
    return (

        <div className="mb-3">
            <div className="row mb-2">
                <SystemTaskOrderItemList orderItemDetail={orderItemDetail} />
            </div>

        </div>
    )
}
export default StockVarification;