/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react"
import CardSection from "../../../../../../components/ui/card/CardSection"
import SidebarModel from "../../../../../../components/ui/sidebarModel/SidebarModel"
import { AppIcons } from "../../../../../../data/appIcons"
import AddEditWorkflow from "./AddEditWorkflow"
import WorkflowList from "./WorkflowList"
import WorkflowStepsList from "../workflowSteps/WorkflowStepsList"

import AddEditWorkflowSteps from "../workflowSteps/AddEditWorkflowSteps"
import { onResetForm } from "../../../../../../utils/FormFields/ResetForm/handleResetForm"
import { addEditWorkflowFormData } from "./config/Workflow.data"


const WorkFlow = ({ systemTaskConfigurationId }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isStepEdit, setIsStepEdit] = useState(false);
  const [listData, setListData] = useState([]);
  const [workFlowId, setWorkFlowId] = useState(null)
  const [stepId,setStepId]=useState(null)
  const [isOpenWorkflowStepModel,setIsOpenWorkflowStepModel]=useState(false)
  const [,setFormData]=useState(addEditWorkflowFormData);
  const childRef = useRef();
  const stepRef=useRef();

  const handleAddWorkflowClick = () => {
    onResetForm(addEditWorkflowFormData, setFormData, null);
    setIsModelOpen(true);
  }

  const onSidebarClose = () => {
    setIsModelOpen(false);
    setIsEdit(false);
  }

  const handleEditClick=()=>{
    onResetForm(addEditWorkflowFormData, setFormData, null);
    setIsModelOpen(true);
    setIsEdit(true);
  }
  const handleAddStepClick =()=>{
    onResetForm(addEditWorkflowFormData, setFormData, null);
    setIsOpenWorkflowStepModel(true);
  }
  const onStepSidebarClose=()=>{
    setIsOpenWorkflowStepModel(false);
    setStepId(null);
    setIsStepEdit(false)
  }

  const handleEditStepClick=(data)=>{
    onResetForm(addEditWorkflowFormData, setFormData, null);
    setIsOpenWorkflowStepModel(true);
    setStepId(data.stepId);

    setIsStepEdit(true)
  }

  const onGetData = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
  };

  const onSuccess=()=>{
    if (stepRef.current) {
      stepRef.current.callChildFunction();
    }
  }
  return (
    <div>
      <div className="mb-4">
        <CardSection
          cardTitle="WorkFlow"
          buttonClassName="btn theme-button"
          rightButton={ workFlowId ? false : true }
          textWithIcon={true}
          iconImg={AppIcons.PlusIcon}
          titleButtonClick={handleAddWorkflowClick}
        >
   
          <WorkflowList listData={listData} setListData={setListData} systemTaskConfigurationId={systemTaskConfigurationId} handleEditClick={handleEditClick} childRef={childRef}  setWorkFlowId={setWorkFlowId}/>
        </CardSection>
      </div>
      <SidebarModel
        modalTitle={"Add/Edit WorkFlow"}
        contentClass="content-35"
        onClose={onSidebarClose}
        modalTitleIcon={AppIcons.AddIcon}
        isOpen={isModelOpen}
      >
        <AddEditWorkflow
           isEdit={isEdit}
           onGetData={onGetData}
           workFlowId={workFlowId}
          systemTaskConfigurationId={systemTaskConfigurationId}
          onClose={onSidebarClose}
          isModelOpen={isModelOpen}
        />
      </SidebarModel>
      {
        listData && listData.length > 0 
          ?
          <CardSection
            cardTitle="Workflow Steps"
            buttonClassName="btn theme-button"
            rightButton={true}
            textWithIcon={true}
            iconImg={AppIcons.PlusIcon}
          titleButtonClick={handleAddStepClick}
          >
            <WorkflowStepsList stepRef={stepRef} workFlowId={workFlowId} handleEditClick={handleEditStepClick} />
          </CardSection> : null}


          <SidebarModel
        modalTitle={"Add/Edit WorkFlowSteps"}
        contentClass="content-35"
        onClose={onStepSidebarClose}
        modalTitleIcon={AppIcons.AddIcon}
        isOpen={isOpenWorkflowStepModel}
      >
        <AddEditWorkflowSteps
            isEdit={isStepEdit}
           stepId={stepId}
           onSuccess={onSuccess}
          workFlowId={workFlowId}
          onClose={onStepSidebarClose}
          isModelOpen={isOpenWorkflowStepModel}
        />
      </SidebarModel>
    </div>)
}
export default WorkFlow