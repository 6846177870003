import React from 'react';
import Iconify from '../../../../../../../components/ui/iconify/Iconify'
import PropTypes from 'prop-types';
import HoverBox from '../../../../../../../components/ui/HoverBox/HoverBox';

const ThirdPartyApiConfigurationInfoCard = ({ editClick, viewCardDetails }) => {
    return (
        <div className='customer-desc-left-sec'>
            <div className="basic-customer-detail">
                <div className="d-flex col-xl-12 col-lg-12 col-md-12 col-12 configuration-seprations">
                    <div className='col-3'>
                        <div className="field-desc">
                            <div className="inf-label">Event Name</div>
                            <b>&nbsp;:&nbsp;</b>
                            <div className="info-desc">{viewCardDetails?.eventName}</div>
                        </div>
                    </div>
                    <div className='col-3 separator'>

                        <div className="field-desc">
                            <div className="inf-label">Event Title</div>
                            <b>&nbsp;:&nbsp;</b>
                            <div className="info-desc">{viewCardDetails?.eventTitle}</div>
                            <HoverBox hoverContent={
                                <div className="thirdparty-card-description">
                                    {viewCardDetails?.eventTitle}
                                </div>}>
                                <Iconify icon="material-symbols:info-outline-rounded" />
                            </HoverBox>
                        </div>
                    
                    </div>
                    <div className='col-3 separator'>
                   
                        <div className="field-desc">
                            <div className="inf-label">Event Type</div>
                            <b>&nbsp;:&nbsp;</b>
                            <div className="thirdparty-card-eventTitle">{viewCardDetails?.eventType}</div>
                        </div>
                    
                    </div>
                    <div className='col-3 separator'>
                        <div className="field-desc ">
                            <div className="inf-label">Description</div>
                            <b>&nbsp;:&nbsp;</b>
                            <div className="info-desc">{viewCardDetails?.description}</div>
                            <HoverBox hoverContent={
                                <div className="thirdparty-card-description">
                                    {viewCardDetails?.description}
                                </div>}>
                                <Iconify icon="material-symbols:info-outline-rounded" />
                            </HoverBox>
                        </div>
                    </div>
                    <div className="edit-icons"
                        onClick={editClick}
                    >
                        {/* <Image imagePath={AppIcons.editThemeIcon} altText="Website Icon" /> */}
                        <Iconify icon="tabler:pencil" />
                    </div>
                </div>
            </div>
        </div>
    )
}

ThirdPartyApiConfigurationInfoCard.propTypes = {
    editClick: PropTypes.func,
    viewCardDetails: PropTypes.shape({
        eventName: PropTypes.string,
        description: PropTypes.string,
    }),
};
export default ThirdPartyApiConfigurationInfoCard